//  const cityData =[
//     {
//       "country": "Canada",
//       "cities": [
//         "Toronto", "Montreal", "Vancouver", "Calgary", "Edmonton", "Ottawa", "Winnipeg", "Quebec City", "Hamilton", "London", "Kitchener", "St. Catharines", "Halifax", "Victoria", "Windsor", "Oshawa", "Saskatoon", "Regina", "Barrie", "Sherbrooke", "Guelph", "Kingston", "Moncton", "Thunder Bay", "Saint John", "Peterborough", "Red Deer", "Kamloops", "Sydney", "Fredericton"
//       ]
//     },
//     {
//       "country": "USA",
//       "cities": [
//         "New York City", "Los Angeles", "Chicago", "Houston", "Phoenix", "Philadelphia", "San Antonio", "San Diego", "Dallas", "San Jose", "Austin", "Jacksonville", "San Francisco", "Columbus", "Fort Worth", "Indianapolis", "Charlotte", "Seattle", "Denver", "Washington, D.C.", "Boston", "El Paso", "Nashville", "Detroit", "Oklahoma City", "Portland", "Las Vegas", "Memphis", "Louisville", "Baltimore"
//       ]
//     },
//     {
//       "country": "India",
//       "states": [
//         {
//           "state": "Andhra Pradesh",
//           "cities": ["Visakhapatnam", "Vijayawada", "Guntur", "Nellore", "Kurnool", "Kadapa", "Rajahmundry", "Tirupati", "Anantapur", "Kakinada", "Eluru", "Vizianagaram", "Ongole", "Nandyal", "Machilipatnam"]
//         },
//         {
//           "state": "Arunachal Pradesh",
//           "cities": ["Itanagar", "Naharlagun", "Tawang", "Bomdila", "Pasighat", "Ziro", "Along", "Tezu", "Daporijo", "Roing", "Khonsa", "Anini"]
//         },
//         {
//           "state": "Assam",
//           "cities": ["Guwahati", "Dibrugarh", "Silchar", "Jorhat", "Nagaon", "Tinsukia", "Tezpur", "Sivasagar", "Goalpara", "Dhubri", "Nalbari", "Bongaigaon", "Karimganj", "Barpeta", "Hailakandi"]
//         },
//         {
//           "state": "Bihar",
//           "cities": ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur", "Purnia", "Darbhanga", "Bihar Sharif", "Arrah", "Begusarai", "Katihar", "Munger", "Chhapra", "Danapur", "Saharsa", "Hajipur"]
//         },
//         {
//           "state": "Chhattisgarh",
//           "cities": ["Raipur", "Bhilai", "Bilaspur", "Korba", "Durg", "Rajnandgaon", "Raigarh", "Ambikapur", "Jagdalpur", "Chirmiri", "Dhamtari", "Mahasamund", "Kanker", "Janjgir"]
//         },
//         {
//           "state": "Goa",
//           "cities": ["Panaji", "Margao", "Vasco da Gama", "Mapusa", "Ponda", "Bicholim", "Curchorem", "Sanguem", "Sanquelim", "Valpoi"]
//         },
//         {
//           "state": "Gujarat",
//           "cities": ["Ahmedabad", "Surat", "Vadodara", "Rajkot", "Bhavnagar", "Jamnagar", "Junagadh", "Gandhinagar", "Anand", "Navsari", "Morbi", "Bharuch", "Porbandar", "Ankleshwar"]
//         },
//         {
//           "state": "Haryana",
//           "cities": ["Chandigarh", "Faridabad", "Gurgaon", "Hisar", "Panipat", "Ambala", "Yamunanagar", "Rohtak", "Karnal", "Sonipat", "Panchkula", "Bhiwani", "Sirsa", "Bahadurgarh"]
//         },
//         {
//           "state": "Himachal Pradesh",
//           "cities": ["Shimla", "Mandi", "Dharamshala", "Solan", "Palampur", "Una", "Kullu", "Manali", "Bilaspur", "Nahan", "Sirmour", "Kinnaur", "Hamirpur", "Chamba"]
//         },
//         {
//           "state": "Jharkhand",
//           "cities": ["Ranchi", "Jamshedpur", "Dhanbad", "Bokaro Steel City", "Deoghar", "Phusro", "Hazaribagh", "Giridih", "Ramgarh", "Medininagar", "Chirkunda", "Jhumri Tilaiya", "Saunda", "Sahibganj"]
//         },
//         {
//           "state": "Karnataka",
//           "cities": ["Bengaluru", "Mysuru", "Hubli", "Mangaluru", "Belagavi", "Davanagere", "Ballari", "Vijayapura", "Kalaburagi", "Shivamogga", "Tumakuru", "Raichur", "Bidar", "Hosapete"]
//         },
//         {
//           "state": "Kerala",
//           "cities": ["Thiruvananthapuram", "Kochi", "Kozhikode", "Kollam", "Thrissur", "Alappuzha", "Palakkad", "Kottayam", "Malappuram", "Kannur", "Manjeri", "Kasaragod", "Thalassery", "Ponnani"]
//         },
//         {
//           "state": "Madhya Pradesh",
//           "cities": ["Bhopal", "Indore", "Jabalpur", "Gwalior", "Ujjain", "Sagar", "Dewas", "Satna", "Ratlam", "Rewa", "Chhindwara", "Guna", "Shivpuri", "Vidisha"]
//         },
//         {
//           "state": "Maharashtra",
//           "cities": ["Mumbai", "Pune", "Nagpur", "Nashik", "Aurangabad", "Solapur", "Amravati", "Malegaon", "Kolhapur", "Nanded", "Sangli", "Jalgaon", "Akola", "Latur"]
//         },
//         {
//           "state": "Manipur",
//           "cities": ["Imphal", "Thoubal", "Bishnupur", "Churachandpur", "Kakching", "Ukhrul", "Wangjing", "Nambol", "Kakching", "Mayang Imphal", "Yairipok", "Lilong", "Kumbi"]
//         },
//         {
//           "state": "Meghalaya",
//           "cities": ["Shillong", "Tura", "Jowai", "Nongpoh", "Nongstoin", "Baghmara", "Williamnagar", "Resubelpara", "Mankachar", "Rongjeng", "Mairang", "Mawkyrwat", "Nongpoh"]
//         },
//         {
//           "state": "Mizoram",
//           "cities": ["Aizawl", "Lunglei", "Saiha", "Champhai", "Kolasib", "Serchhip", "Thenzawl", "Hnahthial", "Khawhai", "Bilkhawthlir", "Zawlnuam", "N. Vanlaiphai", "Sairang"]
//         },
//         {
//           "state": "Nagaland",
//           "cities": ["Kohima", "Dimapur", "Mokokchung", "Tuensang", "Wokha", "Zunheboto", "Phek", "Mon", "Longleng", "Kiphire", "Tseminyu", "Chumukedima", "Zuweema"]
//         },
//         {
//           "state": "Odisha",
//           "cities": ["Bhubaneswar", "Cuttack", "Rourkela", "Brahmapur", "Sambalpur", "Puri", "Balasore", "Bhadrak", "Baripada", "Jharsuguda", "Jeypore", "Bargarh", "Rayagada", "Angul"]
//         },
//         {
//           "state": "Punjab",
//           "cities": ["Chandigarh", "Ludhiana", "Amritsar", "Jalandhar", "Patiala", "Bathinda", "Hoshiarpur", "Mohali", "Batala", "Pathankot", "Moga", "Firozpur", "Kapurthala", "Khanna"]
//         },
//         {
//           "state": "Rajasthan",
//           "cities": ["Jaipur", "Jodhpur", "Udaipur", "Kota", "Bikaner", "Ajmer", "Bhilwara", "Alwar", "Sikar", "Pali", "Sawai Madhopur", "Jhunjhunu", "Bharatpur", "Barmer"]
//         },
//         {
//           "state": "Sikkim",
//           "cities": ["Gangtok", "Namchi", "Gyalshing", "Mangan", "Jorethang", "Ravangla", "Rangpo", "Singtam", "Nayabazar", "Rhenock", "Melli", "Gyalshing", "Jorethang"]
//         },
//         {
//           "state": "Tamil Nadu",
//           "cities": ["Chennai", "Coimbatore", "Madurai", "Tiruchirappalli", "Salem", "Tirunelveli", "Tiruppur", "Erode", "Vellore", "Thoothukudi", "Thanjavur", "Dindigul", "Ranipet", "Nagercoil"]
//         },
//         {
//           "state": "Telangana",
//           "cities": ["Hyderabad", "Warangal", "Nizamabad", "Karimnagar", "Khammam", "Ramagundam", "Mahbubnagar", "Nalgonda", "Adilabad", "Suryapet", "Miryalaguda", "Jagtial", "Nirmal", "Kamareddy"]
//         },
//         {
//           "state": "Tripura",
//           "cities": ["Agartala", "Udaipur", "Dharmanagar", "Kailashahar", "Ambassa", "Belonia", "Santirbazar", "Khowai", "Sonamura", "Kamalpur", "Bishalgarh", "Melaghar"]
//         },
//         {
//           "state": "Uttar Pradesh",
//           "cities": ["Lucknow", "Kanpur", "Agra", "Meerut", "Varanasi", "Allahabad", "Bareilly", "Aligarh", "Moradabad", "Saharanpur", "Gorakhpur", "Noida", "Firozabad", "Jhansi"]
//         },
//         {
//           "state": "Uttarakhand",
//           "cities": ["Dehradun", "Haridwar", "Roorkee", "Haldwani", "Rudrapur", "Kashipur", "Rishikesh", "Ramnagar", "Pithoragarh", "Manglaur", "Nainital", "Kotdwar", "Srinagar"]
//         },
//         {
//           "state": "West Bengal",
//           "cities": ["Kolkata", "Howrah", "Durgapur", "Asansol", "Siliguri", "Malda", "Bardhaman", "English Bazar", "Baharampur", "Habra", "Kharagpur", "Shantipur", "Dankuni", "Dhulian"]
//         }
//       ]
//     }
//   ]

//   export default cityData;

const options = [
  {
    label: "India",
    options: [
      {
        value: "Visakhapatnam",
        label: "Visakhapatnam",
      },
      {
        value: "Vijayawada",
        label: "Vijayawada",
      },
      {
        value: "Guntur",
        label: "Guntur",
      },
      {
        value: "Nellore",
        label: "Nellore",
      },
      {
        value: "Kurnool",
        label: "Kurnool",
      },
      {
        value: "Kadapa",
        label: "Kadapa",
      },
      {
        value: "Rajahmundry",
        label: "Rajahmundry",
      },
      {
        value: "Tirupati",
        label: "Tirupati",
      },
      {
        value: "Anantapur",
        label: "Anantapur",
      },
      {
        value: "Kakinada",
        label: "Kakinada",
      },
      {
        value: "Eluru",
        label: "Eluru",
      },
      {
        value: "Vizianagaram",
        label: "Vizianagaram",
      },
      {
        value: "Ongole",
        label: "Ongole",
      },
      {
        value: "Nandyal",
        label: "Nandyal",
      },
      {
        value: "Machilipatnam",
        label: "Machilipatnam",
      },
      {
        value: "Itanagar",
        label: "Itanagar",
      },
      {
        value: "Naharlagun",
        label: "Naharlagun",
      },
      {
        value: "Tawang",
        label: "Tawang",
      },
      {
        value: "Bomdila",
        label: "Bomdila",
      },
      {
        value: "Pasighat",
        label: "Pasighat",
      },
      {
        value: "Ziro",
        label: "Ziro",
      },
      {
        value: "Along",
        label: "Along",
      },
      {
        value: "Tezu",
        label: "Tezu",
      },
      {
        value: "Daporijo",
        label: "Daporijo",
      },
      {
        value: "Roing",
        label: "Roing",
      },
      {
        value: "Khonsa",
        label: "Khonsa",
      },
      {
        value: "Anini",
        label: "Anini",
      },
      {
        value: "Guwahati",
        label: "Guwahati",
      },
      {
        value: "Dibrugarh",
        label: "Dibrugarh",
      },
      {
        value: "Silchar",
        label: "Silchar",
      },
      {
        value: "Jorhat",
        label: "Jorhat",
      },
      {
        value: "Nagaon",
        label: "Nagaon",
      },
      {
        value: "Tinsukia",
        label: "Tinsukia",
      },
      {
        value: "Tezpur",
        label: "Tezpur",
      },
      {
        value: "Sivasagar",
        label: "Sivasagar",
      },
      {
        value: "Goalpara",
        label: "Goalpara",
      },
      {
        value: "Dhubri",
        label: "Dhubri",
      },
      {
        value: "Nalbari",
        label: "Nalbari",
      },
      {
        value: "Bongaigaon",
        label: "Bongaigaon",
      },
      {
        value: "Karimganj",
        label: "Karimganj",
      },
      {
        value: "Barpeta",
        label: "Barpeta",
      },
      {
        value: "Hailakandi",
        label: "Hailakandi",
      },
      {
        value: "Patna",
        label: "Patna",
      },
      {
        value: "Gaya",
        label: "Gaya",
      },
      {
        value: "Bhagalpur",
        label: "Bhagalpur",
      },
      {
        value: "Muzaffarpur",
        label: "Muzaffarpur",
      },
      {
        value: "Purnia",
        label: "Purnia",
      },
      {
        value: "Darbhanga",
        label: "Darbhanga",
      },
      {
        value: "Bihar Sharif",
        label: "Bihar Sharif",
      },
      {
        value: "Arrah",
        label: "Arrah",
      },
      {
        value: "Begusarai",
        label: "Begusarai",
      },
      {
        value: "Katihar",
        label: "Katihar",
      },
      {
        value: "Munger",
        label: "Munger",
      },
      {
        value: "Chhapra",
        label: "Chhapra",
      },
      {
        value: "Danapur",
        label: "Danapur",
      },
      {
        value: "Saharsa",
        label: "Saharsa",
      },
      {
        value: "Hajipur",
        label: "Hajipur",
      },
      {
        value: "Raipur",
        label: "Raipur",
      },
      {
        value: "Bhilai",
        label: "Bhilai",
      },
      {
        value: "Bilaspur",
        label: "Bilaspur",
      },
      {
        value: "Korba",
        label: "Korba",
      },
      {
        value: "Durg",
        label: "Durg",
      },
      {
        value: "Rajnandgaon",
        label: "Rajnandgaon",
      },
      {
        value: "Raigarh",
        label: "Raigarh",
      },
      {
        value: "Ambikapur",
        label: "Ambikapur",
      },
      {
        value: "Jagdalpur",
        label: "Jagdalpur",
      },
      {
        value: "Chirmiri",
        label: "Chirmiri",
      },
      {
        value: "Dhamtari",
        label: "Dhamtari",
      },
      {
        value: "Mahasamund",
        label: "Mahasamund",
      },
      {
        value: "Kanker",
        label: "Kanker",
      },
      {
        value: "Janjgir",
        label: "Janjgir",
      },
      {
        value: "Panaji",
        label: "Panaji",
      },
      {
        value: "Margao",
        label: "Margao",
      },
      {
        value: "Vasco da Gama",
        label: "Vasco da Gama",
      },
      {
        value: "Mapusa",
        label: "Mapusa",
      },
      {
        value: "Ponda",
        label: "Ponda",
      },
      {
        value: "Bicholim",
        label: "Bicholim",
      },
      {
        value: "Curchorem",
        label: "Curchorem",
      },
      {
        value: "Sanguem",
        label: "Sanguem",
      },
      {
        value: "Sanquelim",
        label: "Sanquelim",
      },
      {
        value: "Valpoi",
        label: "Valpoi",
      },
      {
        value: "Ahmedabad",
        label: "Ahmedabad",
      },
      {
        value: "Surat",
        label: "Surat",
      },
      {
        value: "Vadodara",
        label: "Vadodara",
      },
      {
        value: "Rajkot",
        label: "Rajkot",
      },
      {
        value: "Bhavnagar",
        label: "Bhavnagar",
      },
      {
        value: "Jamnagar",
        label: "Jamnagar",
      },
      {
        value: "Junagadh",
        label: "Junagadh",
      },
      {
        value: "Gandhinagar",
        label: "Gandhinagar",
      },
      {
        value: "Anand",
        label: "Anand",
      },
      {
        value: "Navsari",
        label: "Navsari",
      },
      {
        value: "Morbi",
        label: "Morbi",
      },
      {
        value: "Bharuch",
        label: "Bharuch",
      },
      {
        value: "Porbandar",
        label: "Porbandar",
      },
      {
        value: "Ankleshwar",
        label: "Ankleshwar",
      },
      {
        value: "Chandigarh",
        label: "Chandigarh",
      },
      {
        value: "Faridabad",
        label: "Faridabad",
      },
      {
        value: "Gurgaon",
        label: "Gurgaon",
      },
      {
        value: "Hisar",
        label: "Hisar",
      },
      {
        value: "Panipat",
        label: "Panipat",
      },
      {
        value: "Ambala",
        label: "Ambala",
      },
      {
        value: "Yamunanagar",
        label: "Yamunanagar",
      },
      {
        value: "Rohtak",
        label: "Rohtak",
      },
      {
        value: "Karnal",
        label: "Karnal",
      },
      {
        value: "Sonipat",
        label: "Sonipat",
      },
      {
        value: "Panchkula",
        label: "Panchkula",
      },
      {
        value: "Bhiwani",
        label: "Bhiwani",
      },
      {
        value: "Sirsa",
        label: "Sirsa",
      },
      {
        value: "Bahadurgarh",
        label: "Bahadurgarh",
      },
      {
        value: "Shimla",
        label: "Shimla",
      },
      {
        value: "Mandi",
        label: "Mandi",
      },
      {
        value: "Dharamshala",
        label: "Dharamshala",
      },
      {
        value: "Solan",
        label: "Solan",
      },
      {
        value: "Palampur",
        label: "Palampur",
      },
      {
        value: "Una",
        label: "Una",
      },
      {
        value: "Kullu",
        label: "Kullu",
      },
      {
        value: "Manali",
        label: "Manali",
      },
      {
        value: "Bilaspur",
        label: "Bilaspur",
      },
      {
        value: "Nahan",
        label: "Nahan",
      },
      {
        value: "Sirmour",
        label: "Sirmour",
      },
      {
        value: "Kinnaur",
        label: "Kinnaur",
      },
      {
        value: "Hamirpur",
        label: "Hamirpur",
      },
      {
        value: "Chamba",
        label: "Chamba",
      },
    ],
  },
  {
    label: "🍁 Canada",
    options: [
      {
        value: "Montreal",
        label: "Montreal",
      },
      {
        value: "Vancouver",
        label: "Vancouver",
      },
      {
        value: "Calgary",
        label: "Calgary",
      },
      {
        value: "Edmonton",
        label: "Edmonton",
      },
      {
        value: "Ottawa",
        label: "Ottawa",
      },
      {
        value: "Winnipeg",
        label: "Winnipeg",
      },
      {
        value: "Quebec City",
        label: "Quebec City",
      },
      {
        value: "Hamilton",
        label: "Hamilton",
      },
      {
        value: "London",
        label: "London",
      },
      {
        value: "Kitchener",
        label: "Kitchener",
      },
      {
        value: "St. Catharines",
        label: "St. Catharines",
      },
      {
        value: "Halifax",
        label: "Halifax",
      },
      {
        value: "Victoria",
        label: "Victoria",
      },
      {
        value: "Windsor",
        label: "Windsor",
      },
      {
        value: "Oshawa",
        label: "Oshawa",
      },
      {
        value: "Saskatoon",
        label: "Saskatoon",
      },
      {
        value: "Regina",
        label: "Regina",
      },
      {
        value: "Barrie",
        label: "Barrie",
      },
      {
        value: "Sherbrooke",
        label: "Sherbrooke",
      },
      {
        value: "Guelph",
        label: "Guelph",
      },
      {
        value: "Kingston",
        label: "Kingston",
      },
      {
        value: "Moncton",
        label: "Moncton",
      },
      {
        value: "Thunder Bay",
        label: "Thunder Bay",
      },
      {
        value: "Saint John",
        label: "Saint John",
      },
      {
        value: "Peterborough",
        label: "Peterborough",
      },
      {
        value: "Red Deer",
        label: "Red Deer",
      },
      {
        value: "Kamloops",
        label: "Kamloops",
      },
      {
        value: "Sydney",
        label: "Sydney",
      },
      {
        value: "Fredericton",
        label: "Fredericton",
      },
    ],
  },
  {
    label: "America",
    options: [
      {
        value: "America",
        label: "America",
      },
      {
        value: "New York City",
        label: "New York City",
      },
      {
        value: "Los Angeles",
        label: "Los Angeles",
      },
      {
        value: "Chicago",
        label: "Chicago",
      },
      {
        value: "Houston",
        label: "Houston",
      },
      {
        value: "Phoenix",
        label: "Phoenix",
      },
      {
        value: "Philadelphia",
        label: "Philadelphia",
      },
      {
        value: "San Antonio",
        label: "San Antonio",
      },
      {
        value: "San Diego",
        label: "San Diego",
      },
      {
        value: "Dallas",
        label: "Dallas",
      },
      {
        value: "San Jose",
        label: "San Jose",
      },
      {
        value: "Austin",
        label: "Austin",
      },
      {
        value: "Jacksonville",
        label: "Jacksonville",
      },
      {
        value: "San Francisco",
        label: "San Francisco",
      },
      {
        value: "Columbus",
        label: "Columbus",
      },
      {
        value: "Fort Worth",
        label: "Fort Worth",
      },
      {
        value: "Indianapolis",
        label: "Indianapolis",
      },
      {
        value: "Charlotte",
        label: "Charlotte",
      },
      {
        value: "Seattle",
        label: "Seattle",
      },
      {
        value: "Denver",
        label: "Denver",
      },
      {
        value: "Washington, D.C.",
        label: "Washington, D.C.",
      },
      {
        value: "Boston",
        label: "Boston",
      },
      {
        value: "El Paso",
        label: "El Paso",
      },
      {
        value: "Nashville",
        label: "Nashville",
      },
      {
        value: "Detroit",
        label: "Detroit",
      },
      {
        value: "Oklahoma City",
        label: "Oklahoma City",
      },
      {
        value: "Portland",
        label: "Portland",
      },
      {
        value: "Las Vegas",
        label: "Las Vegas",
      },
      {
        value: "Memphis",
        label: "Memphis",
      },
      {
        value: "Louisville",
        label: "Louisville",
      },
      {
        value: "Baltimore",
        label: "Baltimore",
      },
    ],
  },
];

export default options;
