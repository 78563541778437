import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import { car7 } from "Utils/Images"; // Make sure to replace this with the correct path to your image
import Heading from "components/Heading/Heading.";
import { RiCustomerService2Fill, RiExchangeDollarFill } from "react-icons/ri";
import { IoIosPerson } from "react-icons/io";
import { IoCarSportSharp } from "react-icons/io5";
import { GiAutoRepair } from "react-icons/gi";
const features = [
  {
    icon: <RiExchangeDollarFill color="red" size={30} />,
    title: "Best price guaranteed",
    description: "Find a lower price? We’ll refund you 100% of the difference.",
  },
  {
    icon: <IoIosPerson color="red" size={30} />,
    title: "Experience driver",
    description:
      "Don’t have driver? Don’t worry, we have many experienced driver for you.",
  },
  {
    icon: <GiAutoRepair color="red" size={30} />,
    title: "Customizable packages",
    description:
      "Tailor your car rental package to suit your needs, including insurance and extra features.",
  },
  {
    icon: <IoCarSportSharp color="red" size={30} />,
    title: "24 hour car delivery",
    description:
      "Book your car anytime and we will deliver it directly to you.",
  },
  {
    icon: <RiCustomerService2Fill color="red" size={30} />,
    title: "24/7 technical support",
    description:
      "Have a question? Contact Rentcars support any time when you have problem.",
  },
];
const Whychooseus = () => {
  const ref = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.unobserve(ref.current); // Stop observing after the element is in view
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  return (
    <section className="grid grid-cols-1 items-center gap-10 sm:grid-cols-2">
      <div
        className="section-with-car relative z-[1] overflow-hidden"
        ref={ref}
      >
        {isInView && (
          <motion.div
            initial={{ opacity: 0, x: -100 }} // Start 100px to the left and invisible
            animate={{ opacity: 1, x: 0 }} // Animate to the original position and fully visible
            transition={{ duration: 0.5 }} // Duration of the animation
          >
            <img src={car7} alt="Audi" className="relative z-20 w-full" />
          </motion.div>
        )}
      </div>
      <div>
        <div className="px-5 sm:px-0">
          <Heading
            Heading={"WHY CHOOSE US"}
            paragraph={"We offer the best experience with our rental deals"}
            direction="text-left"
            padding="sm:py-0"
          />
        </div>
        <div>
          {features.map((feature, index) => (
            <div
              key={index}
              className="mb-3 flex items-center gap-2 text-gray-800 sm:gap-5"
            >
              <div className="rounded-lg bg-white p-4 sm:bg-red-50">
                {feature.icon}
              </div>
              <div>
                <h3 className="text-lg font-bold">{feature.title}</h3>
                <h3 className="w-72 text-sm">{feature.description}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Whychooseus;
