import Logo from "components/maharjalogowithplane/Logo";
import React from "react";

export default function WhychooseUs({ heading, reasons, p }) {
  return (
    <section className="py-5 px-5 sm:py-10 sm:px-0 ">
      <Logo />
      <div className="relative mb-5 -mt-4 text-lg  font-semibold leading-5 sm:mb-10 sm:text-3xl">
        <h2 className="">{heading}</h2>
        {p && (
          <div>
            <p className="">{p}</p>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 gap-5 px-0 text-white sm:grid-cols-3 sm:gap-5 md:grid-cols-5">
        {reasons.map((reason, index) => (
          <div
            key={index}
            className={`bg-carousal h-56 w-full rounded-lg p-6 sm:h-full ${
              [1, 3, 6, 8].includes(index) ? "sm:relative sm:top-5" : ""
            }`}
          >
            <h2 className="mb-2 text-2xl font-bold">{reason.title}</h2>
            <p className="text-sm">{reason.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
