import React from "react";
import { useNavigate } from "react-router-dom";

export default function Heading({
  Heading,
  paragraph,
  discription,
  button,
  color,
  direction,
  padding,
}) {
  const navigate = useNavigate();
  return (
    <div
      className={` py-5 ${padding ? padding : "sm:py-10"} ${
        direction ? direction : ""
      }px-5 text-gray-800 sm:px-0`}
    >
      {Heading && (
        <h3 className="mb-4 inline  rounded-md bg-red-50 py-2 px-5  text-red-600">
          {Heading}
        </h3>
      )}
      {paragraph && (
        <h4 className=" mb-0 mt-5 max-w-5xl text-xl font-bold sm:text-2xl">
          {paragraph}
        </h4>
      )}
      {discription && (
        <h6 className={`${color ? color : "text-gray-700"}  sm:w-[550px]`}>
          {discription}
        </h6>
      )}
      {button && (
        <button
          onClick={() => {
            navigate("/careers");
            window.scrollTo(0, 0);
          }}
          className="bg-carousal mt-6 rounded-sm p-2 px-10"
        >
          {button}
        </button>
      )}
    </div>
  );
}
