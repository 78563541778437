import React, { useState } from "react";
import "../../../assets/css/App.css";
import SearchComponent from "../SearchComponent/SearchComponent";
import FormSetupFormMobile from "components/FormSetupFormMobile/FormSetupFormMobile";
import TravelStories from "components/TravelStories/TravelStories";
import PhoneNumberPopup from "components/PhoneNumberPopup/PhoneNumberPopup";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../../redux/userSlice";

export default function Home() {
  const userInfo = useSelector(selectUserInfo);
  const [isMobile, setIsMobile] = useState("");

  return (
    <div>
      {/* for phone screen */}
      <FormSetupFormMobile />
      <div className={`${userInfo.status ? "hidden" : ""} visible `}>
        <PhoneNumberPopup />
      </div>
      {/* For laptop screen and tab */}
      <SearchComponent />
      <TravelStories />
    </div>
  );
}
