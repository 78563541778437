import userResources from "api/userResources";
import Loader from "components/Loader/Loader";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Select from "react-tailwindcss-select";
import { toast } from "react-toastify";

export default function Coupon() {
  const [deals, setDeals] = useState(null); // Change initial state to null
  const [options, setOptions] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [editingCoupon, setEditingCoupon] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useForm();
  const [expiryDate, setExpiryDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const validateExpiryDate = (date) => {
    const today = new Date();
    const selectedDate = new Date(date);

    if (!date) {
      setError("expiry_date", {
        type: "manual",
        message: "Expiry date is required",
      });
    } else if (selectedDate <= today) {
      setError("expiry_date", {
        type: "manual",
        message: "Expiry date must be greater than today",
      });
    } else {
      clearErrors("expiry_date");
    }
  };

  const handleChange = (value) => {
    // console.log("value:", value.label);
    setDeals(value);
  };

  const getDealList = async () => {
    try {
      setLoading(true);
      const response = await userResources.getDealList();
      // Format options for the select component
      const formattedOptions =
        response?.data?.data.map((deal) => ({
          value: deal.id,
          label: deal.name,
        })) || [];
      setOptions(formattedOptions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleExpiryDateChange = (e) => {
    const date = e.target.value;
    setExpiryDate(date);
    validateExpiryDate(date);
  };

  const onSubmit = async (data) => {
    if (!expiryDate || new Date(expiryDate) <= new Date()) {
      setError("expiry_date", {
        type: "manual",
        message: "Expiry date must be greater than today",
      });
      return;
    }

    data.expiry_date = expiryDate;

    try {
      setLoading(true);
      if (editingCoupon) {
        data.id = editingCoupon.id;
        data.name = deals.label;
        const response = await userResources.coupon(data);
        if (response.status) {
          toast.success("Coupon updated successfully");
        }
      } else {
        data.name = deals.label;
        const response = await userResources.coupon(data);
        if (response.status) {
          toast.success("Coupon created successfully");
        }
      }
      getCouponList();
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
    resetForm();
  };

  const getCouponList = async () => {
    try {
      setLoading(true);
      const response = await userResources.couponList();
      setCouponList(response?.data?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (index) => {
    const coupon = couponList[index];
    setEditingCoupon(coupon);
    setValue("name", coupon.name);
    setValue("coupon_code", coupon.coupon_code);
    setValue("discount", coupon.discount);

    const expiryDatePart = coupon.expiry_date.split(" ")[0];
    setExpiryDate(expiryDatePart);
  };

  const handleDelete = async (index) => {
    const coupon = couponList[index];

    try {
      setLoading(true);
      const response = await userResources.deleteCoupon(coupon.id);
      if (response.status) {
        toast.success("Coupon deleted successfully");
        getCouponList();
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    reset();
    setValue("expiry_date", null);
    clearErrors();
    setExpiryDate(new Date().toISOString().split("T")[0]);
    setEditingCoupon(null);
    setDeals(null); // Reset deals selection
  };

  useEffect(() => {
    getCouponList();
    getDealList();
  }, []);

  return (
    <div className="mx-auto mt-4 flex max-w-8xl flex-col lg:flex-row">
      <div className="w-full pr-4 lg:w-1/2">
        <h2 className="mb-4 text-xl font-semibold">
          {editingCoupon ? "Edit Coupon" : "Create Coupon"}
        </h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 lg:col-span-1">
            <label
              className="mb-2.5 block font-medium text-gray-800 dark:text-white"
              htmlFor="name"
            >
              Name
            </label>
            <Select
              value={deals}
              onChange={handleChange}
              options={options}
              loading={loading}
              className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="coupon_code"
              className="block text-sm font-medium text-gray-700"
            >
              Coupon Code
            </label>
            <input
              {...register("coupon_code", {
                required: "Coupon code is required",
              })}
              type="text"
              id="coupon_code"
              className={`mt-1 block w-full rounded-md border border-gray-300 bg-white p-3 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                errors.coupon_code ? "border-red-500" : ""
              }`}
              placeholder="Enter the coupon code"
            />
            {errors.coupon_code && (
              <p className="mt-2 text-sm text-red-600">
                {errors.coupon_code.message}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="discount"
              className="block text-sm font-medium text-gray-700"
            >
              Discount (%)
            </label>
            <input
              {...register("discount", {
                required: "Discount percentage is required",
                min: {
                  value: 1,
                  message: "Discount must be at least 1%",
                },
                max: {
                  value: 100,
                  message: "Discount cannot exceed 100%",
                },
              })}
              type="number"
              id="discount"
              className={`mt-1 block w-full rounded-md border border-gray-300 bg-white p-3 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                errors.discount ? "border-red-500" : ""
              }`}
              placeholder="Enter the discount percentage"
            />
            {errors.discount && (
              <p className="mt-2 text-sm text-red-600">
                {errors.discount.message}
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="expiry_date"
              className="block text-sm font-medium text-gray-700"
            >
              Expiry Date
            </label>
            <input
              {...register("expiry_date", {
                required: "Expiry date is required",
              })}
              type="date"
              id="expiry_date"
              value={expiryDate}
              onChange={handleExpiryDateChange}
              className={`mt-1 block w-full rounded-md border border-gray-300 bg-white p-3 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                errors.expiry_date ? "border-red-500" : ""
              }`}
            />
            {errors.expiry_date && (
              <p className="mt-2 text-sm text-red-600">
                {errors.expiry_date.message}
              </p>
            )}
          </div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            {editingCoupon ? "Update" : "Create"}
          </button>
          {editingCoupon && (
            <button
              type="button"
              onClick={resetForm}
              className="mt-2 flex w-full justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Cancel
            </button>
          )}
        </form>
      </div>
      <div className="mt-8 w-full pl-4 lg:mt-0 lg:w-1/2">
        <div className="flex items-center justify-between">
          <h2 className="mb-4 text-xl font-semibold">Coupon List</h2>
          <input
            type="text"
            placeholder="Search"
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input mb-4 rounded-lg border bg-transparent py-1 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
          />
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <ul className="hide-scroll-bar h-96 list-none overflow-scroll p-0">
            {couponList.map((coupon, index) => (
              <li
                key={index}
                className="mb-4 flex flex-col items-start justify-between rounded-lg bg-white p-4 shadow-md lg:flex-row lg:items-center"
              >
                <div className="lg:w-3/4">
                  <p className="text-lg font-semibold">{coupon.coupon_code}</p>
                  <p className="text-sm">Discount: {coupon.discount}%</p>
                  <p className="text-sm">
                    Expiry Date:{" "}
                    {new Date(coupon.expiry_date).toLocaleDateString()}
                  </p>
                  <p className="text-sm">
                    Created At: {new Date(coupon.created_at).toLocaleString()}
                  </p>
                </div>
                <div className="flex flex-col items-start justify-center lg:mt-0 lg:w-1/4">
                  <p className="rounded-md bg-blue-600 py-1 px-3 text-center text-sm text-white">
                    {coupon.name}
                  </p>
                  <div>
                    <button
                      className="mr-2 text-blue-600"
                      onClick={() => handleEdit(index)}
                    >
                      Edit
                    </button>
                    <button
                      className="text-red-600"
                      onClick={() => handleDelete(index)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
