import userResources from "api/userResources";
import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Select from "react-tailwindcss-select";

export default function CreateOffer() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      terms_conditions: [{ value: "" }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "terms_conditions",
  });
  const [offerfor, setOfferfor] = useState(null); // Change initial state to null
  const [offerList, setOfferList] = useState([]);
  const [coupon, setCoupon] = useState({});
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log(offerList, "offerList");
  console.log(options, "option");

  const getCouponList = async () => {
    setLoading(true);
    try {
      const response = await userResources.couponList();
      const formattedOptions = response.data?.data?.map((item) => ({
        value: item.id,
        label: `${item.coupon_code} (${item.discount}%)`,
        expiryDate: item.expiry_date,
      }));
      setLoading(false);
      setOptions(formattedOptions);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleChange = (value) => {
    console.log("value:", value);
    setCoupon(value);
  };

  const handleOfferVlaue = (value) => {
    console.log("value:", value);
    setOfferfor(value);
  };

  const onSubmit = (data) => {
    console.log(data); // You can handle form submission here
  };

  useEffect(() => {
    getCouponList();
    getDealList();
  }, []);

  const getDealList = async () => {
    try {
      setLoading(true);
      const response = await userResources.getDealList();
      // Format options for the select component
      const formattedOptions =
        response?.data?.data.map((deal) => ({
          value: deal.id,
          label: deal.name,
        })) || [];
      setOfferList(formattedOptions);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fields.length === 0) {
      append({ value: "" });
    }
  }, [fields, append]);

  return (
    <section className="mx-auto mt-5 max-w-6xl">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mb-4 grid rounded px-2 pt-6 pb-8 shadow-lg sm:px-8 lg:grid-cols-2 lg:gap-x-4"
      >
        <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="heading"
          >
            Heading
          </label>
          <input
            {...register("heading")}
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
            id="heading"
            type="text"
            placeholder="Heading"
          />
        </div>
        {/* <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="coupon"
          >
            Offer For
          </label>
          <input
            {...register("offer")}
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
            id="offer"
            type="text"
            placeholder="flight,hotel,cruise"
          />
        </div> */}
        <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="offer"
          >
            Offer For
          </label>
          <Select
            value={offerfor}
            onChange={handleOfferVlaue}
            options={offerList}
            loading={loading}
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
          />
        </div>
        <div className="mb-4 lg:col-span-2">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="paragraph"
          >
            Paragraph
          </label>
          <textarea
            {...register("paragraph")}
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
            id="paragraph"
            placeholder="Paragraph"
            rows={1}
          ></textarea>
        </div>
        <div className="mb-4 lg:col-span-2">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="longParagraph"
          >
            Long Paragraph
          </label>
          <textarea
            {...register("longParagraph")}
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
            id="longParagraph"
            placeholder="Long Paragraph"
            rows={1}
          ></textarea>
        </div>

        <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="coupon"
          >
            Coupon
          </label>
          <Select
            value={coupon}
            onChange={handleChange}
            options={options}
            loading={loading}
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
          />
        </div>
        <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="booking_validity"
          >
            Booking Validity
          </label>
          <input
            {...register("booking_validity")}
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
            id="booking_validity"
            type="text"
            value={coupon.expiryDate}
            placeholder="Booking Validity"
          />
        </div>
        <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="image"
          >
            Image
          </label>
          <input
            {...register("image")}
            className="file-input file-input-bordered file-input-info w-full max-w-lg bg-white sm:max-w-sm"
            id="image"
            type="file"
          />
        </div>
        <div className="mb-4 lg:col-span-1">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="innerImage"
          >
            Inner Image
          </label>
          <input
            {...register("innerImage")}
            className="file-input file-input-bordered file-input-info w-full max-w-lg bg-white sm:max-w-sm"
            id="innerImage"
            type="file"
          />
        </div>
        <div className="mb-4 lg:col-span-2">
          <label
            className="mb-2.5 block font-medium text-gray-800 dark:text-white"
            htmlFor="terms_conditions"
          >
            Terms & Conditions
          </label>
          {fields.map((field, index) => (
            <div key={field.id} className="mb-2 flex">
              <input
                {...register(`terms_conditions.${index}.value`, {
                  required: true,
                })}
                defaultValue={field.value} // set up defaultValue correctly
                className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input w-full rounded-lg border bg-transparent py-2 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
              />
              <button
                type="button"
                onClick={() => remove(index)}
                className="focus:shadow-outline ml-2 rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700 focus:outline-none"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={() => append({ value: "" })}
            className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
          >
            Add Term
          </button>
        </div>
        <div className="flex items-center justify-end lg:col-span-2">
          <button
            className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </section>
  );
}
