import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const RoutesDropdown = ({ options, name, route }) => {
  const navigate = useNavigate();
  const params = useParams();
  // Extracting the parameter you expect, for example 'id' or other parameter defined in your route
  const currentRoute = params["*"]; // This will capture the current route path

  console.log(currentRoute, "currentroute");
  const isActive = options.some((item) => item.value === currentRoute);
  return (
    <div className="dropdown-hover dropdown">
      <div
        tabIndex={0}
        role="button"
        className={`font-semibold text-gray-500 hover:text-gray-900 dark:hover:text-white ${
          isActive ? "text-gray-900 dark:text-white" : ""
        }`}
      >
        {name}
      </div>
      <ul
        tabIndex={0}
        className="menu dropdown-content z-[1] w-52 rounded-box bg-white text-gray-900 shadow dark:text-gray-900"
      >
        {options.map((option, index) => (
          <li
            key={option.value}
            onClick={() => navigate(option.route)}
            className={`${
              currentRoute == option.value || currentRoute == option.value
                ? "font-semibold"
                : ""
            } p-2 hover:font-semibold`}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RoutesDropdown;
