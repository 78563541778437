import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/Store";
import App from "./App";
import FlightBookingChatbot from "components/FlightBookingChatbot/FlightBookingChatbot";
import "./i18n";
import React from "react";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.Suspense>
          <App />
        </React.Suspense>
        <FlightBookingChatbot />
        <ToastContainer />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);
