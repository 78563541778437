import { jaipur } from "Utils/Images";
import poster from "../../../assets/img/TravelStory/ThingsYouCan’tCarryOnAPlane.jpeg";
import { indiaGate } from "Utils/Images";
import { india } from "Utils/Images";
import { tajmehal } from "Utils/Images";
import { Mumbai } from "Utils/Images";
import { Varanasi } from "Utils/Images";
import { Udaipur } from "Utils/Images";
import { Kochi } from "Utils/Images";
import { Amritsar } from "Utils/Images";
import { Chennai } from "Utils/Images";
import { Kolkata } from "Utils/Images";
import { prewedding } from "Utils/Images";
import { humayutomb } from "Utils/Images";
import { tajmehalshoot } from "Utils/Images";
import { RannofKutch } from "Utils/Images";
import { hwamehal } from "Utils/Images";
import { goaphotoshoot } from "Utils/Images";
import { Kerala_Backwaters_shoot } from "Utils/Images";
import { submarineHotal } from "Utils/Images";
import { udaipurPrewedding } from "Utils/Images";
const travel_stories = [
  {
    id: 1,
    poster: india,
    heading: "Hand Luggage Restrictions – Things You Can’t Carry On A Plane",
    writtenBy: "Outher Name",
    writtenDate: "June 1, 2023",
    discruption:
      "When traveling by air, it's essential to be aware of hand luggage restrictions to ensure a smooth and safe journey. Here's a comprehensive guide on items you can't carry on a plane:",
    firstHeading: "Liquids and Gels",
    firstImage: indiaGate,
    firstDis: [
      {
        heading: "Sharp Objects",
        li: "Sharp objects such as knives, scissors, and razor blades are prohibited in hand luggage. These items pose a security risk and must be packed in checked baggage.",
      },
      {
        heading: "Sporting Equipment",
        li: "Sporting equipment such as bats, clubs, and martial arts weapons are not allowed in hand luggage. Ensure such items are securely packed in checked baggage or make prior arrangements with the airline.",
      },
      {
        heading: "Tools and Equipment",
        li: "Tools and equipment capable of causing harm, including drills, saws, and crowbars, are restricted from hand luggage. These items should be packed securely in checked baggage or transported via other means.",
      },
      {
        heading: "Explosives and Flammable Materials",
        li: "Explosives, flammable liquids, gases, and fireworks are strictly prohibited in hand luggage. Any items with explosive or flammable properties must be declared and transported in accordance with airline regulations.",
      },
      {
        heading: "Self-Defense Items",
        li: "Self-defense items such as pepper spray, tasers, and stun guns are not permitted in hand luggage. Passengers requiring such items for personal safety should contact the airline for guidance on transportation protocols.",
      },
      {
        heading: "Lithium Batteries",
        li: "Lithium batteries exceeding specified watt-hour ratings are restricted from hand luggage due to safety concerns. Passengers should refer to airline guidelines for proper handling and transportation of electronic devices containing lithium batteries.",
      },
      {
        heading: "Disabling Devices",
        li: "Devices designed to interfere with aircraft systems, including jammers and signal disruptors, are strictly prohibited in hand luggage. Any such devices found will result in severe penalties and potential legal consequences.",
      },
      {
        heading: "Chemical Substances",
        li: "Chemical substances capable of causing harm or posing a risk to passengers and crew members are not allowed in hand luggage. Passengers transporting essential chemicals for medical or scientific purposes should seek prior approval from the airline.",
      },
      {
        heading: "Alcoholic Beverages",
        li: "Alcoholic beverages exceeding the permissible limit are prohibited in hand luggage. Duty-free alcohol purchased at the airport should be packed securely in checked baggage or collected upon arrival.",
      },
      {
        heading: "Biological Hazards",
        li: "Biological hazards such as infectious materials, biological samples, and genetically modified organisms are strictly prohibited in hand luggage. Proper containment and transportation protocols must be followed for such items.",
      },
    ],
    firstparagraph:
      "Understanding the regulations regarding liquids and gels is crucial for hassle-free airport security checks and compliance with airline policies.",
    secondHeading: "Delhi",
    secondImage: indiaGate,
    secondDis: [
      {
        heading: "Akshardham Temple",
        li: "This sprawling temple complex showcases Indian culture, spirituality, and architecture through exhibitions, boat rides, and stunning architecture.",
      },
      {
        heading: "Jama Masjid",
        li: "One of the largest mosques in India, Jama Masjid was built by the Mughal Emperor Shah Jahan and is a stunning example of Mughal architecture.",
      },
      {
        heading: "Gurudwara Bangla Sahib",
        li: "A prominent Sikh house of worship, this gurudwara offers a serene atmosphere and a chance to experience Sikh hospitality through langar (community kitchen).",
      },
      {
        heading: "Chandni Chowk",
        li: "One of the oldest and busiest markets in Delhi, Chandni Chowk offers a glimpse into the city's vibrant street life, with narrow lanes filled with shops selling everything from spices to jewelry.",
      },
      {
        heading: "National Museum",
        li: "Located in New Delhi, the National Museum houses an extensive collection of artifacts spanning thousands of years of Indian history and culture.",
      },
      {
        heading: "Red Fort",
        li: "A UNESCO World Heritage Site, this massive red sandstone fort was the main residence of the emperors of the Mughal dynasty for nearly 200 years.",
      },
      {
        heading: "Qutub Minar",
        li: "Another UNESCO World Heritage Site, Qutub Minar is the tallest brick minaret in the world and a magnificent example of Indo-Islamic architecture.",
      },
      {
        heading: "Humayun's Tomb",
        li: "This stunning tomb, commissioned by Humayun's widow, is a masterpiece of Mughal architecture and is also a UNESCO World Heritage Site.",
      },
      {
        heading: "India Gate",
        li: "A war memorial located astride the Rajpath, India Gate is dedicated to the soldiers who died during World War I and the Third Anglo-Afghan War.",
      },
      {
        heading: "Lotus Temple",
        li: "Known for its flower-like shape, the Lotus Temple is a Bahá'í House of Worship where people of all religions can come together to pray and meditate.",
      },
    ],
    secondparagraph:
      "Note : The capital city offers a blend of historic monuments and bustling markets alongside chic cafes and bars. Key sights include the Red Fort, India Gate, and Qutub Minar.",
    thirdHeading: "Agra",
    thirdImage: tajmehal,
    thirdDis: [
      {
        heading: "Taj Mahal",
        li: "One of the Seven Wonders of the World, the Taj Mahal is an iconic symbol of love and a UNESCO World Heritage Site. Built by Emperor Shah Jahan in memory of his wife Mumtaz Mahal, it is renowned for its stunning white marble architecture and intricate craftsmanship.",
      },
      {
        heading: "Agra Fort",
        li: "Also known as the Red Fort of Agra, this UNESCO World Heritage Site served as the main residence of the Mughal emperors until 1638. It features impressive red sandstone walls and palaces, including the Jahangir Palace and the Khas Mahal.",
      },
      {
        heading: "Fatehpur Sikri",
        li: "A UNESCO World Heritage Site, Fatehpur Sikri is a well-preserved Mughal city built by Emperor Akbar in the 16th century. It houses notable structures like the Buland Darwaza, Jama Masjid, and the Tomb of Salim Chishti.",
      },
      {
        heading: "Itmad-ud-Daulah's Tomb",
        li: "Often referred to as the 'Baby Taj,' this tomb is an exquisite example of Mughal architecture and is believed to be the inspiration for the Taj Mahal. It was built by Empress Nur Jahan for her father, Mirza Ghiyas Beg.",
      },
      {
        heading: "Mehtab Bagh",
        li: "Situated across the Yamuna River from the Taj Mahal, Mehtab Bagh offers stunning views of the monument and is a popular spot for sunset photography.",
      },
      {
        heading: "Jama Masjid",
        li: "Agra's largest mosque, Jama Masjid was built by Shah Jahan in 1648 and is an architectural masterpiece adorned with intricate marble work and calligraphy.",
      },
      {
        heading: "Tomb of Akbar the Great",
        li: "Located in Sikandra, the tomb of Emperor Akbar is an impressive mausoleum surrounded by lush gardens. Its construction was initiated by Akbar and completed by his son, Jahangir.",
      },
      {
        heading: "Mariam's Tomb",
        li: "Situated in Sikandra, Mariam's Tomb is the final resting place of Mariam-uz-Zamani, the wife of Emperor Akbar and the mother of Emperor Jahangir.",
      },
      {
        heading: "Chini-ka-Rauza",
        li: "This mausoleum is dedicated to the Prime Minister of Emperor Shah Jahan, Allama Afzal Khan Mullah. It is famous for its intricate Persian glazed tile work, which gives it a distinct appearance.",
      },
      {
        heading: "Guru Ka Taal",
        li: "Located in Agra's Sikandra area, Guru Ka Taal is a historical Sikh pilgrimage site with a sacred pond and a Gurudwara dedicated to Guru Tegh Bahadur, the ninth Sikh Guru.",
      },
    ],
    thirdparagraph:
      "Note : Home to the incomparable Taj Mahal, Agra is a must-visit for a glimpse into the architectural grandeur of the Mughal Empire.",

    //
    fourthHeading: "Mumbai",
    fourthImage: Mumbai,
    fourthDis: [
      {
        heading: "Gateway of India",
        li: "The Gateway of India is an iconic monument located in Mumbai overlooking the Arabian Sea. Built during the British Raj, it serves as a symbol of India's rich history and is a popular tourist attraction.",
      },
      {
        heading: "Elephanta Caves",
        li: "Located on Elephanta Island in Mumbai Harbor, the Elephanta Caves are a UNESCO World Heritage Site renowned for their ancient rock-cut temples dedicated to Lord Shiva. The caves feature intricate sculptures and artwork dating back to the 5th century.",
      },
      {
        heading: "Marine Drive",
        li: "Marine Drive, also known as the Queen's Necklace, is a picturesque promenade in South Mumbai overlooking the Arabian Sea. It is famous for its stunning views, especially at sunset, and is a popular spot for leisurely walks.",
      },
      {
        heading: "Chhatrapati Shivaji Maharaj Vastu Sangrahalaya",
        li: "Formerly known as the Prince of Wales Museum, this museum in Mumbai houses an extensive collection of artifacts, artwork, and archaeological exhibits showcasing India's rich cultural heritage. It is a must-visit for history and art enthusiasts.",
      },
      {
        heading: "Haji Ali Dargah",
        li: "The Haji Ali Dargah is a prominent Islamic shrine and mosque located on an islet off the coast of Worli in Mumbai. Dedicated to the Sufi saint Haji Ali, it is visited by thousands of pilgrims and tourists seeking blessings and spiritual solace.",
      },
      {
        heading: "Juhu Beach",
        li: "Juhu Beach is one of Mumbai's most popular beaches, known for its vibrant atmosphere and stunning sunsets. It offers a variety of activities such as horse riding, street food, and leisurely strolls along the shore.",
      },
      {
        heading: "Siddhivinayak Temple",
        li: "The Siddhivinayak Temple is a revered Hindu temple dedicated to Lord Ganesha, located in Prabhadevi, Mumbai. It is one of the richest temples in Mumbai and attracts devotees from all over the country, especially on auspicious occasions.",
      },
      {
        heading: "Sanjay Gandhi National Park",
        li: "Sanjay Gandhi National Park is a sprawling protected area in Mumbai, known for its diverse flora and fauna, including leopards, deer, and a variety of bird species. It offers opportunities for nature walks, safaris, and eco-tourism activities.",
      },
      {
        heading: "Chhatrapati Shivaji Maharaj Terminus",
        li: "Formerly known as Victoria Terminus, Chhatrapati Shivaji Maharaj Terminus is a historic railway station in Mumbai, known for its impressive Gothic architecture and intricate detailing. It is a UNESCO World Heritage Site and a bustling hub of commuter activity.",
      },
      {
        heading: "Colaba Causeway",
        li: "Colaba Causeway is a bustling street market in South Mumbai, famous for its eclectic mix of shops selling everything from clothing and accessories to handicrafts and souvenirs. It's a great place to shop, bargain, and experience Mumbai's vibrant street life.",
      },
    ],
    fourthparagraph:
      "Note : Known as the financial capital and the city of dreams, Mumbai offers everything from Bollywood tours to historic sites like the Gateway of India and vibrant street markets.",
    fiveHeading: "Varanasi",
    fiveImage: Varanasi,
    fiveDis: [
      {
        heading: "Kashi Vishwanath Temple",
        li: "One of the holiest Hindu temples, the Kashi Vishwanath Temple is dedicated to Lord Shiva and is located in the heart of Varanasi. It is believed to be one of the twelve Jyotirlingas and attracts millions of devotees each year.",
      },
      {
        heading: "Dashashwamedh Ghat",
        li: "Dashashwamedh Ghat is one of the oldest and most important ghats in Varanasi, situated on the banks of the river Ganges. It is famous for its daily Ganga Aarti ceremony, where priests perform rituals to honor the river goddess.",
      },
      {
        heading: "Assi Ghat",
        li: "Assi Ghat is a popular ghat located at the confluence of the rivers Ganges and Assi. It is known for its serene atmosphere and is a favorite spot for meditation, yoga, and boat rides on the Ganges.",
      },
      {
        heading: "Sarnath",
        li: "Sarnath is a significant Buddhist pilgrimage site located near Varanasi. It is where Lord Buddha gave his first sermon after attaining enlightenment. The site features ancient stupas, monasteries, and the Ashoka Pillar.",
      },
      {
        heading: "Manikarnika Ghat",
        li: "Manikarnika Ghat is one of the oldest and most sacred ghats in Varanasi, where Hindu cremation ceremonies take place round the clock. It is believed that cremation at this ghat ensures liberation from the cycle of birth and death.",
      },
      {
        heading: "Banaras Hindu University (BHU)",
        li: "Banaras Hindu University is one of the largest residential universities in Asia and a prominent educational institution in Varanasi. The campus features beautiful architecture, museums, and the Bharat Kala Bhavan museum.",
      },
      {
        heading: "Ramnagar Fort",
        li: "Located on the eastern banks of the river Ganges, Ramnagar Fort is a historic fort-palace complex dating back to the 18th century. It houses a museum with a rich collection of vintage cars, royal artifacts, and weapons.",
      },
      {
        heading: "Tulsi Manas Temple",
        li: "The Tulsi Manas Temple is dedicated to Lord Rama and is situated near the Durga Temple in Varanasi. It is constructed at the site where the Hindu epic Ramcharitmanas was composed by the poet Goswami Tulsidas.",
      },
      {
        heading: "Durga Temple (Durga Kund Mandir)",
        li: "Dedicated to Goddess Durga, the Durga Temple is one of the most revered temples in Varanasi. It is known for its vibrant architecture and colorful festivals, especially during Navratri.",
      },
      {
        heading: "Bharat Mata Temple",
        li: "The Bharat Mata Temple, also known as the Mother India Temple, is a unique shrine dedicated to the motherland. It features a relief map of undivided India carved in marble and is a symbol of patriotism and national unity.",
      },
    ],
    fiveparagraph:
      "Note : One of the world's oldest continually inhabited cities and a spiritual center for Hindus, Varanasi is known for its riverside rituals and vibrant ceremonies.",
    sixHeading: "Udaipur",
    sixImage: Udaipur,
    sixDis: [
      {
        heading: "City Palace",
        li: "The City Palace is a magnificent complex situated on the banks of Lake Pichola. It is a blend of Rajasthani and Mughal architectural styles and houses several palaces, courtyards, and museums showcasing royal artifacts and heritage.",
      },
      {
        heading: "Lake Pichola",
        li: "Lake Pichola is a picturesque artificial lake created in the 14th century. It is surrounded by stunning palaces, including the Jag Mandir and the Lake Palace, which appear to float on its tranquil waters.",
      },
      {
        heading: "Jag Mandir",
        li: "Jag Mandir is an exquisite palace located on an island in Lake Pichola. Built in the 17th century, it features beautiful gardens, marble pavilions, and intricate carvings, offering breathtaking views of the lake and surrounding hills.",
      },
      {
        heading: "Lake Palace",
        li: "The Lake Palace, also known as the Jag Niwas Palace, is a luxurious hotel situated on an island in Lake Pichola. Built as a summer palace in the 18th century, it is renowned for its stunning architecture, elegant interiors, and royal hospitality.",
      },
      {
        heading: "Saheliyon-ki-Bari",
        li: "Saheliyon-ki-Bari, or the Garden of the Maidens, is a beautiful garden adorned with fountains, kiosks, and marble pavilions. Built by Maharana Sangram Singh in the 18th century, it was a leisure spot for the royal ladies and their attendants.",
      },
      {
        heading: "Jagdish Temple",
        li: "The Jagdish Temple is a majestic Hindu temple dedicated to Lord Vishnu. Built in the 17th century, it features intricate carvings, sculptures, and a towering spire. The temple is a significant religious and architectural landmark in Udaipur.",
      },
      {
        heading: "Fateh Sagar Lake",
        li: "Fateh Sagar Lake is an artificial lake known for its serene surroundings and scenic beauty. It offers boating facilities and stunning views of the surrounding hills, making it a popular spot for leisure activities and picnics.",
      },
      {
        heading: "Bagore ki Haveli",
        li: "Bagore ki Haveli is a historic mansion converted into a museum showcasing Udaipur's cultural heritage. It features a collection of royal artifacts, costumes, and traditional Rajasthani artwork. The haveli also hosts cultural performances in the evening.",
      },
      {
        heading: "Monsoon Palace",
        li: "The Monsoon Palace, also known as the Sajjangarh Palace, is a hilltop palace offering panoramic views of Udaipur and its surroundings. Built by Maharana Sajjan Singh in the 19th century, it was originally intended as an astronomical observatory and monsoon retreat for the royal family.",
      },
      {
        heading: "Shilpgram",
        li: "Shilpgram is a rural arts and crafts complex located near Udaipur. It showcases traditional Rajasthani architecture, handicrafts, and cultural performances, providing visitors with an immersive experience of rural life and craftsmanship.",
      },
    ],
    sixparagraph:
      "Note : Often called the 'City of Lakes,' Udaipur is famous for its picturesque lakes and royal palaces. The cityscape is a popular backdrop for weddings and films.",
    savenHeading: "Kochi",
    savenImage: Kochi,
    savenDis: [
      {
        heading: "Fort Kochi",
        li: "Fort Kochi is a historic neighborhood known for its colonial-era architecture, narrow streets, and vibrant cultural scene. It features attractions like the Fort Kochi Beach, Chinese fishing nets, and colonial buildings.",
      },
      {
        heading: "Mattancherry Palace",
        li: "Also known as the Dutch Palace, Mattancherry Palace is a Portuguese-built palace with Kerala murals depicting Hindu temple art, portraits, and exhibits of the Kochi royal family's possessions.",
      },
      {
        heading: "Jew Town",
        li: "Jew Town is a historic area in Mattancherry known for its antique shops, spice markets, and the Paradesi Synagogue, the oldest active synagogue in the Commonwealth of Nations.",
      },
      {
        heading: "Chinese Fishing Nets",
        li: "The Chinese Fishing Nets are iconic landmarks of Kochi's coastline. These massive fishing nets, believed to have been introduced by Chinese traders in the 14th century, are still used by local fishermen today.",
      },
      {
        heading: "St. Francis Church",
        li: "St. Francis Church is one of the oldest European churches in India and holds historical significance as the burial site of the explorer Vasco da Gama, whose remains were buried here in 1524.",
      },
      {
        heading: "Santa Cruz Basilica",
        li: "The Santa Cruz Basilica is a Roman Catholic church built by the Portuguese in the 16th century. It is known for its architectural grandeur, vibrant interior decorations, and beautiful stained glass windows.",
      },
      {
        heading: "Kerala Folklore Museum",
        li: "The Kerala Folklore Museum is a cultural museum showcasing the rich heritage and traditions of Kerala through artifacts, costumes, masks, sculptures, and performing arts.",
      },
      {
        heading: "Marine Drive",
        li: "Marine Drive is a picturesque promenade along the backwaters of Kochi, offering stunning views of the Arabian Sea and the city skyline. It's a popular spot for evening walks, leisure activities, and enjoying beautiful sunsets.",
      },
      {
        heading: "Hill Palace Museum",
        li: "The Hill Palace Museum is the largest archaeological museum in Kerala, housed in the former royal palace of the Kochi Maharajas. It features a vast collection of artifacts, paintings, sculptures, and royal belongings.",
      },
      {
        heading: "Veegaland Amusement Park",
        li: "Veegaland Amusement Park, now known as Wonderla Kochi, is a popular theme park offering thrilling rides, water slides, and entertainment attractions for visitors of all ages.",
      },
    ],
    savenparagraph:
      "Note : A charming coastal city in Kerala, Kochi has a distinct colonial heritage with Portuguese, Dutch, and British influences visible in its architecture and old spice markets.",

    eightHeading: "Amritsar",
    eightImage: Amritsar,
    eightDis: [
      {
        heading: "Golden Temple (Harmandir Sahib)",
        li: "The Golden Temple, also known as Harmandir Sahib, is the holiest Gurdwara and the most iconic landmark in Amritsar. It is a place of worship for Sikhs and people of other faiths, renowned for its stunning golden architecture and spiritual significance.",
      },
      {
        heading: "Jallianwala Bagh",
        li: "Jallianwala Bagh is a historic public garden that holds significance for its tragic past. It was the site of the Jallianwala Bagh massacre in 1919, where British troops opened fire on a peaceful gathering, resulting in hundreds of deaths.",
      },
      {
        heading: "Wagah Border",
        li: "The Wagah Border is a ceremonial border crossing between India and Pakistan, located near Amritsar. Visitors can witness the famous Wagah Border Ceremony, a daily military parade and flag-lowering ceremony performed by the Indian Border Security Force and the Pakistan Rangers.",
      },
      {
        heading: "Akal Takht",
        li: "Akal Takht, meaning the 'Throne of the Almighty,' is one of the five Takhts (seats of authority) in Sikhism. It is located within the Golden Temple complex and serves as the highest temporal seat of Sikh authority, where important decisions are made concerning Sikhism.",
      },
      {
        heading: "Partition Museum",
        li: "The Partition Museum in Amritsar is dedicated to preserving the memory and stories of the Partition of India in 1947. It houses artifacts, documents, photographs, and personal accounts that depict the tumultuous period of Partition and its impact on millions of lives.",
      },
      {
        heading: "Gobindgarh Fort",
        li: "Gobindgarh Fort is a historic fort located in the heart of Amritsar. Built by Maharaja Ranjit Singh, it has served as a symbol of Sikh strength and sovereignty. The fort now houses museums, exhibitions, and cultural shows that highlight Punjab's rich history and heritage.",
      },
      {
        heading: "Durgiana Temple",
        li: "The Durgiana Temple, also known as the Lakshmi Narayan Temple, is a Hindu temple dedicated to Goddess Durga. It is built in a similar architectural style to the Golden Temple and is a popular pilgrimage site for Hindus visiting Amritsar.",
      },
      {
        heading: "Maharaja Ranjit Singh Museum",
        li: "The Maharaja Ranjit Singh Museum is located within the Ram Bagh Palace complex in Amritsar. It showcases artifacts, weapons, paintings, and personal belongings of Maharaja Ranjit Singh, the founder of the Sikh Empire.",
      },
      {
        heading: "Gurudwara Baba Atal Rai",
        li: "Gurudwara Baba Atal Rai is a Sikh gurudwara located near the Golden Temple. It is dedicated to Baba Atal Rai, the son of Guru Hargobind Sahib, and is known for its beautiful architecture and spiritual ambiance.",
      },
      {
        heading: "Gurudwara Mata Kaulan",
        li: "Gurudwara Mata Kaulan is a historic gurudwara in Amritsar dedicated to Mata Kaulan, a spiritual disciple of Guru Har Rai Sahib Ji. It is revered by Sikhs and features stunning architecture and religious significance.",
      },
    ],
    eightparagraph:
      "Note : The spiritual center for the Sikh religion, Amritsar is home to the Golden Temple, one of the most revered spiritual sites in India.",
    nineHeading: "Chennai",
    nineImage: Chennai,
    nineDis: [
      {
        heading: "Marina Beach",
        li: "Marina Beach is one of the longest urban beaches in the world, stretching along the Bay of Bengal. It is a popular destination for leisurely walks, beach activities, and enjoying the sea breeze.",
      },
      {
        heading: "Kapaleeshwarar Temple",
        li: "The Kapaleeshwarar Temple is a historic Hindu temple dedicated to Lord Shiva. It is renowned for its Dravidian architecture, intricately carved gopurams (gateway towers), and vibrant festivals, including the Arubathimoovar festival.",
      },
      {
        heading: "Fort St. George",
        li: "Fort St. George is a historic fortress built by the British East India Company in the 17th century. It houses the Fort Museum, showcasing artifacts, documents, and memorabilia from the colonial era.",
      },
      {
        heading: "Government Museum, Chennai",
        li: "The Government Museum in Chennai is one of the oldest museums in India, featuring a diverse collection of artifacts, sculptures, paintings, and archaeological exhibits. Highlights include the Bronze Gallery and the National Art Gallery.",
      },
      {
        heading: "San Thome Basilica",
        li: "San Thome Basilica is a Roman Catholic basilica built over the tomb of St. Thomas, the apostle who preached in India. It is a significant pilgrimage site for Christians and features neo-Gothic architecture and beautiful stained glass windows.",
      },
      {
        heading: "MGR Film City",
        li: "MGR Film City is a sprawling film studio complex named after the legendary actor and former Chief Minister of Tamil Nadu, M.G. Ramachandran. It offers guided tours, film screenings, and opportunities to witness film shootings.",
      },
      {
        heading: "Arignar Anna Zoological Park",
        li: "Arignar Anna Zoological Park, also known as the Vandalur Zoo, is one of the largest zoological parks in India. It is home to a wide variety of exotic animals, birds, and reptiles, as well as an extensive safari park.",
      },
      {
        heading: "Guindy National Park",
        li: "Guindy National Park is a protected area located in the heart of Chennai. It is home to diverse flora and fauna, including endangered species like the Indian star tortoise and the blackbuck. The park also features historical monuments and a children's park.",
      },
      {
        heading: "Marundeeswarar Temple",
        li: "The Marundeeswarar Temple is a renowned Hindu temple dedicated to Lord Shiva, known for its medicinal properties. It is believed that prayers and offerings at this temple can cure ailments and bring good health.",
      },
      {
        heading: "Chennai Central Railway Station",
        li: "Chennai Central Railway Station is one of the most important railway hubs in South India. Built-in the British colonial era, it is known for its architectural grandeur and serves as a gateway to Chennai and beyond.",
      },
    ],
    nineparagraph:
      "Note : Known for its deep-rooted traditions and classical music and dance scenes, Chennai is also a gateway to exploring the temples and cultural sites of Tamil Nadu.",
    tenHeading: "Kolkata",
    tenImage: Kolkata,
    tenDis: [
      {
        heading: "Victoria Memorial",
        li: "The Victoria Memorial is a grand marble building dedicated to Queen Victoria. It serves as a museum showcasing artifacts, paintings, and sculptures from the British colonial era, as well as beautiful gardens and monuments.",
      },
      {
        heading: "Howrah Bridge",
        li: "The Howrah Bridge is an iconic cantilever bridge that spans the Hooghly River, connecting Kolkata with the neighboring city of Howrah. It is one of the busiest and most recognizable landmarks in Kolkata, offering stunning views of the river and cityscape.",
      },
      {
        heading: "Dakshineswar Kali Temple",
        li: "The Dakshineswar Kali Temple is a renowned Hindu temple dedicated to Goddess Kali. It is located on the eastern bank of the Hooghly River and is known for its intricate architecture, serene surroundings, and religious significance.",
      },
      {
        heading: "Indian Museum",
        li: "The Indian Museum in Kolkata is the largest and oldest museum in India, housing a vast collection of artifacts, sculptures, fossils, and artworks representing various cultures and civilizations. It is a treasure trove of history and heritage.",
      },
      {
        heading: "Belur Math",
        li: "Belur Math is the headquarters of the Ramakrishna Math and Mission, founded by Swami Vivekananda. It is a spiritual and educational center known for its serene ambiance, architectural beauty, and teachings of Sri Ramakrishna and Swami Vivekananda.",
      },
      {
        heading: "Kalighat Kali Temple",
        li: "The Kalighat Kali Temple is one of the oldest and most sacred Hindu temples in Kolkata, dedicated to Goddess Kali. Pilgrims visit this temple to seek blessings and participate in religious rituals, especially during festivals like Kali Puja.",
      },
      {
        heading: "Science City Kolkata",
        li: "Science City Kolkata is an interactive science center and museum designed to promote scientific education and awareness. It features interactive exhibits, galleries, a space theater, and outdoor science-themed attractions.",
      },
      {
        heading: "South Park Street Cemetery",
        li: "The South Park Street Cemetery is a historic colonial-era cemetery in Kolkata, dating back to the late 18th century. It is notable for its Gothic-style tombs, mausoleums, and cenotaphs, as well as its serene and atmospheric ambiance.",
      },
      {
        heading: "Princep Ghat",
        li: "Princep Ghat is a beautiful riverside promenade along the banks of the Hooghly River. It is named after James Princep, a British scholar, and features a picturesque monument, gardens, and boat rides offering panoramic views of the river.",
      },
      {
        heading: "Birla Mandir Kolkata",
        li: "The Birla Mandir, also known as the Lakshmi-Narayan Temple, is a Hindu temple dedicated to Lord Vishnu and Goddess Lakshmi. It is renowned for its architectural beauty, intricate carvings, and religious ceremonies.",
      },
    ],
    tenparagraph:
      "Note : Known as the cultural capital of India, Kolkata has a unique blend of colonial architecture and strong Bengali traditions, exemplified in its literature, cuisine, and festivals.",
    //

    // fourthHeading: "Hand Baggage Rules",
    // fourthImage: poster,
    // imgDis:
    //   "If you are planning to take a domestic and international holiday anytime soon, we recommend you always travel light. Improper carriage or restricted items on a flight can put one through a lot of inconveniences.",
    // fourthDis: [
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    // ],
    // fourthparagraph:
    //   "We hope these tips were informative for you. But wait, there is one more thing we can do for you, and that is arrange visa services for all countries online for you. So simply contact us and let Akbar Travels customize your trip for you.",
  },
  {
    id: 2,
    poster: india,
    heading: "Top 10 cities to visit in India",
    writtenBy: "Outher Name",
    writtenDate: "June 1, 2023",
    discruption:
      "India is a vast country with a rich tapestry of culture, history, and natural beauty. Each city offers a unique experience. Here are ten of the top cities to visit in India, each known for their distinct attractions:",
    firstHeading: "Jaipur",
    firstImage: jaipur,
    firstDis: [
      {
        heading: "Hawa Mahal",
        li: "A five-story palace made of pink sandstone, Hawa Mahal, or the Palace of Winds, is a distinctive landmark in Jaipur. Its facade, resembling a honeycomb of a beehive, allows cool air to pass through, providing relief from the heat. Built in 1799, it offers stunning views of the city.",
      },
      {
        heading: "Amber Fort",
        li: "Amber Fort, located on a hill, blends Hindu and Rajput architectural styles. Built in the 16th century, it features intricate artwork, including the famous Sheesh Mahal with its mirrored walls. Offering panoramic views, it's a UNESCO World Heritage Site.",
      },
      {
        heading: "City Palace, Jaipur",
        li: "City Palace, a blend of Rajput, Mughal, and European architecture, is a magnificent complex housing museums and gardens. Built in the 18th century, it's a testament to Jaipur's rich heritage and royal legacy.",
      },
      {
        heading: "Jantar Mantar",
        li: "Jantar Mantar, an 18th-century astronomical observatory, is a UNESCO World Heritage Site. Its instruments, including the world's largest stone sundial, showcase the scientific prowess of ancient India.",
      },
      {
        heading: "Nahargarh Fort",
        li: "Nahargarh Fort, situated on the Aravalli Hills, offers breathtaking views of Jaipur. Built in the 18th century, it was originally a retreat for the royals. The fort's architectural grandeur and historical significance attract visitors.",
      },
      {
        heading: "Jaigarh Fort",
        li: "Jaigarh Fort, perched on the Aravalli Hills, is famous for its massive cannon, Jaivana. Built in the early 18th century, it offers panoramic views of Jaipur and houses several historic structures.",
      },
      {
        heading: "Albert Hall Museum",
        li: "Albert Hall Museum, Rajasthan's oldest museum, is housed in a magnificent Indo-Saracenic building. Its diverse collection of artifacts provides insights into Rajasthan's cultural heritage.",
      },
      {
        heading: "Jal Mahal",
        li: "Jal Mahal, a beautiful palace amidst Man Sagar Lake, offers picturesque views. Built in the 18th century, it's a blend of Rajput and Mughal architectural styles, attracting tourists and photographers.",
      },
      {
        heading: "Galtaji Temple",
        li: "Galtaji Temple, also known as the Monkey Temple, is an ancient Hindu pilgrimage site surrounded by the Aravalli Hills. Its natural springs and intricate architecture make it a spiritual and cultural landmark.",
      },
      {
        heading: "Birla Mandir",
        li: "Birla Mandir, a modern Hindu temple made of white marble, is dedicated to Lord Vishnu and Goddess Lakshmi. Built in the 20th century, its serene ambiance and intricate carvings attract devotees and tourists alike.",
      },
    ],
    firstparagraph:
      "Note : Known as the 'Pink City' for its iconic colored buildings, Jaipur is part of the Golden Triangle tourist circuit and famed for its grand palaces and forts.",
    secondHeading: "Delhi",
    secondImage: indiaGate,
    secondDis: [
      {
        heading: "Akshardham Temple",
        li: "This sprawling temple complex showcases Indian culture, spirituality, and architecture through exhibitions, boat rides, and stunning architecture.",
      },
      {
        heading: "Jama Masjid",
        li: "One of the largest mosques in India, Jama Masjid was built by the Mughal Emperor Shah Jahan and is a stunning example of Mughal architecture.",
      },
      {
        heading: "Gurudwara Bangla Sahib",
        li: "A prominent Sikh house of worship, this gurudwara offers a serene atmosphere and a chance to experience Sikh hospitality through langar (community kitchen).",
      },
      {
        heading: "Chandni Chowk",
        li: "One of the oldest and busiest markets in Delhi, Chandni Chowk offers a glimpse into the city's vibrant street life, with narrow lanes filled with shops selling everything from spices to jewelry.",
      },
      {
        heading: "National Museum",
        li: "Located in New Delhi, the National Museum houses an extensive collection of artifacts spanning thousands of years of Indian history and culture.",
      },
      {
        heading: "Red Fort",
        li: "A UNESCO World Heritage Site, this massive red sandstone fort was the main residence of the emperors of the Mughal dynasty for nearly 200 years.",
      },
      {
        heading: "Qutub Minar",
        li: "Another UNESCO World Heritage Site, Qutub Minar is the tallest brick minaret in the world and a magnificent example of Indo-Islamic architecture.",
      },
      {
        heading: "Humayun's Tomb",
        li: "This stunning tomb, commissioned by Humayun's widow, is a masterpiece of Mughal architecture and is also a UNESCO World Heritage Site.",
      },
      {
        heading: "India Gate",
        li: "A war memorial located astride the Rajpath, India Gate is dedicated to the soldiers who died during World War I and the Third Anglo-Afghan War.",
      },
      {
        heading: "Lotus Temple",
        li: "Known for its flower-like shape, the Lotus Temple is a Bahá'í House of Worship where people of all religions can come together to pray and meditate.",
      },
    ],
    secondparagraph:
      "Note : The capital city offers a blend of historic monuments and bustling markets alongside chic cafes and bars. Key sights include the Red Fort, India Gate, and Qutub Minar.",
    thirdHeading: "Agra",
    thirdImage: tajmehal,
    thirdDis: [
      {
        heading: "Taj Mahal",
        li: "One of the Seven Wonders of the World, the Taj Mahal is an iconic symbol of love and a UNESCO World Heritage Site. Built by Emperor Shah Jahan in memory of his wife Mumtaz Mahal, it is renowned for its stunning white marble architecture and intricate craftsmanship.",
      },
      {
        heading: "Agra Fort",
        li: "Also known as the Red Fort of Agra, this UNESCO World Heritage Site served as the main residence of the Mughal emperors until 1638. It features impressive red sandstone walls and palaces, including the Jahangir Palace and the Khas Mahal.",
      },
      {
        heading: "Fatehpur Sikri",
        li: "A UNESCO World Heritage Site, Fatehpur Sikri is a well-preserved Mughal city built by Emperor Akbar in the 16th century. It houses notable structures like the Buland Darwaza, Jama Masjid, and the Tomb of Salim Chishti.",
      },
      {
        heading: "Itmad-ud-Daulah's Tomb",
        li: "Often referred to as the 'Baby Taj,' this tomb is an exquisite example of Mughal architecture and is believed to be the inspiration for the Taj Mahal. It was built by Empress Nur Jahan for her father, Mirza Ghiyas Beg.",
      },
      {
        heading: "Mehtab Bagh",
        li: "Situated across the Yamuna River from the Taj Mahal, Mehtab Bagh offers stunning views of the monument and is a popular spot for sunset photography.",
      },
      {
        heading: "Jama Masjid",
        li: "Agra's largest mosque, Jama Masjid was built by Shah Jahan in 1648 and is an architectural masterpiece adorned with intricate marble work and calligraphy.",
      },
      {
        heading: "Tomb of Akbar the Great",
        li: "Located in Sikandra, the tomb of Emperor Akbar is an impressive mausoleum surrounded by lush gardens. Its construction was initiated by Akbar and completed by his son, Jahangir.",
      },
      {
        heading: "Mariam's Tomb",
        li: "Situated in Sikandra, Mariam's Tomb is the final resting place of Mariam-uz-Zamani, the wife of Emperor Akbar and the mother of Emperor Jahangir.",
      },
      {
        heading: "Chini-ka-Rauza",
        li: "This mausoleum is dedicated to the Prime Minister of Emperor Shah Jahan, Allama Afzal Khan Mullah. It is famous for its intricate Persian glazed tile work, which gives it a distinct appearance.",
      },
      {
        heading: "Guru Ka Taal",
        li: "Located in Agra's Sikandra area, Guru Ka Taal is a historical Sikh pilgrimage site with a sacred pond and a Gurudwara dedicated to Guru Tegh Bahadur, the ninth Sikh Guru.",
      },
    ],
    thirdparagraph:
      "Note : Home to the incomparable Taj Mahal, Agra is a must-visit for a glimpse into the architectural grandeur of the Mughal Empire.",

    //
    fourthHeading: "Mumbai",
    fourthImage: Mumbai,
    fourthDis: [
      {
        heading: "Gateway of India",
        li: "The Gateway of India is an iconic monument located in Mumbai overlooking the Arabian Sea. Built during the British Raj, it serves as a symbol of India's rich history and is a popular tourist attraction.",
      },
      {
        heading: "Elephanta Caves",
        li: "Located on Elephanta Island in Mumbai Harbor, the Elephanta Caves are a UNESCO World Heritage Site renowned for their ancient rock-cut temples dedicated to Lord Shiva. The caves feature intricate sculptures and artwork dating back to the 5th century.",
      },
      {
        heading: "Marine Drive",
        li: "Marine Drive, also known as the Queen's Necklace, is a picturesque promenade in South Mumbai overlooking the Arabian Sea. It is famous for its stunning views, especially at sunset, and is a popular spot for leisurely walks.",
      },
      {
        heading: "Chhatrapati Shivaji Maharaj Vastu Sangrahalaya",
        li: "Formerly known as the Prince of Wales Museum, this museum in Mumbai houses an extensive collection of artifacts, artwork, and archaeological exhibits showcasing India's rich cultural heritage. It is a must-visit for history and art enthusiasts.",
      },
      {
        heading: "Haji Ali Dargah",
        li: "The Haji Ali Dargah is a prominent Islamic shrine and mosque located on an islet off the coast of Worli in Mumbai. Dedicated to the Sufi saint Haji Ali, it is visited by thousands of pilgrims and tourists seeking blessings and spiritual solace.",
      },
      {
        heading: "Juhu Beach",
        li: "Juhu Beach is one of Mumbai's most popular beaches, known for its vibrant atmosphere and stunning sunsets. It offers a variety of activities such as horse riding, street food, and leisurely strolls along the shore.",
      },
      {
        heading: "Siddhivinayak Temple",
        li: "The Siddhivinayak Temple is a revered Hindu temple dedicated to Lord Ganesha, located in Prabhadevi, Mumbai. It is one of the richest temples in Mumbai and attracts devotees from all over the country, especially on auspicious occasions.",
      },
      {
        heading: "Sanjay Gandhi National Park",
        li: "Sanjay Gandhi National Park is a sprawling protected area in Mumbai, known for its diverse flora and fauna, including leopards, deer, and a variety of bird species. It offers opportunities for nature walks, safaris, and eco-tourism activities.",
      },
      {
        heading: "Chhatrapati Shivaji Maharaj Terminus",
        li: "Formerly known as Victoria Terminus, Chhatrapati Shivaji Maharaj Terminus is a historic railway station in Mumbai, known for its impressive Gothic architecture and intricate detailing. It is a UNESCO World Heritage Site and a bustling hub of commuter activity.",
      },
      {
        heading: "Colaba Causeway",
        li: "Colaba Causeway is a bustling street market in South Mumbai, famous for its eclectic mix of shops selling everything from clothing and accessories to handicrafts and souvenirs. It's a great place to shop, bargain, and experience Mumbai's vibrant street life.",
      },
    ],
    fourthparagraph:
      "Note : Known as the financial capital and the city of dreams, Mumbai offers everything from Bollywood tours to historic sites like the Gateway of India and vibrant street markets.",
    fiveHeading: "Varanasi",
    fiveImage: Varanasi,
    fiveDis: [
      {
        heading: "Kashi Vishwanath Temple",
        li: "One of the holiest Hindu temples, the Kashi Vishwanath Temple is dedicated to Lord Shiva and is located in the heart of Varanasi. It is believed to be one of the twelve Jyotirlingas and attracts millions of devotees each year.",
      },
      {
        heading: "Dashashwamedh Ghat",
        li: "Dashashwamedh Ghat is one of the oldest and most important ghats in Varanasi, situated on the banks of the river Ganges. It is famous for its daily Ganga Aarti ceremony, where priests perform rituals to honor the river goddess.",
      },
      {
        heading: "Assi Ghat",
        li: "Assi Ghat is a popular ghat located at the confluence of the rivers Ganges and Assi. It is known for its serene atmosphere and is a favorite spot for meditation, yoga, and boat rides on the Ganges.",
      },
      {
        heading: "Sarnath",
        li: "Sarnath is a significant Buddhist pilgrimage site located near Varanasi. It is where Lord Buddha gave his first sermon after attaining enlightenment. The site features ancient stupas, monasteries, and the Ashoka Pillar.",
      },
      {
        heading: "Manikarnika Ghat",
        li: "Manikarnika Ghat is one of the oldest and most sacred ghats in Varanasi, where Hindu cremation ceremonies take place round the clock. It is believed that cremation at this ghat ensures liberation from the cycle of birth and death.",
      },
      {
        heading: "Banaras Hindu University (BHU)",
        li: "Banaras Hindu University is one of the largest residential universities in Asia and a prominent educational institution in Varanasi. The campus features beautiful architecture, museums, and the Bharat Kala Bhavan museum.",
      },
      {
        heading: "Ramnagar Fort",
        li: "Located on the eastern banks of the river Ganges, Ramnagar Fort is a historic fort-palace complex dating back to the 18th century. It houses a museum with a rich collection of vintage cars, royal artifacts, and weapons.",
      },
      {
        heading: "Tulsi Manas Temple",
        li: "The Tulsi Manas Temple is dedicated to Lord Rama and is situated near the Durga Temple in Varanasi. It is constructed at the site where the Hindu epic Ramcharitmanas was composed by the poet Goswami Tulsidas.",
      },
      {
        heading: "Durga Temple (Durga Kund Mandir)",
        li: "Dedicated to Goddess Durga, the Durga Temple is one of the most revered temples in Varanasi. It is known for its vibrant architecture and colorful festivals, especially during Navratri.",
      },
      {
        heading: "Bharat Mata Temple",
        li: "The Bharat Mata Temple, also known as the Mother India Temple, is a unique shrine dedicated to the motherland. It features a relief map of undivided India carved in marble and is a symbol of patriotism and national unity.",
      },
    ],
    fiveparagraph:
      "Note : One of the world's oldest continually inhabited cities and a spiritual center for Hindus, Varanasi is known for its riverside rituals and vibrant ceremonies.",
    sixHeading: "Udaipur",
    sixImage: Udaipur,
    sixDis: [
      {
        heading: "City Palace",
        li: "The City Palace is a magnificent complex situated on the banks of Lake Pichola. It is a blend of Rajasthani and Mughal architectural styles and houses several palaces, courtyards, and museums showcasing royal artifacts and heritage.",
      },
      {
        heading: "Lake Pichola",
        li: "Lake Pichola is a picturesque artificial lake created in the 14th century. It is surrounded by stunning palaces, including the Jag Mandir and the Lake Palace, which appear to float on its tranquil waters.",
      },
      {
        heading: "Jag Mandir",
        li: "Jag Mandir is an exquisite palace located on an island in Lake Pichola. Built in the 17th century, it features beautiful gardens, marble pavilions, and intricate carvings, offering breathtaking views of the lake and surrounding hills.",
      },
      {
        heading: "Lake Palace",
        li: "The Lake Palace, also known as the Jag Niwas Palace, is a luxurious hotel situated on an island in Lake Pichola. Built as a summer palace in the 18th century, it is renowned for its stunning architecture, elegant interiors, and royal hospitality.",
      },
      {
        heading: "Saheliyon-ki-Bari",
        li: "Saheliyon-ki-Bari, or the Garden of the Maidens, is a beautiful garden adorned with fountains, kiosks, and marble pavilions. Built by Maharana Sangram Singh in the 18th century, it was a leisure spot for the royal ladies and their attendants.",
      },
      {
        heading: "Jagdish Temple",
        li: "The Jagdish Temple is a majestic Hindu temple dedicated to Lord Vishnu. Built in the 17th century, it features intricate carvings, sculptures, and a towering spire. The temple is a significant religious and architectural landmark in Udaipur.",
      },
      {
        heading: "Fateh Sagar Lake",
        li: "Fateh Sagar Lake is an artificial lake known for its serene surroundings and scenic beauty. It offers boating facilities and stunning views of the surrounding hills, making it a popular spot for leisure activities and picnics.",
      },
      {
        heading: "Bagore ki Haveli",
        li: "Bagore ki Haveli is a historic mansion converted into a museum showcasing Udaipur's cultural heritage. It features a collection of royal artifacts, costumes, and traditional Rajasthani artwork. The haveli also hosts cultural performances in the evening.",
      },
      {
        heading: "Monsoon Palace",
        li: "The Monsoon Palace, also known as the Sajjangarh Palace, is a hilltop palace offering panoramic views of Udaipur and its surroundings. Built by Maharana Sajjan Singh in the 19th century, it was originally intended as an astronomical observatory and monsoon retreat for the royal family.",
      },
      {
        heading: "Shilpgram",
        li: "Shilpgram is a rural arts and crafts complex located near Udaipur. It showcases traditional Rajasthani architecture, handicrafts, and cultural performances, providing visitors with an immersive experience of rural life and craftsmanship.",
      },
    ],
    sixparagraph:
      "Note : Often called the 'City of Lakes,' Udaipur is famous for its picturesque lakes and royal palaces. The cityscape is a popular backdrop for weddings and films.",
    savenHeading: "Kochi",
    savenImage: Kochi,
    savenDis: [
      {
        heading: "Fort Kochi",
        li: "Fort Kochi is a historic neighborhood known for its colonial-era architecture, narrow streets, and vibrant cultural scene. It features attractions like the Fort Kochi Beach, Chinese fishing nets, and colonial buildings.",
      },
      {
        heading: "Mattancherry Palace",
        li: "Also known as the Dutch Palace, Mattancherry Palace is a Portuguese-built palace with Kerala murals depicting Hindu temple art, portraits, and exhibits of the Kochi royal family's possessions.",
      },
      {
        heading: "Jew Town",
        li: "Jew Town is a historic area in Mattancherry known for its antique shops, spice markets, and the Paradesi Synagogue, the oldest active synagogue in the Commonwealth of Nations.",
      },
      {
        heading: "Chinese Fishing Nets",
        li: "The Chinese Fishing Nets are iconic landmarks of Kochi's coastline. These massive fishing nets, believed to have been introduced by Chinese traders in the 14th century, are still used by local fishermen today.",
      },
      {
        heading: "St. Francis Church",
        li: "St. Francis Church is one of the oldest European churches in India and holds historical significance as the burial site of the explorer Vasco da Gama, whose remains were buried here in 1524.",
      },
      {
        heading: "Santa Cruz Basilica",
        li: "The Santa Cruz Basilica is a Roman Catholic church built by the Portuguese in the 16th century. It is known for its architectural grandeur, vibrant interior decorations, and beautiful stained glass windows.",
      },
      {
        heading: "Kerala Folklore Museum",
        li: "The Kerala Folklore Museum is a cultural museum showcasing the rich heritage and traditions of Kerala through artifacts, costumes, masks, sculptures, and performing arts.",
      },
      {
        heading: "Marine Drive",
        li: "Marine Drive is a picturesque promenade along the backwaters of Kochi, offering stunning views of the Arabian Sea and the city skyline. It's a popular spot for evening walks, leisure activities, and enjoying beautiful sunsets.",
      },
      {
        heading: "Hill Palace Museum",
        li: "The Hill Palace Museum is the largest archaeological museum in Kerala, housed in the former royal palace of the Kochi Maharajas. It features a vast collection of artifacts, paintings, sculptures, and royal belongings.",
      },
      {
        heading: "Veegaland Amusement Park",
        li: "Veegaland Amusement Park, now known as Wonderla Kochi, is a popular theme park offering thrilling rides, water slides, and entertainment attractions for visitors of all ages.",
      },
    ],
    savenparagraph:
      "Note : A charming coastal city in Kerala, Kochi has a distinct colonial heritage with Portuguese, Dutch, and British influences visible in its architecture and old spice markets.",

    eightHeading: "Amritsar",
    eightImage: Amritsar,
    eightDis: [
      {
        heading: "Golden Temple (Harmandir Sahib)",
        li: "The Golden Temple, also known as Harmandir Sahib, is the holiest Gurdwara and the most iconic landmark in Amritsar. It is a place of worship for Sikhs and people of other faiths, renowned for its stunning golden architecture and spiritual significance.",
      },
      {
        heading: "Jallianwala Bagh",
        li: "Jallianwala Bagh is a historic public garden that holds significance for its tragic past. It was the site of the Jallianwala Bagh massacre in 1919, where British troops opened fire on a peaceful gathering, resulting in hundreds of deaths.",
      },
      {
        heading: "Wagah Border",
        li: "The Wagah Border is a ceremonial border crossing between India and Pakistan, located near Amritsar. Visitors can witness the famous Wagah Border Ceremony, a daily military parade and flag-lowering ceremony performed by the Indian Border Security Force and the Pakistan Rangers.",
      },
      {
        heading: "Akal Takht",
        li: "Akal Takht, meaning the 'Throne of the Almighty,' is one of the five Takhts (seats of authority) in Sikhism. It is located within the Golden Temple complex and serves as the highest temporal seat of Sikh authority, where important decisions are made concerning Sikhism.",
      },
      {
        heading: "Partition Museum",
        li: "The Partition Museum in Amritsar is dedicated to preserving the memory and stories of the Partition of India in 1947. It houses artifacts, documents, photographs, and personal accounts that depict the tumultuous period of Partition and its impact on millions of lives.",
      },
      {
        heading: "Gobindgarh Fort",
        li: "Gobindgarh Fort is a historic fort located in the heart of Amritsar. Built by Maharaja Ranjit Singh, it has served as a symbol of Sikh strength and sovereignty. The fort now houses museums, exhibitions, and cultural shows that highlight Punjab's rich history and heritage.",
      },
      {
        heading: "Durgiana Temple",
        li: "The Durgiana Temple, also known as the Lakshmi Narayan Temple, is a Hindu temple dedicated to Goddess Durga. It is built in a similar architectural style to the Golden Temple and is a popular pilgrimage site for Hindus visiting Amritsar.",
      },
      {
        heading: "Maharaja Ranjit Singh Museum",
        li: "The Maharaja Ranjit Singh Museum is located within the Ram Bagh Palace complex in Amritsar. It showcases artifacts, weapons, paintings, and personal belongings of Maharaja Ranjit Singh, the founder of the Sikh Empire.",
      },
      {
        heading: "Gurudwara Baba Atal Rai",
        li: "Gurudwara Baba Atal Rai is a Sikh gurudwara located near the Golden Temple. It is dedicated to Baba Atal Rai, the son of Guru Hargobind Sahib, and is known for its beautiful architecture and spiritual ambiance.",
      },
      {
        heading: "Gurudwara Mata Kaulan",
        li: "Gurudwara Mata Kaulan is a historic gurudwara in Amritsar dedicated to Mata Kaulan, a spiritual disciple of Guru Har Rai Sahib Ji. It is revered by Sikhs and features stunning architecture and religious significance.",
      },
    ],
    eightparagraph:
      "Note : The spiritual center for the Sikh religion, Amritsar is home to the Golden Temple, one of the most revered spiritual sites in India.",
    nineHeading: "Chennai",
    nineImage: Chennai,
    nineDis: [
      {
        heading: "Marina Beach",
        li: "Marina Beach is one of the longest urban beaches in the world, stretching along the Bay of Bengal. It is a popular destination for leisurely walks, beach activities, and enjoying the sea breeze.",
      },
      {
        heading: "Kapaleeshwarar Temple",
        li: "The Kapaleeshwarar Temple is a historic Hindu temple dedicated to Lord Shiva. It is renowned for its Dravidian architecture, intricately carved gopurams (gateway towers), and vibrant festivals, including the Arubathimoovar festival.",
      },
      {
        heading: "Fort St. George",
        li: "Fort St. George is a historic fortress built by the British East India Company in the 17th century. It houses the Fort Museum, showcasing artifacts, documents, and memorabilia from the colonial era.",
      },
      {
        heading: "Government Museum, Chennai",
        li: "The Government Museum in Chennai is one of the oldest museums in India, featuring a diverse collection of artifacts, sculptures, paintings, and archaeological exhibits. Highlights include the Bronze Gallery and the National Art Gallery.",
      },
      {
        heading: "San Thome Basilica",
        li: "San Thome Basilica is a Roman Catholic basilica built over the tomb of St. Thomas, the apostle who preached in India. It is a significant pilgrimage site for Christians and features neo-Gothic architecture and beautiful stained glass windows.",
      },
      {
        heading: "MGR Film City",
        li: "MGR Film City is a sprawling film studio complex named after the legendary actor and former Chief Minister of Tamil Nadu, M.G. Ramachandran. It offers guided tours, film screenings, and opportunities to witness film shootings.",
      },
      {
        heading: "Arignar Anna Zoological Park",
        li: "Arignar Anna Zoological Park, also known as the Vandalur Zoo, is one of the largest zoological parks in India. It is home to a wide variety of exotic animals, birds, and reptiles, as well as an extensive safari park.",
      },
      {
        heading: "Guindy National Park",
        li: "Guindy National Park is a protected area located in the heart of Chennai. It is home to diverse flora and fauna, including endangered species like the Indian star tortoise and the blackbuck. The park also features historical monuments and a children's park.",
      },
      {
        heading: "Marundeeswarar Temple",
        li: "The Marundeeswarar Temple is a renowned Hindu temple dedicated to Lord Shiva, known for its medicinal properties. It is believed that prayers and offerings at this temple can cure ailments and bring good health.",
      },
      {
        heading: "Chennai Central Railway Station",
        li: "Chennai Central Railway Station is one of the most important railway hubs in South India. Built-in the British colonial era, it is known for its architectural grandeur and serves as a gateway to Chennai and beyond.",
      },
    ],
    nineparagraph:
      "Note : Known for its deep-rooted traditions and classical music and dance scenes, Chennai is also a gateway to exploring the temples and cultural sites of Tamil Nadu.",
    tenHeading: "Kolkata",
    tenImage: Kolkata,
    tenDis: [
      {
        heading: "Victoria Memorial",
        li: "The Victoria Memorial is a grand marble building dedicated to Queen Victoria. It serves as a museum showcasing artifacts, paintings, and sculptures from the British colonial era, as well as beautiful gardens and monuments.",
      },
      {
        heading: "Howrah Bridge",
        li: "The Howrah Bridge is an iconic cantilever bridge that spans the Hooghly River, connecting Kolkata with the neighboring city of Howrah. It is one of the busiest and most recognizable landmarks in Kolkata, offering stunning views of the river and cityscape.",
      },
      {
        heading: "Dakshineswar Kali Temple",
        li: "The Dakshineswar Kali Temple is a renowned Hindu temple dedicated to Goddess Kali. It is located on the eastern bank of the Hooghly River and is known for its intricate architecture, serene surroundings, and religious significance.",
      },
      {
        heading: "Indian Museum",
        li: "The Indian Museum in Kolkata is the largest and oldest museum in India, housing a vast collection of artifacts, sculptures, fossils, and artworks representing various cultures and civilizations. It is a treasure trove of history and heritage.",
      },
      {
        heading: "Belur Math",
        li: "Belur Math is the headquarters of the Ramakrishna Math and Mission, founded by Swami Vivekananda. It is a spiritual and educational center known for its serene ambiance, architectural beauty, and teachings of Sri Ramakrishna and Swami Vivekananda.",
      },
      {
        heading: "Kalighat Kali Temple",
        li: "The Kalighat Kali Temple is one of the oldest and most sacred Hindu temples in Kolkata, dedicated to Goddess Kali. Pilgrims visit this temple to seek blessings and participate in religious rituals, especially during festivals like Kali Puja.",
      },
      {
        heading: "Science City Kolkata",
        li: "Science City Kolkata is an interactive science center and museum designed to promote scientific education and awareness. It features interactive exhibits, galleries, a space theater, and outdoor science-themed attractions.",
      },
      {
        heading: "South Park Street Cemetery",
        li: "The South Park Street Cemetery is a historic colonial-era cemetery in Kolkata, dating back to the late 18th century. It is notable for its Gothic-style tombs, mausoleums, and cenotaphs, as well as its serene and atmospheric ambiance.",
      },
      {
        heading: "Princep Ghat",
        li: "Princep Ghat is a beautiful riverside promenade along the banks of the Hooghly River. It is named after James Princep, a British scholar, and features a picturesque monument, gardens, and boat rides offering panoramic views of the river.",
      },
      {
        heading: "Birla Mandir Kolkata",
        li: "The Birla Mandir, also known as the Lakshmi-Narayan Temple, is a Hindu temple dedicated to Lord Vishnu and Goddess Lakshmi. It is renowned for its architectural beauty, intricate carvings, and religious ceremonies.",
      },
    ],
    tenparagraph:
      "Note : Known as the cultural capital of India, Kolkata has a unique blend of colonial architecture and strong Bengali traditions, exemplified in its literature, cuisine, and festivals.",
    //

    // fourthHeading: "Hand Baggage Rules",
    // fourthImage: poster,
    // imgDis:
    //   "If you are planning to take a domestic and international holiday anytime soon, we recommend you always travel light. Improper carriage or restricted items on a flight can put one through a lot of inconveniences.",
    // fourthDis: [
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    // ],
    // fourthparagraph:
    //   "We hope these tips were informative for you. But wait, there is one more thing we can do for you, and that is arrange visa services for all countries online for you. So simply contact us and let Akbar Travels customize your trip for you.",
  },
  {
    id: 3,
    poster: prewedding,
    heading: "Pre wedding shoot locations in India",
    writtenBy: "Outher Name",
    writtenDate: "June 1, 2023",
    discruption:
      "India offers a plethora of stunning locations that can provide the perfect backdrop for a pre-wedding photo shoot, each adding its own unique flavor and charm. Here are some of the best places across the country known for their scenic beauty and iconic landmarks.",
    firstHeading: "Taj Mahal, Agra",
    firstImage: tajmehalshoot,
    firstDis: [
      {
        li: "The Taj Mahal's exquisite architecture, with its white marble facade and intricate carvings, provides a stunning backdrop for romantic photographs.",
      },
      {
        li: "The symbol of eternal love, the Taj Mahal's history adds depth and significance to the photoshoot, making it memorable for couples.",
      },
      {
        li: "The serene surroundings of the Taj Mahal, including its lush gardens and reflecting pools, create a peaceful ambiance for romantic moments.",
      },
      {
        li: "The changing hues of the Taj Mahal at different times of the day offer a variety of lighting conditions, perfect for capturing diverse moods in photographs.",
      },
      {
        li: "The romantic legend of Shah Jahan and Mumtaz Mahal adds a touch of romance and mystique to the photoshoot experience at the Taj Mahal.",
      },
      {
        li: "The symmetry and balance of the Taj Mahal's design provide a harmonious setting for couples to express their love through photography.",
      },
      {
        li: "The grandeur of the Taj Mahal, as one of the Seven Wonders of the World, lends a sense of awe and magnificence to the photographs.",
      },
      {
        li: "The cultural significance of the Taj Mahal as a UNESCO World Heritage Site adds cultural richness and historical depth to the couple's photoshoot.",
      },
      {
        li: "The intricate details and craftsmanship of the Taj Mahal's architecture offer a wealth of beautiful elements to incorporate into romantic photographs.",
      },
      {
        li: "The romantic atmosphere of the Taj Mahal, with its whispers of love and devotion, inspires couples to express their affection openly in their photographs.",
      },
    ],
    firstparagraph:
      "Note :  The timeless beauty of the Taj Mahal, symbolizing love, is a favorite for couples looking to capture the essence of romance in their photographs.",
    secondHeading: "Rann of Kutch, Gujarat",
    secondImage: RannofKutch,
    secondDis: [
      {
        li: "The vast white salt desert provides a unique and mesmerizing backdrop for pre-wedding shoots, creating stunning and surreal imagery.",
      },
      {
        li: "During the Rann Utsav, the salt desert comes alive with cultural performances, colorful tents, and traditional handicrafts, adding vibrancy and excitement to the photoshoot experience.",
      },
      {
        li: "The expansive horizon of the salt desert offers ample space for creative compositions and dramatic silhouettes, enhancing the artistic appeal of pre-wedding photographs.",
      },
      {
        li: "The ethereal beauty of the salt desert at sunrise and sunset casts a magical glow over the landscape, resulting in breathtakingly romantic photographs.",
      },
      {
        li: "Couples can explore the vast expanse of the salt desert, hand in hand, creating intimate moments and memories that will last a lifetime.",
      },
      {
        li: "The serene and otherworldly atmosphere of the salt desert fosters a sense of adventure and spontaneity, encouraging couples to embrace the moment and let their love shine through.",
      },
      {
        li: "Photographers have the opportunity to experiment with light and shadow in the stark landscape of the salt desert, crafting captivating and dynamic compositions.",
      },
      {
        li: "The minimalistic beauty of the salt desert allows the focus to remain on the couple, their emotions, and their connection, resulting in authentic and heartfelt photographs.",
      },
      {
        li: "The Rann Utsav offers a plethora of cultural experiences, from camel rides to folk dances, providing endless opportunities for unique and memorable pre-wedding shots.",
      },
      {
        li: "The remote and isolated location of the salt desert creates a sense of exclusivity and intimacy, allowing couples to escape the hustle and bustle of everyday life and focus on each other.",
      },
    ],
    secondparagraph:
      "Note : The vast white salt desert becomes an exotic locale for pre-wedding shoots, especially during the Rann Utsav when the area is vibrant with culture and festivity.",
    thirdHeading: "Jaipur",
    thirdImage: hwamehal,
    thirdDis: [
      {
        li: "Jaipur, often called the 'Pink City,' boasts grand architecture that includes majestic palaces and forts, making it a dream destination for pre-wedding photography.",
      },
      {
        li: "The Amer Fort, a UNESCO World Heritage Site, stands as a symbol of Jaipur's rich history and architectural splendor, offering a picturesque setting with its intricate designs and stunning views.",
      },
      {
        li: "The City Palace, a blend of Rajput, Mughal, and European architectural styles, exudes grandeur and opulence, providing an elegant backdrop for pre-wedding shoots.",
      },
      {
        li: "The intricate carvings, delicate jharokhas (overhanging balconies), and sprawling courtyards of Jaipur's palaces and forts create a romantic ambiance ideal for capturing love stories.",
      },
      {
        li: "The vibrant colors and ornate details of Jaipur's architecture add depth and character to pre-wedding photographs, enhancing the visual appeal and storytelling.",
      },
      {
        li: "Jaipur's forts and palaces offer a mix of grandeur and intimacy, allowing couples to express their love amidst regal surroundings while also enjoying private moments in secluded corners.",
      },
      {
        li: "The play of light and shadow on Jaipur's architectural marvels, especially during sunrise and sunset, adds drama and romance to pre-wedding pictures, creating magical moments.",
      },
      {
        li: "Jaipur's palaces and forts provide a timeless backdrop that transcends trends, ensuring that pre-wedding photographs stand the test of time and remain cherished memories for years to come.",
      },
      {
        li: "Professional photographers in Jaipur are well-versed in capturing the essence of love against the backdrop of the city's grand architecture, ensuring stunning and evocative pre-wedding photographs.",
      },
      {
        li: "Couples can explore Jaipur's architectural wonders during pre-wedding shoots, creating a memorable experience filled with romance, adventure, and the timeless beauty of heritage.",
      },
    ],
    thirdparagraph:
      "Note : The grand architecture of Jaipur’s palaces and forts, especially the Amer Fort and the City Palace, offers a majestic backdrop for pre-wedding photography.",

    //
    fourthHeading: "Goa",
    fourthImage: goaphotoshoot,
    fourthDis: [
      {
        li: "Goa, known for its pristine beaches, vibrant culture, and Portuguese heritage, offers a picturesque backdrop for pre-wedding photography.",
      },
      {
        li: "The golden sands and azure waters of Goa's beaches provide a romantic setting for pre-wedding shoots, with stunning sunsets and endless horizons adding to the allure.",
      },
      {
        li: "Goa's colonial architecture, characterized by colorful Portuguese villas, churches, and forts, adds a touch of old-world charm and elegance to pre-wedding photographs.",
      },
      {
        li: "The laid-back vibe and tropical beauty of Goa create a relaxed atmosphere, allowing couples to express their love naturally and freely in front of the camera.",
      },
      {
        li: "Goa's lush green landscapes, including coconut groves, rice paddies, and spice plantations, offer a refreshing change of scenery for pre-wedding shoots, adding diversity to the photos.",
      },
      {
        li: "The quaint streets of Goa's heritage villages, such as Fontainhas in Panjim and Old Goa, provide a charming setting with colorful houses and winding lanes, perfect for capturing intimate moments.",
      },
      {
        li: "Couples can explore Goa's hidden gems, such as secluded beaches, secret coves, and hidden waterfalls, to create unique and adventurous pre-wedding photographs.",
      },
      {
        li: "The vibrant culture of Goa, with its music, dance, and festivals, adds energy and excitement to pre-wedding shoots, creating dynamic and lively photographs.",
      },
      {
        li: "Professional photographers in Goa are experienced in capturing the essence of love amidst the beauty of the coastal paradise, ensuring that pre-wedding pictures reflect the couple's unique story and connection.",
      },
      {
        li: "Goa's blend of natural beauty, cultural richness, and relaxed atmosphere makes it a dream destination for pre-wedding photography, offering endless possibilities for romantic and memorable shots.",
      },
    ],
    fourthparagraph:
      "Note : Known for its laid-back vibe, beautiful beaches, and Portuguese architecture, Goa provides a variety of picturesque settings from sunny shores to old churches.",
    fiveHeading: "Kerala Backwaters",
    fiveImage: Kerala_Backwaters_shoot,
    fiveDis: [
      {
        li: "Kerala's backwaters, a network of interconnected canals, rivers, and lagoons, offer a serene and enchanting backdrop for pre-wedding photography.",
      },
      {
        li: "The tranquil waters framed by lush greenery create a serene and romantic atmosphere, ideal for capturing intimate moments between couples.",
      },
      {
        li: "Traditional houseboats, known as 'kettuvallams,' drifting along the backwaters provide a unique and picturesque setting for pre-wedding shoots.",
      },
      {
        li: "The calm and reflective surface of the backwaters creates stunning mirror-like reflections, adding depth and beauty to pre-wedding photographs.",
      },
      {
        li: "Couples can explore the backwaters aboard a houseboat, cruising past picturesque villages, coconut groves, and emerald rice paddies, capturing the essence of Kerala's rural charm.",
      },
      {
        li: "The golden hues of sunrise and sunset over the backwaters create a magical ambiance, perfect for capturing romantic and ethereal photographs.",
      },
      {
        li: "The backwaters offer a sense of seclusion and tranquility, allowing couples to escape the hustle and bustle of everyday life and focus on each other amidst the natural beauty of Kerala.",
      },
      {
        li: "Professional photographers in Kerala are experienced in harnessing the unique charm of the backwaters, ensuring that pre-wedding photographs reflect the couple's love and connection in a timeless and captivating manner.",
      },
      {
        li: "Kerala's backwaters provide a blend of natural beauty, cultural heritage, and serenity, offering couples a romantic and memorable backdrop for their pre-wedding shoot.",
      },
      {
        li: "Whether it's a leisurely cruise aboard a houseboat or a stroll along the tranquil banks, Kerala's backwaters provide endless opportunities for creating beautiful and evocative pre-wedding photographs.",
      },
    ],
    fiveparagraph:
      "Note : The tranquil backwaters, especially around Alleppey and Kumarakom, offer a serene and beautiful setting with traditional houseboats and lush greenery.      ",
    sixHeading: "Udaipur",
    sixImage: udaipurPrewedding,
    sixDis: [
      {
        li: "Udaipur, often referred to as the 'City of Lakes' and the 'Venice of the East,' is renowned for its romantic ambiance and stunning architectural marvels, making it an ideal destination for pre-wedding photography.",
      },
      {
        li: "The majestic Lake Pichola, with its shimmering waters and picturesque surroundings, offers a breathtaking backdrop for pre-wedding shoots, especially during sunrise and sunset.",
      },
      {
        li: "Udaipur's iconic landmarks, including the City Palace, Jag Mandir, and Lake Palace, showcase the city's regal heritage and provide stunning settings for capturing timeless and romantic moments.",
      },
      {
        li: "The intricate architecture, ornate balconies, and elaborate courtyards of Udaipur's palaces and havelis create a fairy-tale setting, perfect for creating enchanting and romantic photographs.",
      },
      {
        li: "The narrow lanes and vibrant bazaars of Udaipur's old city offer a charming backdrop with colorful facades, bustling markets, and bustling streets, adding authenticity and character to pre-wedding pictures.",
      },
      {
        li: "Couples can enjoy a romantic boat ride on Lake Pichola, gliding past historic landmarks and scenic vistas, creating magical and memorable moments that are perfect for pre-wedding photography.",
      },
      {
        li: "Udaipur's lush gardens, such as Saheliyon ki Bari and Gulab Bagh, offer verdant settings with blooming flowers, tranquil water features, and architectural gems, providing a romantic and refreshing backdrop for pre-wedding shoots.",
      },
      {
        li: "The regal ambiance and hospitality of Udaipur's heritage hotels and luxury resorts provide a luxurious and romantic setting for pre-wedding photography, offering opulent interiors, panoramic views, and personalized experiences.",
      },
      {
        li: "Udaipur's rich cultural heritage, with its traditional music, dance, and festivals, adds vibrancy and authenticity to pre-wedding photographs, capturing the essence of Rajasthan's royal charm and hospitality.",
      },
      {
        li: "With its blend of natural beauty, architectural splendor, and romantic ambiance, Udaipur offers couples a magical and unforgettable backdrop for their pre-wedding shoot, ensuring that their love story is captured in all its glory.",
      },
    ],
    sixparagraph:
      "Note : The grand architecture of Jaipur’s palaces and forts, especially the Amer Fort and the City Palace, offers a majestic backdrop for pre-wedding photography.",
    savenHeading: "Lavasa",
    savenImage: Kochi,
    savenDis: [
      {
        li: "Lavasa, India's first planned hill city, is set amidst the lush greenery of the Western Ghats, providing a picturesque backdrop for pre-wedding photography.",
      },
      {
        li: "The scenic beauty of Lavasa's surroundings, including its serene lakes, rolling hills, and verdant landscapes, offers countless opportunities for capturing romantic and captivating photographs.",
      },
      {
        li: "Lavasa's modern architecture, inspired by Italian towns and European cities, adds a touch of sophistication and elegance to pre-wedding shoots, creating a unique and stylish ambiance.",
      },
      {
        li: "The waterfront promenade at Lavasa, with its vibrant cafes, charming boutiques, and stunning views of the lake, provides a chic and contemporary setting for pre-wedding photography.",
      },
      {
        li: "Couples can explore Lavasa's winding streets, adorned with colorful buildings, quaint bridges, and picturesque alleyways, offering a charming and romantic backdrop for their pre-wedding shoot.",
      },
      {
        li: "The pristine waters of Lavasa's lakes, such as Dasve Lake and Warasgaon Lake, offer tranquil settings for capturing intimate moments and reflections, creating serene and romantic photographs.",
      },
      {
        li: "Lavasa's lush green hills and meandering pathways provide a sense of seclusion and romance, allowing couples to escape into nature and create timeless and evocative photographs.",
      },
      {
        li: "The vibrant atmosphere of Lavasa, with its cultural events, live music performances, and outdoor activities, adds energy and excitement to pre-wedding shoots, creating dynamic and lively photographs.",
      },
      {
        li: "Lavasa's luxury resorts and boutique hotels offer luxurious and romantic settings for pre-wedding photography, with world-class amenities, scenic views, and personalized services.",
      },
      {
        li: "With its blend of natural beauty, modern infrastructure, and vibrant ambiance, Lavasa offers couples a captivating and memorable backdrop for their pre-wedding shoot, ensuring that their love story is captured in a stylish and contemporary manner.",
      },
    ],
    savenparagraph:
      "Note : This private, planned city near Pune is modeled on a typical Italian town, providing European-like vistas without leaving India.      ",

    eightHeading: "Humayun’s Tomb, Delhi",
    eightImage: humayutomb,
    eightDis: [
      {
        li: "Humayun's Tomb, located in the heart of Delhi, is a stunning example of Mughal architecture, known for its grandeur, symmetry, and intricate craftsmanship.",
      },
      {
        li: "The majestic mausoleum, surrounded by lush gardens, water channels, and pavilions, offers a serene and romantic ambiance, perfect for capturing timeless and elegant pre-wedding photographs.",
      },
      {
        li: "The geometric patterns, arched alcoves, and intricate carvings adorning Humayun's Tomb create a regal and timeless backdrop, adding depth and character to pre-wedding images.",
      },
      {
        li: "The sprawling Char Bagh (four-part garden) layout of Humayun's Tomb, inspired by Persian and Islamic garden design, provides a tranquil setting with symmetrical pathways and lush greenery, ideal for romantic strolls and candid captures.",
      },
      {
        li: "The play of light and shadow on the marble facades of Humayun's Tomb, especially during sunrise and sunset, adds drama and romance to pre-wedding photographs, creating enchanting and ethereal images.",
      },
      {
        li: "Couples can explore the various chambers, corridors, and terraces of Humayun's Tomb, creating diverse and captivating compositions that reflect the depth of their love amidst the grandeur of history.",
      },
      {
        li: "The historic significance of Humayun's Tomb, as the first garden-tomb on the Indian subcontinent, adds cultural richness and depth to pre-wedding photographs, creating a sense of connection to India's glorious past.",
      },
      {
        li: "Professional photographers in Delhi are skilled in capturing the essence of romance and elegance against the backdrop of Humayun's Tomb, ensuring that pre-wedding images are both breathtaking and timeless.",
      },
      {
        li: "Humayun's Tomb offers a blend of architectural beauty, natural serenity, and historical charm, making it a coveted destination for couples seeking sophisticated and memorable pre-wedding photographs.",
      },
      {
        li: "With its timeless allure and majestic presence, Humayun's Tomb provides couples with an unforgettable setting to celebrate their love and create cherished memories that will last a lifetime through stunning pre-wedding photography.",
      },
    ],
    eightparagraph:
      "Note :  This beautiful example of Mughal architecture, with its lush gardens and grandiose structure, serves as a historical and romantic backdrop.",
    nineHeading: "Dalhousie",
    nineImage: Chennai,
    nineDis: [
      {
        li: "Dalhousie, situated amidst the lush greenery of the Himalayas, offers a serene and romantic backdrop for pre-wedding photography, with its pristine landscapes and panoramic views.",
      },
      {
        li: "The colonial charm of Dalhousie, evident in its British-era buildings, churches, and cottages, adds a touch of old-world elegance and nostalgia to pre-wedding photographs, creating timeless and captivating images.",
      },
      {
        li: "The winding roads, pine-clad valleys, and snow-capped peaks surrounding Dalhousie provide stunning vistas and dramatic scenery, ideal for capturing the beauty and romance of the Himalayan landscape.",
      },
      {
        li: "The tranquil lakes, such as Khajjiar Lake and Chamera Lake, offer reflective surfaces and serene settings for pre-wedding photography, with their crystal-clear waters and lush surroundings adding to the charm.",
      },
      {
        li: "Couples can explore the quaint streets and bustling markets of Dalhousie, capturing candid moments and vibrant scenes that showcase the local culture and lifestyle, adding authenticity and character to their pre-wedding photographs.",
      },
      {
        li: "The lush green meadows and alpine forests surrounding Dalhousie provide a natural and picturesque setting for pre-wedding shoots, with blooming flowers, cascading waterfalls, and verdant valleys creating enchanting backdrops.",
      },
      {
        li: "The colonial-era churches, such as St. John's Church and St. Francis Church, offer historic and atmospheric settings for pre-wedding photography, with their Gothic architecture and tranquil surroundings evoking a sense of peace and serenity.",
      },
      {
        li: "Dalhousie's charming guesthouses, boutique hotels, and resorts offer cozy and intimate settings for pre-wedding photography, with their warm hospitality, scenic views, and personalized services ensuring a memorable experience for couples.",
      },
      {
        li: "Professional photographers in Dalhousie are experienced in capturing the essence of romance and natural beauty amidst the Himalayan landscapes, ensuring that pre-wedding photographs reflect the couple's love and connection in a breathtaking and authentic manner.",
      },
      {
        li: "With its tranquil ambiance, breathtaking scenery, and colonial charm, Dalhousie offers couples a romantic and idyllic setting for their pre-wedding shoot, creating cherished memories amidst the natural beauty of the Himalayas.",
      },
    ],
    nineparagraph:
      "Note :  Offering a glimpse of the colonial-era charm with its beautiful landscapes and old-world architecture, this hill station in Himachal Pradesh is ideal for couples seeking a serene environment.      ",
    tenHeading: "Jodhpur",
    tenImage: Kolkata,
    tenDis: [
      {
        li: "Jodhpur, often referred to as the 'Blue City' due to the indigo hues of its buildings, boasts a mesmerizing blend of vibrant colors and majestic architecture, making it a captivating location for pre-wedding photography.",
      },
      {
        li: "The imposing Mehrangarh Fort, perched atop a rocky hill overlooking the city, provides a grand and dramatic backdrop for pre-wedding shoots, with its towering walls, intricate carvings, and panoramic views.",
      },
      {
        li: "The narrow lanes and bustling bazaars of Jodhpur's old city offer a vibrant and authentic setting for pre-wedding photography, with their bustling markets, vibrant textiles, and ornate facades adding character and charm to the images.",
      },
      {
        li: "Jodhpur's iconic landmarks, such as Umaid Bhawan Palace, Jaswant Thada, and Ghanta Ghar (Clock Tower), showcase the city's regal heritage and architectural splendor, providing elegant and opulent settings for pre-wedding shoots.",
      },
      {
        li: "The enchanting blue houses of Jodhpur's old city create a unique and photogenic backdrop for pre-wedding photography, with their vibrant colors and intricate details adding depth and character to the images.",
      },
      {
        li: "The vibrant festivals and cultural events of Jodhpur, such as the Marwar Festival and Gangaur Festival, offer couples a colorful and lively backdrop for their pre-wedding shoots, with traditional music, dance, and festivities adding energy and excitement to the images.",
      },
      {
        li: "The regal ambiance and luxurious hospitality of Jodhpur's heritage hotels and palaces, such as Umaid Bhawan Palace and Ajit Bhawan, offer couples an elegant and opulent setting for their pre-wedding photography, with royal interiors, manicured gardens, and personalized services ensuring a memorable experience.",
      },
      {
        li: "The desert landscapes surrounding Jodhpur, including the Thar Desert and Osian Sand Dunes, provide a rustic and romantic setting for pre-wedding shoots, with their vast expanses, golden sands, and dramatic sunsets creating breathtaking and evocative images.",
      },
      {
        li: "Professional photographers in Jodhpur are skilled in capturing the essence of romance and regal splendor amidst the city's majestic architecture and vibrant culture, ensuring that pre-wedding photographs are both stunning and timeless.",
      },
      {
        li: "With its blend of vibrant colors, majestic architecture, and rich cultural heritage, Jodhpur offers couples a regal and romantic backdrop for their pre-wedding shoot, creating timeless and enchanting memories amidst the royal splendor of Rajasthan.",
      },
    ],
    tenparagraph:
      "Note : The blue houses and the striking Mehrangarh Fort in the background can give any pre-wedding shoot a touch of royal grandeur and dramatic flair.",
    //

    // fourthHeading: "Hand Baggage Rules",
    // fourthImage: poster,
    // imgDis:
    //   "If you are planning to take a domestic and international holiday anytime soon, we recommend you always travel light. Improper carriage or restricted items on a flight can put one through a lot of inconveniences.",
    // fourthDis: [
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    // ],
    // fourthparagraph:
    //   "We hope these tips were informative for you. But wait, there is one more thing we can do for you, and that is arrange visa services for all countries online for you. So simply contact us and let Akbar Travels customize your trip for you.",
  },
  {
    id: 4,
    poster: india,
    heading: "Premier Skydiving Hotspots Across the Globe",
    writtenBy: "Outher Name",
    writtenDate: "June 1, 2023",
    discruption:
      "Skydiving is a thrilling sport that combines adventure with stunning aerial views. Here are some of the most spectacular skydiving destinations around the world, each offering a unique perspective from the sky:",
    firstHeading: "Interlaken, Switzerland",
    firstImage: jaipur,
    firstDis: [
      {
        li: "Interlaken, Switzerland, is renowned for its thrilling skydiving experiences amidst breathtaking scenery.",
      },
      {
        li: "Skydiving in Interlaken offers panoramic views of the Swiss Alps, including iconic peaks like the Eiger, Mönch, and Jungfrau.",
      },
      {
        li: "The town's picturesque location, nestled between Lake Thun and Lake Brienz, provides a stunning backdrop for skydiving adventures.",
      },
      {
        li: "Interlaken is a popular destination for both first-time skydivers and experienced thrill-seekers due to its favorable weather conditions and scenic beauty.",
      },
      {
        li: "Visitors can choose from tandem skydiving, where they jump in tandem with an experienced instructor, or solo skydiving for certified skydivers.",
      },
      {
        li: "Skydiving centers in Interlaken adhere to strict safety standards and provide comprehensive training to ensure a safe and enjoyable experience for all participants.",
      },
      {
        li: "The adrenaline rush of freefalling from thousands of feet above the ground is complemented by the tranquility of floating under a parachute with panoramic views of the Swiss landscape.",
      },
      {
        li: "Skydiving in Interlaken is available year-round, with different seasons offering unique perspectives of the surrounding mountains, lakes, and valleys.",
      },
      {
        li: "Many skydiving packages in Interlaken include video and photo services, allowing participants to capture and relive their skydiving experience.",
      },
      {
        li: "Skydiving in Interlaken is not only an exhilarating adventure but also a once-in-a-lifetime opportunity to witness the beauty of the Swiss Alps from a unique perspective.",
      },
    ],
    firstparagraph:
      "Note : Known for its breathtaking scenery, skydiving in Interlaken gives you a panoramic view of the Swiss Alps, including peaks like the Eiger, Mönch, and Jungfrau.",
    secondHeading: "Dubai, UAE",
    secondImage: indiaGate,
    secondDis: [
      {
        li: "Dubai, United Arab Emirates, is one of the world's premier destinations for skydiving, offering unparalleled views of the city's iconic skyline and the Arabian Gulf.",
      },
      {
        li: "Skydiving in Dubai provides adrenaline junkies with the opportunity to experience the thrill of freefalling from incredible heights while taking in panoramic vistas of the city's landmarks.",
      },
      {
        li: "Popular skydiving locations in Dubai include the Palm Jumeirah, Dubai Marina, and the desert outskirts of the city.",
      },
      {
        li: "Tandem skydiving, where participants jump in tandem with a certified instructor, is the most common option for first-time skydivers in Dubai.",
      },
      {
        li: "Experienced skydivers can also opt for solo jumps or advanced skydiving courses to further hone their skills.",
      },
      {
        li: "Dubai's skydiving centers are equipped with state-of-the-art facilities and adhere to strict safety standards, ensuring a secure and memorable experience for all participants.",
      },
      {
        li: "The city's favorable weather conditions, with clear skies and minimal rainfall throughout the year, make Dubai an ideal destination for skydiving adventures.",
      },
      {
        li: "In addition to the adrenaline rush of freefalling, skydiving in Dubai offers stunning aerial views of the city's architectural marvels, including the Burj Khalifa, Burj Al Arab, and Palm Jumeirah.",
      },
      {
        li: "Many skydiving packages in Dubai include video and photo services, allowing participants to capture and share their exhilarating experience with friends and family.",
      },
      {
        li: "Skydiving in Dubai is not only a thrilling adventure but also a unique opportunity to see the city from a bird's eye perspective and create unforgettable memories.",
      },
    ],
    secondparagraph:
      "Note : Skydiving over Palm Jumeirah or the desert offers a unique urban landscape with a spectacular aerial view of the city’s iconic structures like the Burj Khalifa and the Palm Islands.",
    thirdHeading: "Fox Glacier, New Zealand*",
    thirdImage: tajmehal,
    thirdDis: [
      {
        li: "Fox Glacier, located on the West Coast of New Zealand's South Island, offers an extraordinary backdrop for skydiving enthusiasts.",
      },
      {
        li: "Skydiving near Fox Glacier provides breathtaking views of the glacier itself, as well as the surrounding Southern Alps and lush rainforests.",
      },
      {
        li: "Tandem skydiving is a popular option for visitors to Fox Glacier, allowing them to experience the thrill of freefalling while securely attached to an experienced instructor.",
      },
      {
        li: "Experienced skydivers can also enjoy solo jumps or advanced skydiving courses, taking advantage of the stunning scenery and favorable weather conditions.",
      },
      {
        li: "Skydiving operators near Fox Glacier prioritize safety and provide comprehensive training to ensure a safe and enjoyable experience for all participants.",
      },
      {
        li: "The remote and pristine location of Fox Glacier adds to the adventure, offering a sense of exhilaration as you leap from the aircraft and soar above the dramatic landscape.",
      },
      {
        li: "Participants can choose from various altitudes for their skydive, with higher altitudes providing longer freefall times and even more spectacular views.",
      },
      {
        li: "Skydiving near Fox Glacier is available year-round, with each season offering its own unique charm and perspective of the glacier and surrounding scenery.",
      },
      {
        li: "Many skydiving packages near Fox Glacier include video and photo services, allowing participants to capture their adrenaline-fueled moments and share them with friends and family.",
      },
      {
        li: "Skydiving near Fox Glacier is not only an adrenaline-pumping adventure but also a once-in-a-lifetime opportunity to experience the unparalleled beauty of New Zealand's natural landscapes from above.",
      },
    ],
    thirdparagraph:
      "Note : Offering one of the most scenic jumps, skydiving here allows views of the stunning snow-capped Southern Alps, lush rainforests, and the Tasman Sea.",

    //
    fourthHeading: "North Shore, Oahu, Hawaii",
    fourthImage: Mumbai,
    fourthDis: [
      {
        li: "The North Shore of Oahu, Hawaii, is renowned for its stunning beaches, legendary surf, and breathtaking scenery, making it an ideal location for skydiving adventures.",
      },
      {
        li: "Skydiving on the North Shore offers unparalleled views of the island's rugged coastline, lush mountains, and crystal-clear waters.",
      },
      {
        li: "Tandem skydiving is a popular choice for visitors to the North Shore, allowing them to experience the thrill of freefalling while securely attached to a certified instructor.",
      },
      {
        li: "Experienced skydivers can also opt for solo jumps or advanced skydiving courses, taking advantage of the island's stunning landscapes and favorable weather conditions.",
      },
      {
        li: "Skydiving operators on the North Shore prioritize safety and provide thorough training to ensure a safe and memorable experience for all participants.",
      },
      {
        li: "The adrenaline rush of leaping from an aircraft high above the North Shore is complemented by the serenity of floating under a parachute, offering breathtaking views of the island's natural beauty.",
      },
      {
        li: "Participants can choose from various altitudes for their skydive, with higher altitudes providing longer freefall times and even more spectacular views of the North Shore.",
      },
      {
        li: "Skydiving on the North Shore is available year-round, with each season offering its own unique charm and perspective of the island's coastline and landscapes.",
      },
      {
        li: "Many skydiving packages on the North Shore include video and photo services, allowing participants to capture their adrenaline-fueled moments and share them with friends and family.",
      },
      {
        li: "Skydiving on the North Shore of Oahu is not only an exhilarating adventure but also a once-in-a-lifetime opportunity to experience the beauty of Hawaii's landscapes from a thrilling perspective.",
      },
    ],
    fourthparagraph:
      "Note : Skydive over the beautiful Pacific Ocean and the lush Hawaiian countryside, offering one of the most picturesque skydives in the world.",
    fiveHeading: "Empuriabrava, Spain",
    fiveImage: Varanasi,
    fiveDis: [
      {
        li: "Empuriabrava, located on the Costa Brava in northeastern Spain, is known as the 'Skydiving Capital of Europe'.",
      },
      {
        li: "Skydiving in Empuriabrava offers breathtaking views of the Mediterranean Sea, the Empordà region, and the scenic Empuriabrava Canal network.",
      },
      {
        li: "Tandem skydiving is a popular choice for visitors to Empuriabrava, allowing them to experience the thrill of freefalling while securely attached to a certified instructor.",
      },
      {
        li: "Experienced skydivers can also opt for solo jumps or advanced skydiving courses, taking advantage of the region's favorable weather conditions and stunning landscapes.",
      },
      {
        li: "Empuriabrava boasts one of the largest and busiest skydiving centers in Europe, equipped with state-of-the-art facilities and aircraft.",
      },
      {
        li: "Skydiving operators in Empuriabrava prioritize safety and provide comprehensive training to ensure a safe and enjoyable experience for all participants.",
      },
      {
        li: "The adrenaline rush of leaping from an aircraft high above Empuriabrava is complemented by the tranquility of floating under a parachute, offering panoramic views of the coastline and countryside.",
      },
      {
        li: "Participants can choose from various altitudes for their skydive, with higher altitudes providing longer freefall times and even more spectacular views of the Empordà region.",
      },
      {
        li: "Skydiving in Empuriabrava is available year-round, with each season offering its own unique charm and perspective of the region's landscapes and coastline.",
      },
      {
        li: "Many skydiving packages in Empuriabrava include video and photo services, allowing participants to capture their adrenaline-fueled moments and share them with friends and family.",
      },
    ],
    fiveparagraph:
      "Note :  Known as the skydiving capital of Europe, it boasts views of the Pyrenees, the Mediterranean Sea, and beautiful coastal landscapes.",
    sixHeading: "Victoria Falls, Zambia/Zimbabwe",
    sixImage: Udaipur,
    sixDis: [
      {
        li: "Skydiving at Victoria Falls offers a truly unique perspective of one of the world's most magnificent natural wonders.",
      },
      {
        li: "Located on the border between Zambia and Zimbabwe, Victoria Falls is known for its awe-inspiring beauty and powerful cascades.",
      },
      {
        li: "Tandem skydiving is a popular choice for adventurers visiting Victoria Falls, providing an exhilarating experience with stunning aerial views of the falls.",
      },
      {
        li: "Experienced skydivers can also opt for solo jumps, taking advantage of the breathtaking scenery and favorable weather conditions.",
      },
      {
        li: "Skydiving operators at Victoria Falls prioritize safety and provide thorough training to ensure a secure and unforgettable experience for all participants.",
      },
      {
        li: "The adrenaline rush of freefalling from thousands of feet above the ground is enhanced by the majestic sight of Victoria Falls and the surrounding landscape.",
      },
      {
        li: "Participants can choose from various altitudes for their skydive, each offering a different perspective of the falls and the Zambezi River below.",
      },
      {
        li: "Skydiving at Victoria Falls is available year-round, with each season offering its own unique atmosphere and views of the falls.",
      },
      {
        li: "Many skydiving packages at Victoria Falls include video and photo services, allowing participants to capture their thrilling moments and share them with friends and family.",
      },
      {
        li: "Skydiving at Victoria Falls is not only an adrenaline-pumping adventure but also a once-in-a-lifetime opportunity to witness the natural beauty and grandeur of the falls from a bird's-eye perspective.",
      },
    ],
    sixparagraph:
      "Note : Skydiving here offers a unique view of one of the largest and most famous waterfalls in the world, along with the stunning surrounding landscape.",
    savenHeading: "Mount Everest, Nepal",
    savenImage: Kochi,
    savenDis: [
      {
        li: "Skydiving at Mount Everest offers an unparalleled opportunity to experience the world's highest peak from an exhilarating perspective.",
      },
      {
        li: "Located in the Himalayas of Nepal, Mount Everest is renowned for its awe-inspiring height and majestic beauty.",
      },
      {
        li: "Tandem skydiving is a popular choice for adventurers seeking to skydive near Mount Everest, providing an adrenaline-fueled experience with breathtaking views of the surrounding peaks.",
      },
      {
        li: "Experienced skydivers can also opt for solo jumps, taking advantage of the challenging terrain and stunning vistas.",
      },
      {
        li: "Skydiving operators near Mount Everest prioritize safety and provide thorough training to ensure a secure and unforgettable experience for all participants.",
      },
      {
        li: "The adrenaline rush of freefalling from high above the world's tallest mountain is complemented by the serene beauty of the Himalayan landscape.",
      },
      {
        li: "Participants can choose from various altitudes for their skydive, each offering a different perspective of Mount Everest and the surrounding Himalayan peaks.",
      },
      {
        li: "Skydiving near Mount Everest is available during certain times of the year when weather conditions are optimal, typically during the spring and autumn seasons.",
      },
      {
        li: "Many skydiving packages near Mount Everest include video and photo services, allowing participants to capture their thrilling moments and share them with friends and family.",
      },
      {
        li: "Skydiving near Mount Everest is not only an adrenaline-pumping adventure but also a once-in-a-lifetime opportunity to witness the grandeur of the world's highest peak from a unique vantage point.",
      },
    ],
    savenparagraph:
      "Note : Skydiving here offers a unique view of one of the largest and most famous waterfalls in the world, along with the stunning surrounding landscape.",

    eightHeading: "Cape Town, South Africa",
    eightImage: Amritsar,
    eightDis: [
      {
        li: "Cape Town, South Africa, is renowned for its stunning natural beauty and iconic landmarks, making it an ideal destination for skydiving adventures.",
      },
      {
        li: "Skydiving in Cape Town offers breathtaking views of the city's skyline, Table Mountain, and the surrounding coastline.",
      },
      {
        li: "Tandem skydiving is a popular choice for visitors to Cape Town, providing an adrenaline-pumping experience with panoramic vistas of the Atlantic Ocean and the Cape Peninsula.",
      },
      {
        li: "Experienced skydivers can also opt for solo jumps or advanced skydiving courses, taking advantage of the region's favorable weather conditions and diverse landscapes.",
      },
      {
        li: "Skydiving operators in Cape Town prioritize safety and provide comprehensive training to ensure a safe and memorable experience for all participants.",
      },
      {
        li: "The thrill of freefalling from thousands of feet above the ground is complemented by the stunning beauty of Cape Town's natural and urban landscapes.",
      },
      {
        li: "Participants can choose from various altitudes for their skydive, each offering a different perspective of Cape Town and its surroundings.",
      },
      {
        li: "Skydiving in Cape Town is available year-round, with each season offering its own unique atmosphere and views of the city and coastline.",
      },
      {
        li: "Many skydiving packages in Cape Town include video and photo services, allowing participants to capture their adrenaline-fueled moments and share them with friends and family.",
      },
      {
        li: "Skydiving in Cape Town is not only an exhilarating adventure but also a once-in-a-lifetime opportunity to experience the beauty of South Africa's landscapes from a thrilling perspective.",
      },
    ],
    eightparagraph:
      "Note : Jump with views of Table Mountain and the Cape Peninsula. This location combines both ocean and mountain scenery.",
    nineHeading: "Sydney, Australia",
    nineImage: Chennai,
    nineDis: [
      {
        li: "Sydney, Australia, offers a stunning backdrop for skydiving adventures, with its iconic landmarks and beautiful coastline.",
      },
      {
        li: "Skydiving in Sydney provides breathtaking views of the city's skyline, Sydney Harbour, and famous attractions like the Sydney Opera House and Sydney Harbour Bridge.",
      },
      {
        li: "Tandem skydiving is a popular choice for visitors to Sydney, allowing them to experience the thrill of freefalling while securely attached to an experienced instructor.",
      },
      {
        li: "Experienced skydivers can also opt for solo jumps or advanced skydiving courses, taking advantage of the region's favorable weather conditions and diverse landscapes.",
      },
      {
        li: "Skydiving operators in Sydney prioritize safety and provide comprehensive training to ensure a safe and enjoyable experience for all participants.",
      },
      {
        li: "The adrenaline rush of leaping from an aircraft high above Sydney is complemented by the stunning beauty of the city's coastal scenery and natural landmarks.",
      },
      {
        li: "Participants can choose from various altitudes for their skydive, each offering a different perspective of Sydney and its surroundings.",
      },
      {
        li: "Skydiving in Sydney is available year-round, with each season offering its own unique charm and views of the city and coastline.",
      },
      {
        li: "Many skydiving packages in Sydney include video and photo services, allowing participants to capture their thrilling moments and share them with friends and family.",
      },
      {
        li: "Skydiving in Sydney is not only an exhilarating adventure but also a once-in-a-lifetime opportunity to experience the beauty of Australia's landscapes from a thrilling perspective.",
      },
    ],
    nineparagraph:
      "Note : Offers a unique urban skydiving experience with views of Sydney Harbour, the city skyline, and the beaches along the coast.",
    tenHeading: "Pattaya, Thailand",
    tenImage: Kolkata,
    tenDis: [
      {
        li: "Pattaya, Thailand, offers an exciting destination for skydiving enthusiasts, with its stunning beaches and vibrant atmosphere.",
      },
      {
        li: "Skydiving in Pattaya provides breathtaking views of the city's coastline, turquoise waters, and picturesque islands dotting the Gulf of Thailand.",
      },
      {
        li: "Tandem skydiving is a popular choice for visitors to Pattaya, allowing them to experience the thrill of freefalling while securely attached to an experienced instructor.",
      },
      {
        li: "Experienced skydivers can also opt for solo jumps or advanced skydiving courses, taking advantage of the region's favorable weather conditions and scenic landscapes.",
      },
      {
        li: "Skydiving operators in Pattaya prioritize safety and provide comprehensive training to ensure a safe and enjoyable experience for all participants.",
      },
      {
        li: "The adrenaline rush of leaping from an aircraft high above Pattaya is complemented by the stunning beauty of the city's coastal scenery and tropical islands.",
      },
      {
        li: "Participants can choose from various altitudes for their skydive, each offering a different perspective of Pattaya and its surrounding areas.",
      },
      {
        li: "Skydiving in Pattaya is available year-round, with each season offering its own unique charm and views of the city and coastline.",
      },
      {
        li: "Many skydiving packages in Pattaya include video and photo services, allowing participants to capture their thrilling moments and share them with friends and family.",
      },
      {
        li: "Skydiving in Pattaya is not only an exhilarating adventure but also a memorable way to experience the beauty of Thailand's coastal landscapes from a thrilling perspective.",
      },
    ],
    tenparagraph:
      "Note : Known for its tropical landscapes, skydiving in Pattaya offers views of the Gulf of Thailand and the lush countryside surrounding the city.",
    //

    // fourthHeading: "Hand Baggage Rules",
    // fourthImage: poster,
    // imgDis:
    //   "If you are planning to take a domestic and international holiday anytime soon, we recommend you always travel light. Improper carriage or restricted items on a flight can put one through a lot of inconveniences.",
    // fourthDis: [
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    // ],
    // fourthparagraph:
    //   "We hope these tips were informative for you. But wait, there is one more thing we can do for you, and that is arrange visa services for all countries online for you. So simply contact us and let Akbar Travels customize your trip for you.",
  },
  {
    id: 5,
    poster: india,
    heading: "10 most expensive hotels in the world",
    writtenBy: "Outher Name",
    writtenDate: "June 1, 2023",
    discruption:
      "The most expensive hotels in the world are renowned for their luxurious accommodations, exceptional service, and often unique locations. Prices can vary depending on the suite or package, but here's a list highlighting ten of the most lavish hotels where nightly rates reach astronomical figures:",
    firstHeading: "Lover’s Deep Luxury Submarine, St. Lucia",
    firstImage: submarineHotal,
    firstDis: [
      {
        li: "Experience the unparalleled luxury of staying in a submarine, submerged beneath the crystal-clear waters of St. Lucia, offering an exclusive underwater retreat.",
      },
      {
        li: "Immerse yourself in the romance of the ocean with a stay in Lover’s Deep, where every moment is set against the stunning backdrop of the Caribbean Sea.",
      },
      {
        li: "Enjoy the ultimate in privacy and seclusion as you escape to the depths of the ocean, surrounded by serene underwater landscapes.",
      },
      {
        li: "Indulge in lavish accommodations, complete with opulent furnishings and modern amenities, ensuring a truly luxurious stay beneath the waves.",
      },
      {
        li: "Delight in personalized service from a dedicated team, catering to your every need and creating a bespoke experience tailored to your preferences.",
      },
      {
        li: "Savor exquisite culinary delights prepared by world-class chefs, dining on gourmet cuisine amidst the enchanting ambiance of the underwater world.",
      },
      {
        li: "Marvel at panoramic views through the submarine's large windows, offering glimpses of vibrant marine life and mesmerizing seascapes.",
      },
      {
        li: "Embark on thrilling underwater adventures, from snorkeling and scuba diving to submarine excursions, exploring the captivating marine ecosystem.",
      },
      {
        li: "Create unforgettable memories with customizable itineraries, including romantic dinners, underwater photography sessions, and private excursions tailored to your desires.",
      },
      {
        li: "Leave with cherished memories of a once-in-a-lifetime experience, as your stay at Lover’s Deep Luxury Submarine in St. Lucia leaves an indelible mark on your heart and mind.",
      },
    ],
    firstparagraph:
      "Note : Offering an underwater hotel experience in a submarine, this is possibly the ultimate in exclusive and unique stays, with prices reported around $150,000 per night.",
    secondHeading: "Hotel President Wilson, Geneva",
    secondImage: indiaGate,
    secondDis: [
      {
        heading: "Lake Geneva Views",
        li: "Enjoy breathtaking vistas of Lake Geneva, providing a picturesque backdrop for your stay at Hotel President Wilson.",
      },
      {
        heading: "Presidential Suite",
        li: "Experience unparalleled luxury in the opulent Presidential Suite, offering lavish accommodations and exclusive amenities..",
      },
      {
        heading: "Michelin-Starred Dining",
        li: "Delight your palate with exquisite cuisine at the hotel's Michelin-starred restaurant, showcasing culinary mastery and innovation.",
      },
      {
        heading: "Luxury Spa",
        li: "Relax and rejuvenate at the hotel's luxury spa, indulging in a range of pampering treatments and therapies.",
      },
      {
        heading: "Business Facilities",
        li: "Stay productive with state-of-the-art business facilities, including meeting rooms and conference spaces, catering to the needs of corporate guests.",
      },
      {
        heading: "Concierge Service",
        li: "Experience personalized service and assistance from the dedicated concierge team, ensuring a seamless and memorable stay.",
      },
      {
        heading: "Grand Ballroom",
        li: "Host elegant events and gatherings in the grand ballroom, featuring stunning architecture and sophisticated décor.",
      },
      {
        heading: "Fitness Center",
        li: "Stay active and energized at the hotel's fitness center, equipped with modern gym facilities for your workout routine.",
      },
      {
        heading: "Contemporary Design",
        li: "Immerse yourself in the hotel's contemporary design aesthetic, blending elegance with modern sophistication throughout its luxurious spaces.",
      },
      {
        heading: "Outdoor Terrace",
        li: "Unwind and socialize on the outdoor terrace, offering serene views and a tranquil atmosphere.",
      },
    ],
    secondparagraph:
      "Note : Known for its Royal Penthouse Suite, which covers the entire top floor of the hotel, rates for this luxury accommodation can go up to about $80,000 per night.",
    thirdHeading: "The Palms, Las Vegas",
    thirdImage: tajmehal,
    thirdDis: [
      {
        heading: "Luxury Accommodations",
        li: "Experience unparalleled luxury with lavish accommodations featuring modern amenities and stylish decor at The Palms in Las Vegas.",
      },
      {
        heading: "Iconic Las Vegas Strip Location",
        li: "Situated on the iconic Las Vegas Strip, The Palms offers convenient access to world-famous attractions, entertainment venues, and casinos.",
      },
      {
        heading: "World-Class Dining Options",
        li: "Indulge in a culinary journey with an array of world-class dining options, from fine dining restaurants to casual eateries, offering something for every palate.",
      },
      {
        heading: "High-End Shopping",
        li: "Shop till you drop at The Palms' high-end retail outlets, featuring luxury brands and designer boutiques for the ultimate shopping experience.",
      },
      {
        heading: "Casino and Entertainment",
        li: "Experience the excitement of gaming and entertainment at the casino, where you can try your luck at a variety of games and enjoy live performances.",
      },
      {
        heading: "Exclusive Nightlife Experiences",
        li: "Immerse yourself in the vibrant nightlife scene with exclusive experiences at The Palms' clubs and bars, featuring world-renowned DJs and performers.",
      },
      {
        heading: "Spa and Wellness Facilities",
        li: "Relax and rejuvenate at the spa and wellness facilities, offering a range of indulgent treatments, massages, and therapies for ultimate relaxation.",
      },
      {
        heading: "Pool Complex with Stunning Views",
        li: "Soak up the sun and enjoy breathtaking views from the pool complex, featuring multiple pools, cabanas, and lounging areas for a luxurious outdoor experience.",
      },
      {
        heading: "State-of-the-Art Fitness Center",
        li: "Stay active and energized at the state-of-the-art fitness center, equipped with modern equipment and amenities for a comprehensive workout session.",
      },
      {
        heading: "Contemporary Design and Architecture",
        li: "Immerse yourself in the contemporary design and architecture of The Palms, featuring sleek lines, stylish interiors, and innovative spaces.",
      },
    ],
    thirdparagraph:
      "Note : The Empathy Suite in this hotel is designed by artist Damien Hirst and costs around $100,000 per night, including a range of ultra-luxury amenities.      ",

    //
    fourthHeading: "The Mark Hotel, New York",
    fourthImage: Mumbai,
    fourthDis: [
      {
        heading: "Luxurious Accommodations",
        li: "Indulge in luxurious accommodations at The Mark Hotel, offering elegant rooms and suites designed for comfort and style in the heart of New York City.",
      },
      {
        heading: "Central Park Location",
        li: "Enjoy the prime location of The Mark Hotel, just steps away from Central Park, offering easy access to the iconic attractions and vibrant energy of Manhattan.",
      },
      {
        heading: "Fine Dining Experience",
        li: "Delight your palate with a fine dining experience at The Mark Restaurant by Jean-Georges, where culinary excellence meets contemporary elegance.",
      },
      {
        heading: "Exclusive Shopping Destinations",
        li: "Explore exclusive shopping destinations near The Mark Hotel, including world-renowned boutiques and designer stores along Madison Avenue and Fifth Avenue.",
      },
      {
        heading: "Exceptional Service",
        li: "Experience exceptional service and personalized attention from the dedicated staff at The Mark Hotel, ensuring a memorable and seamless stay for every guest.",
      },
      {
        heading: "Spa and Wellness Retreat",
        li: "Escape to a sanctuary of relaxation and rejuvenation at The Mark Spa, offering a range of luxurious treatments and therapies to nourish the body and soul.",
      },
      {
        heading: "Chic Bar and Lounge",
        li: "Unwind with signature cocktails and stylish ambiance at The Mark Bar & Lounge, a chic destination for socializing and enjoying the vibrant nightlife of New York City.",
      },
      {
        heading: "State-of-the-Art Fitness Center",
        li: "Maintain your fitness routine at the state-of-the-art fitness center, equipped with cutting-edge equipment and amenities for a comprehensive workout experience.",
      },
      {
        heading: "Elegant Design and Décor",
        li: "Immerse yourself in the sophisticated design and luxurious décor of The Mark Hotel, featuring contemporary elegance and timeless charm in every detail.",
      },
      {
        heading: "Personalized Concierge Assistance",
        li: "Receive personalized concierge assistance and insider recommendations from the knowledgeable concierge team at The Mark Hotel, ensuring an unforgettable stay tailored to your preferences.",
      },
    ],
    fourthparagraph:
      "Note : Its Grand Penthouse Suite, which is one of the largest hotel suites in the U.S., can command around $75,000 per night.",
    fiveHeading: "Laucala Island Resort, Fiji",
    fiveImage: Varanasi,
    fiveDis: [
      {
        heading: "Private Island Paradise",
        li: "Escape to your own private island paradise at Laucala Island Resort, surrounded by pristine beaches, lush tropical landscapes, and crystal-clear waters.",
      },
      {
        heading: "Luxurious Villas and Residences",
        li: "Indulge in luxurious accommodations in exquisite villas and residences, featuring traditional Fijian architecture, contemporary amenities, and stunning ocean views.",
      },
      {
        heading: "Gourmet Dining Experiences",
        li: "Savor gourmet dining experiences at Laucala's multiple restaurants and bars, offering a diverse range of cuisines prepared with fresh, locally sourced ingredients.",
      },
      {
        heading: "Exclusive Activities and Excursions",
        li: "Embark on exclusive activities and excursions, including snorkeling, diving, horseback riding, and cultural tours, allowing you to explore the natural beauty and rich culture of Fiji.",
      },
      {
        heading: "World-Class Spa and Wellness Retreat",
        li: "Relax and rejuvenate at the world-class spa and wellness retreat, where you can indulge in a variety of holistic treatments, massages, and wellness programs in a serene island setting.",
      },
      {
        heading: "Championship Golf Course",
        li: "Play a round of golf at the championship golf course, surrounded by breathtaking views of the Pacific Ocean and lush tropical landscapes, offering a challenging yet scenic experience for golf enthusiasts.",
      },
      {
        heading: "Private Airfield and Yacht Marina",
        li: "Arrive in style with access to the resort's private airfield and yacht marina, providing convenient transportation options for guests arriving by air or sea.",
      },
      {
        heading: "Eco-Friendly Practices",
        li: "Experience sustainability and conservation efforts firsthand, as Laucala Island Resort is committed to eco-friendly practices, preserving the natural environment and supporting local communities.",
      },
      {
        heading: "Exclusive Privacy and Seclusion",
        li: "Enjoy exclusive privacy and seclusion on Laucala Island, where you can unwind and disconnect from the outside world, surrounded by untouched beauty and tranquility.",
      },
      {
        heading: "Exceptional Service and Hospitality",
        li: "Experience exceptional service and hospitality from the dedicated staff at Laucala Island Resort, who go above and beyond to ensure a memorable and unforgettable stay for every guest.",
      },
    ],
    fiveparagraph:
      "Note : Located on a private island, the villas here can cost up to $60,000 per night, offering ultimate privacy and exclusivity.",
    sixHeading: "Four Seasons Hotel New York",
    sixImage: Udaipur,
    sixDis: [
      {
        heading: "Luxurious Accommodations",
        li: "Experience luxurious accommodations at the Four Seasons Hotel New York, featuring elegantly appointed rooms and suites with modern amenities and stunning city views.",
      },
      {
        heading: "Prime Manhattan Location",
        li: "Enjoy a prime location in the heart of Manhattan at the Four Seasons Hotel New York, offering easy access to iconic attractions, upscale shopping, and fine dining destinations.",
      },
      {
        heading: "Michelin-Starred Dining",
        li: "Indulge in Michelin-starred dining experiences at the hotel's renowned restaurants, where world-class chefs create exquisite cuisine using the finest ingredients.",
      },
      {
        heading: "Five-Star Spa and Wellness Facilities",
        li: "Relax and rejuvenate at the five-star spa and wellness facilities, offering a range of pampering treatments, massages, and holistic therapies in a serene and luxurious setting.",
      },
      {
        heading: "Exceptional Service and Hospitality",
        li: "Experience exceptional service and hospitality from the dedicated staff at the Four Seasons Hotel New York, renowned for their attention to detail and commitment to exceeding guest expectations.",
      },
      {
        heading: "State-of-the-Art Fitness Center",
        li: "Stay active and energized at the state-of-the-art fitness center, equipped with cutting-edge equipment and amenities for a comprehensive workout experience.",
      },
      {
        heading: "Elegant Event Spaces",
        li: "Host memorable events and gatherings in the hotel's elegant event spaces, featuring sophisticated design, customizable layouts, and impeccable service for weddings, conferences, and special occasions.",
      },
      {
        heading: "Rooftop Terrace with Panoramic Views",
        li: "Take in panoramic views of the Manhattan skyline from the rooftop terrace, an exclusive retreat where you can unwind and enjoy cocktails amidst the city's iconic landmarks.",
      },
      {
        heading: "Personalized Concierge Assistance",
        li: "Receive personalized concierge assistance from the knowledgeable staff, who can arrange restaurant reservations, theater tickets, and unique experiences to enhance your stay in New York City.",
      },
      {
        heading: "Contemporary Design and Elegance",
        li: "Immerse yourself in contemporary design and elegance throughout the Four Seasons Hotel New York, where stylish interiors, luxurious furnishings, and modern amenities create a refined atmosphere.",
      },
    ],
    sixparagraph:
      "Note : The Ty Warner Penthouse, perched high on the 52nd floor, goes for about $50,000 per night and offers panoramic views of Manhattan.",
    savenHeading: "Hotel Martinez, Cannes",
    savenImage: Kochi,
    savenDis: [
      {
        heading: "Art Deco Elegance",
        li: "Experience the timeless allure of Art Deco elegance at Hotel Martinez in Cannes, where stylish design and luxurious amenities blend seamlessly for a sophisticated stay.",
      },
      {
        heading: "Prime Croisette Location",
        li: "Enjoy a prime location on the iconic Croisette Boulevard at Hotel Martinez, offering stunning views of the Mediterranean Sea and convenient access to Cannes' famous attractions and events.",
      },
      {
        heading: "Luxurious Accommodations",
        li: "Indulge in luxurious accommodations at Hotel Martinez, featuring spacious rooms and suites adorned with plush furnishings, modern amenities, and private balconies overlooking the sea or city.",
      },
      {
        heading: "Gourmet Dining Experiences",
        li: "Savor gourmet dining experiences at the hotel's acclaimed restaurants, where world-class chefs showcase the finest Mediterranean cuisine paired with exceptional wines in elegant settings.",
      },
      {
        heading: "Private Beach and Pool",
        li: "Relax and unwind on the hotel's private beach or by the sparkling outdoor pool, where sun loungers, cabanas, and attentive service create the perfect ambiance for a day of leisure.",
      },
      {
        heading: "Wellness and Fitness Center",
        li: "Rejuvenate your body and mind at the wellness and fitness center, offering a range of spa treatments, massages, and state-of-the-art gym facilities to enhance your well-being during your stay.",
      },
      {
        heading: "Rooftop Terrace with Panoramic Views",
        li: "Take in panoramic views of Cannes and the Mediterranean Sea from the rooftop terrace, an exclusive retreat where you can enjoy cocktails, live music, and breathtaking sunsets.",
      },
      {
        heading: "Legendary Hospitality",
        li: "Experience legendary hospitality and impeccable service from the dedicated staff at Hotel Martinez, known for their attention to detail and commitment to exceeding guest expectations.",
      },
      {
        heading: "Elegant Event Spaces",
        li: "Host unforgettable events and celebrations in the hotel's elegant event spaces, featuring stunning décor, customizable layouts, and state-of-the-art technology for weddings, conferences, and special occasions.",
      },
      {
        heading: "Cannes Film Festival Hub",
        li: "Immerse yourself in the glamour and excitement of the Cannes Film Festival at Hotel Martinez, the preferred destination for celebrities, filmmakers, and industry insiders during the prestigious event.",
      },
    ],
    savenparagraph:
      "Note : The Penthouse Suite, on the French Riviera, commands rates of approximately $53,000 per night during the film festival.",

    eightHeading: "The Royal Mansour, Marrakech",
    eightImage: Amritsar,
    eightDis: [
      {
        heading: "Palatial Luxury",
        li: "Experience palatial luxury at The Royal Mansour in Marrakech, where opulent riads, lush gardens, and intricate Moroccan architecture create a regal retreat.",
      },
      {
        heading: "Exclusive Medina Location",
        li: "Discover an exclusive Medina location at The Royal Mansour, nestled within the ancient walls of Marrakech's historic city center, offering a serene oasis amidst the bustling streets.",
      },
      {
        heading: "Exquisite Riad Accommodations",
        li: "Indulge in exquisite riad accommodations, each meticulously designed with handcrafted furnishings, private courtyards, and plunge pools, offering unrivaled comfort and privacy.",
      },
      {
        heading: "Michelin-Starred Dining",
        li: "Savor Michelin-starred dining experiences at the hotel's prestigious restaurants, where world-renowned chefs showcase the finest Moroccan cuisine infused with international flavors.",
      },
      {
        heading: "Luxurious Spa and Wellness Retreat",
        li: "Rejuvenate your senses at the luxurious spa and wellness retreat, where traditional Moroccan hammams, indulgent treatments, and serene relaxation areas transport you to a state of blissful tranquility.",
      },
      {
        heading: "Timeless Moroccan Hospitality",
        li: "Experience timeless Moroccan hospitality at The Royal Mansour, where gracious service, attention to detail, and warm hospitality create an unforgettable stay for every guest.",
      },
      {
        heading: "Elegant Boutiques and Souks",
        li: "Explore elegant boutiques and traditional souks near The Royal Mansour, where you can discover artisanal crafts, exquisite textiles, and unique treasures to commemorate your Marrakech experience.",
      },
      {
        heading: "Rooftop Terrace with Panoramic Views",
        li: "Take in panoramic views of Marrakech from the rooftop terrace, an enchanting setting where you can enjoy refreshing cocktails, Moroccan teas, and breathtaking sunsets amidst the city's iconic landmarks.",
      },
      {
        heading: "Tailored Experiences and Excursions",
        li: "Embark on tailored experiences and excursions curated by the hotel's knowledgeable concierge team, including guided tours, desert adventures, and cultural immersions to discover the enchanting beauty of Marrakech and beyond.",
      },
      {
        heading: "Exquisite Event Spaces",
        li: "Host exquisite events and celebrations in the hotel's elegant event spaces, featuring sumptuous décor, personalized service, and unparalleled ambiance for weddings, banquets, and special occasions.",
      },
    ],
    eightparagraph:
      "Note : This hotel offers riads (traditional Moroccan houses) with prices for the grand riad reaching around $43,000 per night.",
    nineHeading: "The Raj Palace Hotel, Jaipur",
    nineImage: Chennai,
    nineDis: [
      {
        heading: "Heritage Grandeur",
        li: "Step into a world of heritage grandeur at The Raj Palace Hotel in Jaipur, a majestic palace showcasing opulent architecture, regal interiors, and exquisite craftsmanship.",
      },
      {
        heading: "Historic Location in Jaipur",
        li: "Discover a historic location in the heart of Jaipur at The Raj Palace Hotel, situated within the walls of the city's iconic Pink City, offering a glimpse into Rajasthan's rich cultural heritage.",
      },
      {
        heading: "Palatial Accommodations",
        li: "Experience palatial accommodations fit for royalty, with each room and suite at The Raj Palace Hotel adorned with luxurious furnishings, intricate details, and modern amenities.",
      },
      {
        heading: "Royal Dining Experiences",
        li: "Indulge in royal dining experiences at the hotel's renowned restaurants, where sumptuous Rajasthani cuisine, Indian specialties, and international flavors are served amidst regal settings.",
      },
      {
        heading: "Luxurious Spa and Wellness Sanctuary",
        li: "Relax and rejuvenate at the luxurious spa and wellness sanctuary, offering traditional Ayurvedic treatments, holistic therapies, and tranquil spaces to nourish the body, mind, and soul.",
      },
      {
        heading: "Impeccable Service and Hospitality",
        li: "Experience impeccable service and hospitality from the dedicated staff at The Raj Palace Hotel, where every guest is treated like royalty with personalized attention and gracious care.",
      },
      {
        heading: "Cultural Experiences and Entertainment",
        li: "Immerse yourself in cultural experiences and entertainment, including traditional music and dance performances, heritage walks, and storytelling sessions, showcasing the vibrant heritage of Rajasthan.",
      },
      {
        heading: "Magnificent Event Spaces",
        li: "Host magnificent events and celebrations in the hotel's exquisite event spaces, including majestic ballrooms, lush gardens, and historic courtyards, providing an unforgettable backdrop for weddings, galas, and social gatherings.",
      },
      {
        heading: "Heritage Tours and Excursions",
        li: "Embark on heritage tours and excursions curated by the hotel, including visits to Jaipur's iconic landmarks, historic palaces, and cultural attractions, offering insights into the rich history and heritage of the Pink City.",
      },
      {
        heading: "Royal Legacy and Tradition",
        li: "Experience the royal legacy and tradition of Rajasthan at The Raj Palace Hotel, where centuries-old customs, rituals, and hospitality continue to enchant guests and evoke the grandeur of a bygone era.",
      },
    ],
    nineparagraph:
      "Note : This historic hotel’s Presidential Suite, which was a former Maharaja’s residence, is priced at around $45,000 per night.",
    tenHeading: "Burj Al Arab, Dubai",
    tenImage: Kolkata,
    tenDis: [
      {
        heading: "Iconic Sail-Shaped Architecture",
        li: "Marvel at the iconic sail-shaped architecture of Burj Al Arab in Dubai, a symbol of modern luxury and architectural innovation set against the backdrop of the Arabian Gulf.",
      },
      {
        heading: "Exclusive Island Location",
        li: "Enjoy an exclusive island location on Jumeirah Beach at Burj Al Arab, offering unparalleled views of the Dubai skyline and direct access to pristine white sands and turquoise waters.",
      },
      {
        heading: "Ultimate Luxury Accommodations",
        li: "Experience ultimate luxury in the opulent accommodations of Burj Al Arab, featuring lavish suites adorned with gold accents, plush furnishings, and state-of-the-art amenities, including private butler service.",
      },
      {
        heading: "World-Class Dining Experiences",
        li: "Indulge in world-class dining experiences at Burj Al Arab's acclaimed restaurants, where Michelin-starred chefs create culinary masterpieces inspired by global flavors and innovative techniques.",
      },
      {
        heading: "Exclusive Leisure Facilities",
        li: "Relax and unwind at the exclusive leisure facilities of Burj Al Arab, including infinity pools, private beach cabanas, and the Talise Spa, offering a sanctuary of relaxation and rejuvenation.",
      },
      {
        heading: "Exceptional Service and Hospitality",
        li: "Experience exceptional service and hospitality from the dedicated team at Burj Al Arab, renowned for their attention to detail and commitment to exceeding guest expectations with personalized care and bespoke experiences.",
      },
      {
        heading: "Breathtaking Skyview Bar",
        li: "Sip cocktails and enjoy panoramic views from the breathtaking Skyview Bar, located on the hotel's 27th floor and offering an unmatched ambiance for memorable moments and special occasions.",
      },
      {
        heading: "Luxurious Shopping at The Avenue",
        li: "Indulge in luxurious shopping at The Avenue, Burj Al Arab's exclusive retail destination featuring high-end fashion boutiques, designer brands, and curated collections for discerning shoppers.",
      },
      {
        heading: "Magnificent Event Spaces",
        li: "Host magnificent events and celebrations in Burj Al Arab's luxurious event spaces, including elegant ballrooms, private dining rooms, and outdoor terraces, complemented by impeccable service and breathtaking views.",
      },
      {
        heading: "Signature Experiences and Helipad",
        li: "Embark on signature experiences and adventures, including helicopter tours from the hotel's iconic helipad, providing a unique perspective of Dubai's landmarks and skyline from above.",
      },
    ],
    tenparagraph:
      "Note : Known for its distinctive sail shape and luxurious service, the Royal Suite can cost approximately $24,000 per night.",
    //

    // fourthHeading: "Hand Baggage Rules",
    // fourthImage: poster,
    // imgDis:
    //   "If you are planning to take a domestic and international holiday anytime soon, we recommend you always travel light. Improper carriage or restricted items on a flight can put one through a lot of inconveniences.",
    // fourthDis: [
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    //   "The passenger is the guardian of all baggage including hand baggage and its content at all times.",
    // ],
    // fourthparagraph:
    //   "We hope these tips were informative for you. But wait, there is one more thing we can do for you, and that is arrange visa services for all countries online for you. So simply contact us and let Akbar Travels customize your trip for you.",
  },
];

export default travel_stories;
