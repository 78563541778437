import React from "react";
import { useNavigate } from "react-router-dom";
import Background from "components/background/Background";
import { HandLuggage } from "Utils/Images";
import { best10citys } from "Utils/Images";
import { couplePhotoshoot } from "Utils/Images";
import { skydiving } from "Utils/Images";
import { tajhotel } from "Utils/Images";
export default function TravelStories() {
  const navigate = useNavigate();

  const carouselItems = [
    // {
    //   id: 1,
    //   bg: HandLuggage,
    //   category: "Flight",
    //   heading: "Hand Luggage Restrictions – Things You Can’t Carry On A Plane",
    //   paragraph:
    //     "Recently, Indore’s famous ‘Jeerawan’, which contains red chillies and several other ingredients has been...",
    //   bottomTo: "-bottom-36",
    // },
    {
      id: 2,
      bg: best10citys,
      category: "Cities",
      heading: "Top 10 cities to visit in India ",
      paragraph:
        "India is a vast country with a rich tapestry of culture, history, and natural beauty. Each city offers a unique experience. Here are ten of the top cities to visit in India, each known for their distinct attractions:",
      bottomTo: "-bottom-52",
    },
    {
      id: 3,
      bg: couplePhotoshoot,
      category: "Experiences",
      heading: "Pre wedding shoot locations in India",
      paragraph:
        "India offers a plethora of stunning locations that can provide the perfect backdrop for a pre-wedding photo shoot, each adding its own unique flavor and charm. Here are some of the best places across the country known for their scenic beauty and iconic landmarks:",
      bottomTo: "-bottom-64",
    },
    {
      id: 4,
      bg: skydiving,
      category: "Flight",
      heading: "Premier Skydiving Hotspots Across the Globe",
      paragraph:
        "The world is filled with numerous magical places that are a treat to sore eyes. One thing better than...",
      bottomTo: "-bottom-36",
    },
    {
      id: 5,
      bg: tajhotel,
      category: "Hotels",
      heading: "10 most expensive hotels in the world",
      paragraph:
        "To have an unforgettable experience it’s important to find a hotel that treats you and your family like...",
      bottomTo: "-bottom-36",
    },
    {
      id: 2,
      bg: best10citys,
      category: "Cities",
      heading: "Top 10 cities to visit in India ",
      paragraph:
        "India is a vast country with a rich tapestry of culture, history, and natural beauty. Each city offers a unique experience. Here are ten of the top cities to visit in India, each known for their distinct attractions:",
      bottomTo: "-bottom-52",
    },
  ];

  return (
    <section className="m-auto mb-5 max-w-8xl px-5 text-gray-800 sm:mb-12 lg:px-0">
      <h2 className="text-2xl sm:text-4xl">
        Travel <span className="font-semibold">Stories</span>
      </h2>
      <div className="carousel mt-4 rounded-box">
        {/* Map through the carouselItems array and render each item */}
        {carouselItems.map((item, index) => (
          <div key={index} className="carousel-item relative overflow-hidden">
            <Background bg={item.bg} height={"h-full"} />
            <div className=" flex flex-col justify-end gap-2 text-white">
              <p className="absolute top-5 left-5 right-5 inline max-w-fit rounded-full border px-4">
                {item.category}
              </p>
              <div
                className={`absolute ${item.bottomTo} left-5 right-5 cursor-pointer transition duration-300 ease-in-out hover:bottom-5 hover:-translate-y-5`}
              >
                <h2 className="text-lg">{item.heading}</h2>
                <p className="mt-8">{item.paragraph}</p>
                <button
                  type="button"
                  className="add-city-inner mt-5"
                  onClick={() =>
                    navigate(
                      `/travel-stories?${item.heading
                        .toLowerCase()
                        .replace(/\s+/g, "-")
                        .replace(/-$/, "")}`,
                      {
                        state: item.id,
                      }
                    )
                  }
                >
                  Read More
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
