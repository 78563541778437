import React, { useEffect, useState } from "react";
import Select from "react-tailwindcss-select";
import { MaterialReactTable } from "material-react-table";
import InputField from "components/fields/InputField";
import userResources from "api/userResources";
import { china } from "Utils/Images";
import Dropdown from "components/dropdown";

export default function VisaRank() {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [data, setData] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [formData, setFormData] = useState({
    country_id: "",
    visa_rank: "",
    image: null,
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Loading state

  const columns = [
    {
      accessorFn: (row) => row.country.name,
      id: "country.name",
      header: "Country",
    },
    { accessorKey: "visa_rank", header: "Visa Rank" },
    {
      accessorKey: "image",
      header: "Visa Image",
      Cell: ({ row }) => (
        <Dropdown
          button={
            <img
              className="h-10 w-10 rounded-full"
              src={row.original.full_path}
              alt="Visa"
            />
          }
        />
      ),
    },
    {
      id: "actions",
      header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            onClick={() => handleEdit(row.original)}
            className="rounded bg-blue-500 px-2 py-1 text-white"
          >
            Edit
          </button>
          <button
            onClick={() => handleDelete(row.original.id)}
            className="rounded bg-red-500 px-2 py-1 text-white"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const getCountryList = async () => {
    try {
      const res = await userResources.countryList();
      const options = res.data.data.map((country) => ({
        value: country.id,
        label: country.name,
      }));
      setCountryOptions(options);
    } catch (error) {
      console.error("Error fetching country list:", error);
    }
  };

  const getVisaRankList = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const res = await userResources.visaRankList();
      setData(res.data.data);
    } catch (error) {
      console.error("Error fetching visa rank list:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    getCountryList();
    getVisaRankList();
  }, []);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setFormData((prevData) => ({
      ...prevData,
      country_id: selectedOption ? selectedOption.value : "",
    }));
    setErrors((prevErrors) => ({ ...prevErrors, country_id: null }));
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));

    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setErrors({});
    const newErrors = {};
    if (!formData.country_id) newErrors.country_id = ["Country is required."];
    if (!formData.visa_rank) newErrors.visa_rank = ["Visa rank is required."];
    if (!formData.image) newErrors.image = ["Visa image is required."];

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true); // Set loading to true before saving
    try {
      if (editingRow) {
        await userResources.addRank({ ...formData, id: editingRow.id });
      } else {
        await userResources.addRank(formData);
      }
      setEditingRow(null);
      setFormData({ country_id: "", visa_rank: "", image: null });
      await getVisaRankList(); // Call to fetch updated data
      setErrors({});
    } catch (error) {
      setErrors(error.response?.data?.errors || {});
      console.error("Error saving data:", error);
    } finally {
      setLoading(false); // Set loading to false after saving
    }
  };

  const handleEdit = (rowData) => {
    setEditingRow(rowData);
    setFormData({
      country_id: rowData.country.id,
      visa_rank: rowData.visa_rank,
      image: null,
    });
    setSelectedCountry({
      value: rowData.country.id,
      label: rowData.country.name,
    });
  };

  const handleDelete = async (id) => {
    setLoading(true); // Set loading to true before deleting
    try {
      await userResources.deleteVisaRank(id);
      await getVisaRankList(); // Call to fetch updated data
    } catch (error) {
      console.error("Error deleting entry:", error);
    } finally {
      setLoading(false); // Set loading to false after deleting
    }
  };

  const handleCancel = () => {
    setEditingRow(null);
    setFormData({ country_id: "", visa_rank: "", image: null });
    setSelectedCountry(null);
    setErrors({});
  };

  return (
    <section>
      <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2">
        <div className="rounded bg-white p-4 shadow dark:bg-transparent">
          <form onSubmit={handleSave}>
            <div className="mb-4">
              <label
                htmlFor="country_id"
                className="mb-2 block text-sm font-medium text-gray-700 dark:text-white"
              >
                Country
              </label>
              <Select
                id="country_id"
                value={selectedCountry}
                onChange={handleCountryChange}
                options={countryOptions}
                isSearchable={true}
                placeholder="Select country"
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex w-full cursor-pointer relative rounded-md border-gray-400 border text-sm dark:text-white overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                      isDisabled
                        ? "bg-gray-200"
                        : "bg-white dark:bg-transparent"
                    }`,
                  menu: "absolute top-10 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                      isSelected
                        ? "text-white bg-blue-500"
                        : "text-gray-500 hover:bg-blue-100 hover:text-blue-500"
                    }`,
                }}
                formatOptionLabel={(data) => (
                  <li
                    className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                      !data.isSelected
                        ? `bg-gray-200 text-gray-900`
                        : `bg-blue-800 text-gray-200`
                    }`}
                  >
                    {data.label}
                  </li>
                )}
              />
              {errors.country_id && (
                <p className="text-sm text-red-500">{errors.country_id[0]}</p>
              )}
            </div>

            <div className="mb-4 flex items-center justify-between">
              <div>
                <label
                  htmlFor="image"
                  className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                >
                  Visa Image
                </label>
                <input
                  type="file"
                  id="image"
                  onChange={handleInputChange}
                  name="image"
                  className={`mt-2 h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none ${
                    errors.image
                      ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                      : "border-gray-200 dark:!border-white/10 dark:text-white"
                  }`}
                />
                {errors.image && (
                  <p className="text-sm text-red-500">{errors.image[0]}</p>
                )}
              </div>
              <img src={china} alt="" className="float-right w-32" />
            </div>
            <div className="mb-4">
              <label
                htmlFor="visa_rank"
                className="mb-2 block text-sm font-medium text-gray-700 dark:text-white"
              >
                Visa Rank
              </label>
              <input
                type="text"
                name="visa_rank"
                id="visa_rank"
                value={formData.visa_rank}
                onChange={handleInputChange}
                className={`mt-2 h-12 w-full rounded-xl border bg-white/0 p-3 text-sm outline-none ${
                  errors.visa_rank
                    ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                    : "border-gray-200 dark:!border-white/10 dark:text-white"
                }`}
                placeholder="Visa Rank"
              />
              {errors.visa_rank && (
                <p className="text-sm text-red-500">{errors.visa_rank[0]}</p>
              )}
            </div>

            <button
              type="submit"
              className="mt-2 w-full rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
            >
              {editingRow ? "Update" : "Add"} {/* Show loading state */}
            </button>

            {editingRow && (
              <button
                type="button"
                onClick={handleCancel}
                className="mt-2 w-full rounded bg-gray-500 px-4 py-2 text-white hover:bg-gray-600"
              >
                Cancel
              </button>
            )}
          </form>
        </div>
        <div className="rounded bg-white p-4 shadow dark:bg-transparent">
          <h2 className="mb-4 text-lg font-semibold">Visa Rank List</h2>
          <MaterialReactTable
            columns={columns}
            data={data}
            state={{ isLoading: loading }} // Set loading state for MaterialReactTable
            enableColumnResizing={false}
            enableRowSelection={false}
            enablePagination={false}
            enableSorting={false}
            enableColumnFilterModes={false}
            enableExpanding={false}
            // enableGlobalFilter={false}
            initialState={{
              columnVisibility: {
                id: false,
              },
            }}
          />
        </div>
      </div>
    </section>
  );
}
