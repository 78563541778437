import axiosInstance from "./base";

const userResources = {
  // login: async (loginData) => {
  //   try {
  //     const response = await axiosInstance.post("/login", loginData);
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // },
  login: (data) => {
    return axiosInstance.post("/login", data);
  },
  google: () => {
    return axiosInstance.get("/login/google");
  },
  register: (data) => {
    return axiosInstance.post("/register", data);
  },
  logout: () => {
    return axiosInstance.get("/user/logout");
  },

  emailVerification: (email, token) => {
    return axiosInstance.get(`/user/send-verify-mail/${email}`, {
      headers: {
        Authorization: `Bearer ${token}`, // Attach the token for authentication
      },
    });
  },
  CreditCardAuthorizationList: (data) => {
    return axiosInstance.post(
      "/credit-Card-AuthorizationList/save-and-update",
      data
    );
  },
  updateUserProfile: (data, token) => {
    return axiosInstance.post("/user/update-user-details", data, {
      headers: {
        Authorization: `Bearer ${token}`, // Attach the token for authentication
      },
    });
  },

  updateUserPassword: (data, token) => {
    return axiosInstance.post("/user/update-password", data, {
      headers: {
        Authorization: `Bearer ${token}`, // Attach the token for authentication
      },
    });
  },

  contact: (data) => {
    return axiosInstance.post("/contact-us/save-and-update", data);
  },

  agent: (data) => {
    return axiosInstance.post("/job-for-agent/save-and-update", data);
  },
  manager: (data) => {
    return axiosInstance.post("/job-for-manager/save-and-update", data);
  },
  planeCharter: (data) => {
    return axiosInstance.post("/booking_enquiry/save-and-update", data);
  },
  yachtCharter: (data) => {
    return axiosInstance.post("/crise_enquiry/save-and-update", data);
  },
  updateProfile: (data) => {
    return axiosInstance.post("/user/update-user-details", data);
  },
  coupon: (data) => {
    return axiosInstance.post("/coupons/save-and-update", data);
  },
  deleteCoupon: (data) => {
    return axiosInstance.delete(`/coupons/delete-coupon/${data}`);
  },
  couponList: () => {
    return axiosInstance.get("/coupons/coupons-list");
  },
  subscribe: (data) => {
    return axiosInstance.post("/subscribe/save-and-update", data);
  },
  subscribeList: (pageIndex, pageSize, globalFilter) => {
    return axiosInstance.get(
      `/subscribe/subscribe-list?page=${pageIndex}&limit=${pageSize}&filter=${globalFilter}`
    );
  },

  dealsfor: (data) => {
    return axiosInstance.post("/deals/save-and-update", data);
  },
  getDealList: () => {
    return axiosInstance.get("/deals/deals-list");
  },
  countryList: () => {
    return axiosInstance.get("/country/list");
  },
  addRank: (data) => {
    return axiosInstance.post("/visa-rank/save-and-update", data);
  },
  visaRankList: () => {
    return axiosInstance.get("/visa-rank/list");
  },
};

export default userResources;
