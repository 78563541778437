import React, { useEffect, useRef, useState } from "react";
import avatar from "../../../assets/img/auth/admin_picture.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { useSelector, useDispatch } from "react-redux";
import { logout, selectUserInfo, setUserInfo } from "../../../redux/userSlice";
import { userDummy } from "Utils/Images";
import userResources from "api/userResources";
import { MdModeEdit } from "react-icons/md";
import { toast } from "react-toastify";
import { MdVerified } from "react-icons/md";
import { useNavigate } from "react-router-dom";
export default function Profile() {
  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const Navigate = useNavigate();
  const data = userInfo?.data || {};
  const [selectedImage, setSelectedImage] = useState(null);
  const [name, setName] = useState(data?.name || "");
  const [error, setError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [verifyVisibility, setVerifyVisibility] = useState(true);
  const [loadings, setLoadings] = useState(false);
  const cencleUpdation = () => {
    // Reset the state values
    setName(data?.name || "");
    setSelectedImage(null);

    // If you want to reset other fields or handle other logic, you can add them here.
    setPassword("");
    setPassword_confirmation("");
    setPasswordError("");
    setError("");
    setIsButtonDisabled(true);

    toast.success("Profile updateation cancelled.");
  };

  // Handle name change
  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);

    // Name validation
    if (value.length < 3) {
      setError("Name must be at least 3 characters long.");
      setIsButtonDisabled(true);
    } else {
      setError("");
      setIsButtonDisabled(false);
    }
  };

  // Handle password change
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    validatePasswords(e.target.value, password_confirmation);
  };

  // Handle confirm password change
  const handlepassword_confirmationChange = (e) => {
    setPassword_confirmation(e.target.value);
    validatePasswords(password, e.target.value);
  };

  // Validate passwords
  const validatePasswords = (newPassword, password_confirmation) => {
    setLoadings(true);
    const errors = [];

    // Check if the password is at least 4 characters long
    if (newPassword.length < 4) {
      errors.push("Password must be at least 4 characters long.");
    }

    // Check for at least one lowercase letter
    if (!/[a-z]/.test(newPassword)) {
      errors.push("Password must contain at least one lowercase letter.");
    }

    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(newPassword)) {
      errors.push("Password must contain at least one uppercase letter.");
    }

    // Check for at least one digit
    if (!/\d/.test(newPassword)) {
      errors.push("Password must contain at least one digit.");
    }

    // Check for at least one special character
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
      errors.push("Password must contain at least one special character.");
    }

    // Check if the passwords match
    if (newPassword !== password_confirmation) {
      errors.push("Passwords do not match.");
    }

    // If there are any errors, display them and disable the button
    if (errors.length > 0) {
      setPasswordError(errors.join(" "));
      setIsButtonDisabled(true);
    } else {
      setPasswordError("");
      setIsButtonDisabled(false);
    }
  };

  // Trigger file input click
  const openImageFunction = () => fileInputRef.current.click();

  // Handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setIsButtonDisabled(false);
    }
  };

  // Function to update the profile
  const updateProfile = async () => {
    setLoadings(true);
    // Prevent update if name validation fails
    if (name.length < 3) {
      setError("Name must be at least 3 characters long.");
      setIsButtonDisabled(true);
      return;
    }

    // Prevent update if passwords do not match
    if (password !== password_confirmation) {
      setPasswordError("Passwords do not match.");
      setIsButtonDisabled(true);
      return;
    }

    try {
      const token = userInfo?.data?.plain_text_token;
      if (!token) {
        throw new Error("No token found. Please log in again.");
      }

      // Track if profile data needs to be updated
      const updatedData = {};
      let needsProfileUpdate = false;

      // Check if name or image has changed
      if (name !== data?.name || selectedImage) {
        const formData = new FormData();
        formData.append("id", data?.id);
        formData.append("name", name);
        if (selectedImage) formData.append("image", selectedImage);

        // Update profile details
        const profileResponse = await userResources.updateUserProfile(
          formData,
          token
        );
        updatedData.data = {
          ...profileResponse.data.data,
          plain_text_token: token,
        };
        needsProfileUpdate = true;
      }

      // Update password if provided
      if (password) {
        const passwordData = { id: data?.id, password, password_confirmation };
        await userResources.updateUserPassword(passwordData, token);
        setLoadings(false);
      }

      // Only dispatch if the profile data was updated
      if (needsProfileUpdate) {
        dispatch(setUserInfo({ ...userInfo, ...updatedData }));
      }
      setLoadings(false);
      setIsButtonDisabled(true);

      toast.success("Profile updated successfully");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          toast.error("Unauthorized! Please log in again.");
          dispatch(logout());
          setLoadings(false);
        } else {
          const errorMessage =
            error.response.data.message ||
            "Something went wrong while updating the profile.";
          toast.error(errorMessage);
          setLoadings(false);
        }
      } else {
        toast.error("Something went wrong while updating the profile.");
        setLoadings(false);
      }
      console.error("Profile update error:", error);
    }
  };
  const verifyEmail = async () => {
    const token = data?.plain_text_token;
    const email = data?.email;

    if (!email || !token) {
      toast.error("Email or verification token is missing.");
      return;
    }

    setVerifyVisibility(false);

    try {
      // Assuming userResources.emailVerification is an asynchronous function
      const response = await userResources.emailVerification(email, token);
      setVerifyVisibility(false);

      if (response.data.success) {
        toast.success("Verification email has been sent to your inbox.");
        setTimeout(() => {
          setVerifyVisibility(true);
        }, 100000);

        // console.log("Verification response:", response);
      } else {
        toast.error("Failed to send verification email. Please try again.");
        // console.error("Verification failed:", response);
        setVerifyVisibility(true);
      }
    } catch (error) {
      toast.error("An error occurred during email verification.");
      // console.error("Error in verifyEmail:", error);
      setVerifyVisibility(true);
    }
  };

  useEffect(() => {
    if (!userInfo) {
      Navigate("/");
    }
  }, []);

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      {/* Background and profile */}
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute right-4 bottom-1 text-white">
          Account id: {data?.id}
        </div>
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img
            className="h-full w-full rounded-full"
            src={
              selectedImage
                ? URL.createObjectURL(selectedImage)
                : data?.full_path || userDummy
            }
            alt="Profile"
          />
          <div className="relative">
            <button
              onClick={openImageFunction}
              className="absolute -bottom-8 -right-1 flex h-5 w-5 items-center justify-center rounded-full bg-blue-500 sm:-bottom-10 sm:h-8 sm:w-8"
            >
              <MdModeEdit className="h-3 w-3 text-white sm:h-5 sm:w-5" />
            </button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
        </div>
      </div>

      {/* Name and email */}
      <div className="mt-16 flex flex-col items-center">
        <input
          type="text"
          className="bg-transparent text-center text-xl font-bold text-navy-700 outline-none dark:text-white"
          value={name}
          onChange={handleNameChange}
        />
        <div className="flex items-center justify-center">
          <div className="flex">
            <h4 className="text-md text-navy-700 dark:text-white">
              {data?.email}
            </h4>
            {data.is_verified === 1 ? (
              <div className="relative top-1 left-1">
                <MdVerified color="blue" />
              </div>
            ) : null}
          </div>

          {data.is_verified === 0 && verifyVisibility && (
            <h4
              className="text-md ml-2 cursor-pointer font-poppins  text-blue-600"
              onClick={verifyEmail}
            >
              Verify
            </h4>
          )}
        </div>

        {error && <p className="mt-2 text-red-500">{error}</p>}
      </div>

      {/* Post, followers, following */}
      <div className="mt-6 mb-3 flex gap-4 md:!gap-14">
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">17</p>
          <p className="text-sm font-normal text-gray-600">Posts</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            9.7K
          </p>
          <p className="text-sm font-normal text-gray-600">Followers</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            434
          </p>
          <p className="text-sm font-normal text-gray-600">Following</p>
        </div>
      </div>

      {/* Password Fields */}
      <div className="w-full p-3">
        <label
          htmlFor="password"
          className="mb-2 block text-sm font-semibold text-gray-800"
        >
          New Password
        </label>
        <input
          id="password"
          name="password"
          type="password"
          placeholder="Enter a new password"
          className="w-full rounded-md bg-white p-2 outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
          value={password}
          onChange={handlePasswordChange}
        />

        <label
          htmlFor="confirm-password"
          className="mb-2 mt-4 block text-sm font-semibold text-gray-800"
        >
          Confirm Password
        </label>
        <input
          id="confirm-password"
          name="confirm-password"
          type="password"
          placeholder="Confirm your new password"
          className="w-full rounded-md bg-white p-2 outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
          value={password_confirmation}
          onChange={handlepassword_confirmationChange}
        />
        {passwordError && <p className="mt-1 text-red-500">{passwordError}</p>}
      </div>

      {/* Save button */}
      <button
        className={`btn btn-primary w-full text-white ${
          isButtonDisabled ? "cursor-not-allowed  opacity-0" : ""
        }`}
        onClick={updateProfile}
        disabled={isButtonDisabled}
      >
        {loadings ? (
          <span className="loading loading-dots loading-xs text-white "></span>
        ) : (
          "Save Changes"
        )}
      </button>
      {/* Cancel button */}
      <button
        className={`btn mt-2 mb-24 w-full bg-red-500  text-white ${
          isButtonDisabled ? "cursor-not-allowed text-white opacity-0" : ""
        }`}
        onClick={cencleUpdation}
        disabled={isButtonDisabled}
      >
        Cancel
      </button>
    </Card>
  );
}
