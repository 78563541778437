import { faq } from "Utils/Images";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import UserNavbar from "components/UserNavbar/UserNavbar";
import React from "react";

const faqData = [
  {
    question:
      "I just booked my flights online and have not received my e ticket. When will I receive my e ticket receipt?",
    answer:
      "When you complete the booking online or with our call center staff, our Quality control team will verify the credit card billing details with the bank and this process takes time. You will get your e ticket receipt within the same day of making the booking during our regular business hours.",
  },
  {
    question:
      "When will I receive the refund back on my credit card after cancelling the flight tickets?",
    answer:
      "The refund process will take a maximum of 10 days from the Airline or from our supplier end. Once the refund is processed, it will take another one week to get the money back on our credit card. If you have not received the refund money, please call our office to find out the status of your refund.",
  },
  {
    question: "How many checked-in baggage can I carry for my trip?",
    answer:
      "Our e ticket receipt shows the number of checked in baggage. Please refer to the PDF version of the etickets. If you have questions before making the online booking, please call our call center during regular business hours.",
  },
  {
    question:
      "I cancelled my flight tickets and there is a charge of CAD 100 on my credit card from Maharaja Flights Limited. What is this charge for?",
    answer:
      "CAD $100 is the service fee which we charge for cancelling the airline tickets. You will get a refund directly from the airline on your credit card.",
  },
  {
    question:
      "I see the credit card charge credited back on my credit card for my tickets. Does it mean my tickets are not confirmed?",
    answer:
      "No, Your tickets are confirmed. Credit cards are charged directly by the airline in most cases and sometimes Airline system takes time to process the payment.",
  },
  {
    question:
      "My flights have more than 12 hrs of stop. Will I get hotel accommodation?",
    answer:
      "Free STPC (Station to pay cost or stopover) depends on the airline policy. Please call our office to find out about the specific ticket rules.",
  },
  {
    question: "Can My child travel alone on the flight?",
    answer:
      "Our online system does not allow you to book a child without an accompanying Adult. It depends on the age of the child and Airline policy. Please call our call center to find out about the specific airline rules for unaccompanied children.",
  },
  {
    question: "Do I need to reconfirm my flights before departure?",
    answer:
      "Yes, We recommend that you reconfirm your flights 24 hrs before departure. Our schedule change team always ensures that we notify you on time for any flight schedule changes but sometimes last minute changes come from airlines so we always recommend you should check with the airline directly for your flights.",
  },
  {
    question: "How do I do seat assignment for my flights?",
    answer:
      "You can do your seat selection 24 hrs before departure. If you want to purchase a preferred seat, you can login to the Airline web site and pay for the seat.",
  },
  {
    question: "How do I Print my boarding pass?",
    answer:
      "You can print your boarding pass 24 hrs before departure. Please note that you need your last name and ticket numbers or Airline reference handy while logging to the Airline site.",
  },
  {
    question: "Why Airline is not accepting the booking reference you sent me?",
    answer:
      "There are 2 types of booking reference. One is from our GDS system and the other one is Airline reference. Please make sure you are using the Airline reference number(P.N.R.) and not our GDS booking reference while logging to the Airline site.",
  },
  {
    question:
      "I forgot to add my Frequent Flyer number at the time of making the booking. Can I add it now?",
    answer:
      "You can add your frequent flyer number even after your tickets are issued. You can either deal with airline directly or call us and our staff will add it for you.",
  },
  {
    question: "I missed my flight. Will I get a refund of the unused ticket?",
    answer:
      "It depends on the fare rules. Please contact our staff to find out the no show and cancellation fee rules on your ticket.",
  },
  {
    question:
      "I got a schedule change on my flights and I don't want new dates and routing. Will I get a refund?",
    answer:
      "Yes, If you are not satisfied with the new dates and routing, We can process a full refund on your tickets without any service fee.",
  },
  {
    question:
      "I don't have a last name on my passport. How do I book my flights?",
    answer:
      "Our online flight booking engine does not allow you to make a booking without a last name. Please call our call center and our experienced staff will help you make the booking.",
  },
  {
    question:
      "I want to cancel my domestic flight. Will I get credit from the Airline?",
    answer:
      "Yes, you can get future credit from the airline. You must cancel the tickets before departure in order to be eligible for the credit.",
  },
  {
    question:
      "I want to cancel my flight. Can I cancel my flight just before departure?",
    answer:
      "Yes, you can cancel your flight before departure to get a refund with a cancellation penalty. Please note that the airlines charge a no show fee on top of cancellation charges. We recommend you should cancel your flights at least 24 hrs before departure.",
  },
  {
    question:
      "Can I upgrade my ticket to Premium Economy or Business class cabin?",
    answer:
      "Yes, you can upgrade your tickets. please call our call center to know the fare difference and Airline change penalty if any.",
  },
  {
    question:
      "My connecting flight time is very less. If I miss my flight, what will I do?",
    answer:
      "It is the airline's responsibility to arrange the next flight for you. We always recommend that you keep 2-3 hrs of connection time for International sectors due to heavy security checks at the airports now.",
  },
  {
    question:
      "My baggage is delayed by the airline. How will I get my baggage back?",
    answer:
      "Please contact the airline directly. Check the Baggage Information link to contact your airline.",
  },
  {
    question:
      "Why is my International credit card not accepted for paying for my Airline tickets?",
    answer:
      "We don't accept International credit cards due to chargeback reasons. If you want to purchase tickets, you can send us payment using e transfer.",
  },
  {
    question:
      "My credit card got declined. How do I pay for my flight tickets? Can I use my friend's credit card?",
    answer:
      "If your credit card is declined, you can pay using bank transfer or e-transfer. You can use your friend's credit card but we need authorization from the credit card holder to process the payment.",
  },
  {
    question: "Can I transfer my airline ticket to my family members?",
    answer:
      "No, All tickets are non changeable. Airline does not allow transferring the tickets to other names.",
  },
  {
    question:
      "I booked my online tickets with the wrong names on Maharaja Flights website. I swapped my last name and first name. What shall I do now?",
    answer:
      "Names have to be exactly as per your passport. If you booked on the same day, we can void your old tickets at no charge and issue new tickets with correct names. Please note that sometimes if seats are not available, you might have to pay the fare difference. If you notice the error after a few days, we will have to process the refund for the old tickets and issue new tickets with correct names.",
  },
  {
    question:
      "My flights have multiple airlines, which airline counter shall I go for check in?",
    answer:
      "You need to go to the operating airline counter. For example if your ticket is from Air Canada and your first flight is operated by Lufthansa airline, then you need to go to the Lufthansa airline counter.",
  },
  {
    question: "My passport is valid for 60 days. Can I travel?",
    answer:
      "Your passport must be valid for at least 6 months. Please check with the airline to find the exact rule for the passport validity.",
  },
  {
    question: "Can I travel with my expired passport?",
    answer:
      "You can only travel on domestic flights if your passport is expired. You need a government issued photo id to travel on domestic flights. You will not be allowed to travel on the International flights with an expired passport.",
  },
  {
    question:
      "I have a Canadian PR(Permanent Resident) card. Can I travel via the USA?",
    answer:
      "No, You cannot travel via the USA on a Canadian P.R. card. You need a U.S. visa in order to transit via the USA. Please read below important visa rules:",
  },
  {
    question:
      "I have an International flight coming back via the USA to Canada. Where will I do customs?",
    answer:
      "You will have to first do customs at a US airport and need to transfer baggage yourself, and your next custom will be at the Canadian port of entry.",
  },
  {
    question:
      "I lost my credit card. Can I get a refund on my new credit card?",
    answer:
      "No, Refunds can only be processed on the original form of payment by the airline. Please speak to your bank to resolve this. Money will be refunded back to the account number associated with your old credit card number.",
  },
  {
    question:
      "I do not want to use my return portion of the ticket. Will I get a partial refund?",
    answer:
      "Partial refunds depend on the airline policy. You might get some money on the taxes portion. Please call our office to discuss refund on partially used tickets.",
  },
  {
    question:
      "I have not received my purchase invoice yet. When will I receive my invoice?",
    answer:
      "We send invoices mostly on the following day. If you don't get an invoice by the next day, Please contact our call center. If you need an urgent invoice on the same day of purchase, please call our accounting department to get the invoice.",
  },
  {
    question: "Can I pay half on credit card and half by e-transfer?",
    answer:
      "Airlines do not accept partial payments for the tickets. We can do this but you need to pay an extra 3.5% credit card processing fee on the credit card amount.",
  },
  {
    question:
      "I have not purchased Travel insurance at the time of ticket purchase? Can I buy Travel insurance now?",
    answer:
      "We recommend that travel insurance must be purchased at the time of your ticket purchase but yes, you can buy travel insurance before your departure with few exceptions.",
  },
  {
    question:
      "I got a refund on my airline tickets but have not got credit for travel insurance. When will I get the Travel Insurance Credit?",
    answer:
      "You can call Travel insurance company directly for a refund of your travel insurance purchase. Please give your policy number to them.",
  },
  {
    question:
      "Is my Payment transaction safe if I book my airline tickets online on Maharaja Flights Limited website?",
    answer:
      "Our payment page is SSL (Secure Sockets Layer) encrypted and uses the same level of encryption which most of the major financial institutions use.",
  },
  {
    question:
      "Why my credit card statement is showing charge from a different Supplier for my airline tickets",
    answer:
      "In most of the cases, credit cards are processed by the airlines and you will see Airline charges. In some cases where the airline does not accept credit cards, you will either have Maharaja Flights Limited merchant charge or one of our supplier charges. Your total credit card charges will remain the same as quoted by our system.",
  },
  {
    question:
      "Why are different reservation change fees quoted by your agents at different times?",
    answer:
      "If you want to make changes to your booking, it depends on the fare difference which varies based on the Airline seat availability. Airline availability may change and prices will vary based on this factor.",
  },
  {
    question: "Can I book the flights today and pay later?",
    answer:
      "Yes, you can speak to our call center and hold the booking. Our experienced team will advise you about the airline time limit based on the airfare rules.",
  },
  {
    question:
      "Can I use multiple credit cards for payment of my family tickets?",
    answer:
      "Yes, you can speak to our call center to pay using multiple credit cards for your airline tickets. Our experienced staff will advise you about the exact split payment based on the ticket value of each pax.",
  },
  {
    question:
      "Why can't airlines change my booking which is booked with Maharaja Flights?",
    answer:
      "Airlines can help you change the reservation in some cases. We sometimes use special fares for our retail clients and airlines recommend calling us because of these special contractual fares.",
  },
  {
    question: "Does Baggage fee is included in my airline tickets pricing?",
    answer:
      "Baggage fee is included mostly for the International flights. For domestic and transborder flights, you need to pay baggage fees to Airlines separately. Please check with airlines or our call center for airline and destination specific baggage query.",
  },
  {
    question:
      "Will your agency pay us in case we get bumped off(Offloaded) the flight due to overbooking by the airline?",
    answer:
      "We do not compensate in case you are bumped off your flight due to overbooking by the airline. You need to contact the airline directly in this case.",
  },
  {
    question: "How do I request Wheelchair for my flight tickets?",
    answer:
      "You can request a wheelchair at the time of making an online booking. You can also call our call center later(ideally 24 hrs before flight) to request the wheelchair.",
  },
  {
    question:
      "I requested a Bassinet(Crib) for my infant? Will it be guaranteed on all my flights?",
    answer:
      "It depends on the airline and age of the infant. Bassinets(Cribs) are mostly confirmed by airlines on first come first serve and are generally confirmed for infants under 6 months of age.",
  },
];

function FAQ() {
  return (
    <section className="text-gray-800">
      <UserNavbar />
      <ScrollToTopOnRouteChange />

      <div className="m-auto max-w-8xl">
        <div className="px-4 py-8">
          <img src={faq} alt="Frequently Asked Questions" />
          <h1 className="mb-4 mt-8 text-2xl font-bold sm:text-3xl">
            Frequently Asked Questions
          </h1>
          {faqData.map((faq, index) => (
            <div key={index} className="my-4">
              <div
                tabIndex={0}
                className="collapse collapse-arrow  rounded-none border-b"
              >
                <div className="text-md collapse-title px-0 py-2  font-medium sm:text-xl">
                  {faq.question}
                </div>
                <div className="collapse-content px-0 ">{faq.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQ;
