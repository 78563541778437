import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    loggedIn: false,
    userInfo: {},
  },
  reducers: {
    login: (state, action) => {
      state.loggedIn = true;
      state.userInfo = action.payload; // Store both user info and token
    },
    setUserInfo: (state, action) => {
      // Ensure the token remains in the state when updating user info
      state.userInfo = {
        ...state.userInfo,
        ...action.payload, // Merge new user info while preserving existing data like the token
      };
    },
    logout: (state) => {
      state.userInfo = {};
      state.loggedIn = false;
    },
  },
});

export const { login, setUserInfo, logout } = userSlice.actions;
export const selectUserInfo = (state) => state.user.userInfo; // Selector
export default userSlice.reducer;
