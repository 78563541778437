import userResources from "api/userResources";
import Loader from "components/Loader/Loader";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function Deals() {
  const [dealList, setDealList] = useState([]);
  const [editingDeal, setEditingDeal] = useState(null);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (editingDeal) {
        data.id = editingDeal.id;
        const response = await userResources.dealsfor(data); // Ensure your API function matches
        if (response.status) {
          toast.success("Deal updated successfully");
        }
      } else {
        const response = await userResources.dealsfor(data); // Ensure your API function matches
        if (response.status) {
          toast.success("Deal created successfully");
        }
      }
      getDealList();
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
    resetForm();
  };

  const getDealList = async () => {
    try {
      setLoading(true);
      const response = await userResources.getDealList();
      setDealList(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (id) => {
    const deal = dealList.find((d) => d.id === id); // Find the deal by ID
    if (deal) {
      setEditingDeal(deal);
      setValue("name", deal.name);
    }
  };

  //   const handleDelete = async (id) => {
  //     try {
  //       setLoading(true);
  //       const response = await userResources.deleteDeal(id);
  //       if (response.status) {
  //         toast.success("Deal deleted successfully");
  //         getDealList();
  //       }
  //     } catch (error) {
  //       toast.error("Something went wrong!");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  const resetForm = () => {
    reset();
    setEditingDeal(null);
  };

  useEffect(() => {
    getDealList();
  }, []);

  return (
    <div className="mx-auto mt-4 flex max-w-8xl flex-col ">
      <div className="">
        <h2 className="mb-4 text-xl font-semibold">
          {editingDeal ? "Edit Deal" : "Create Deal"}
        </h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              {...register("name", {
                required: "Name is required",
              })}
              type="text"
              id="name"
              className={`mt-1 block w-full rounded-md border border-gray-300 bg-white p-3 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                errors.name ? "border-red-500" : ""
              }`}
              placeholder="Enter the deal name"
            />
            {errors.name && (
              <p className="mt-2 text-sm text-red-600">{errors.name.message}</p>
            )}
          </div>
          <button
            type="submit"
            className="flex w-full justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            {editingDeal ? "Update" : "Create"}
          </button>
          {editingDeal && (
            <button
              type="button"
              onClick={resetForm}
              className="mt-2 flex w-full justify-center rounded-md border border-transparent bg-gray-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              Cancel
            </button>
          )}
        </form>
      </div>
      <div className="mt-8 ">
        <div className="flex items-center justify-between">
          <h2 className="mb-4 text-xl font-semibold">Deals List</h2>
          <input
            type="text"
            placeholder="Search"
            className="focus:shadow-outline border-stroke text-black dark:border-form-strokedark dark:bg-form-input mb-4 rounded-lg border bg-transparent py-1 px-3 shadow outline-none focus:border-primary focus-visible:shadow-none dark:text-white dark:focus:border-primary"
          />
        </div>
        {loading ? (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        ) : (
          <ul className="hide-scroll-bar h-96 list-none overflow-scroll p-0">
            {dealList.map((deal) => (
              <li
                key={deal.id}
                className="mb-3 flex flex-col items-start justify-between rounded-lg bg-white p-2 shadow-sm lg:flex-row lg:items-center"
              >
                <div className="lg:w-3/4">
                  <p className="text-sm font-semibold">{deal.name}</p>
                </div>
                <div className="flex space-x-2 lg:ml-4 lg:mt-0">
                  <button
                    onClick={() => handleEdit(deal.id)}
                    className="flex-shrink-0 rounded-md border border-transparent bg-blue-600 py-1 px-3 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Edit
                  </button>
                  {/* <button
                    onClick={() => handleDelete(deal.id)}
                    className="flex-shrink-0 rounded-md border border-transparent bg-red-600 py-1 px-3 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Delete
                  </button> */}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
