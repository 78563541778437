import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import UserNavbar from "components/UserNavbar/UserNavbar";
import React from "react";

function BaggageInformation() {
  const airlines = [
    {
      name: "Aeroflot-Russian Airlines",
      href: "https://www.aeroflot.ru/ru-en/before_and_after_fly/luggage",
    },
    {
      name: "Aeromexico",
      href: "https://www.aeromexico.com/us/TravelInformation/BeforeYouPurchase/RegulationsAndPolicies/baggagePolicy.html",
    },
    {
      name: "Air Canada",
      href: "https://www.aircanada.com/ca/en/aco/home/plan/baggage.html",
    },
    {
      name: "Air China",
      href: "https://www.airchina.us/US/GB/info/checked-baggage/",
    },
    {
      name: "Air France",
      href: "https://wwws.airfrance.us/information/guide",
    },
    {
      name: "Air India",
      href: "https://www.airindia.com/in/en/travel-information/baggage-guidelines/checked-baggage-allowance.html",
    },
    {
      name: "Air New Zealand",
      href: "https://www.airnewzealand.com/optional-fees#checkedbaggage",
    },
    {
      name: "Air Transat",
      href: "https://www.airtransat.ca/en/Info/what-you-can-bring---weight-and-size.aspx?ExitID=25&ContentType=voyageur",
    },
    {
      name: "Alaska Airlines",
      href: "https://www.alaskaair.com/content/travel-info/baggage/checked-bags",
    },
    {
      name: "Alitalia",
      href: "https://www.ita-airways.com/en_in",
    },
    {
      name: "American Airlines",
      href: "https://www.aa.com/i18n/travel-info/baggage/checked-baggage-policy.jsp",
    },
    {
      name: "Asiana Airlines",
      href: "https://flyasiana.com/C/US/EN/contents/free-baggage",
    },
    {
      name: "Austrian Airlines",
      href: "https://www.austrian.com/in/en/homepage",
    },
    {
      name: "AviancaTaca",
      href: "https://www.avianca.com/en/information-and-help/baggage/",
    },
    {
      name: "Bangkok Airways",
      href: "https://www.bangkokair.com/travel-information/baggage",
    },

    {
      name: "Brussels Airlines",
      href: "https://www.brusselsairlines.com/be/en/extra-services/baggage",
    },
    {
      name: "Cape Air",
      href: "https://www.capeair.com/flying_with_us/baggage.html",
    },
    {
      name: "Caribbean Airlines",
      href: "https://www.caribbean-airlines.com/#/baggage/checked-baggage",
    },

    {
      name: "China Airlines",
      href: "https://www.china-airlines.com/us/en/search?q=baggage",
    },
    {
      name: "China Eastern",
      href: "https://www.ceair.com/",
    },
    {
      name: "China Southern Airlines",
      href: "https://www.csair.com/en/order/kpxlgd.shtml",
    },
    {
      name: "Czech Airlines",
      href: "https://www.csa.cz/en/baggage/",
    },
    { name: "Delta", href: "https://www.delta.com/us/en/need-help/overview" },
    {
      name: "Egyptair",
      href: "https://www.egyptairflight.com/baggageallowance.php",
    },

    {
      name: "Emirates",
      href: "https://www.emirates.com/au/english/before-you-fly/baggage/",
    },
    {
      name: "Ethiopian Airlines",
      href: "https://www.ethiopianairlines.com/in/information/baggage-information/carry-on-baggage",
    },
    {
      name: "Etihad Airways",
      href: "https://www.etihad.com/en/fly-etihad/baggage",
    },

    {
      name: "Finnair",
      href: "https://www.finnair.com/finnaircom/wps/portal/matkustusohjeet/en_INT",
    },
    { name: "Flybe", href: "https://www.flybe.com/flightInfo/baggage.htm" },
    {
      name: "Frontier Airlines",
      href: "https://www.flyfrontier.com/travel/travel-info/bag-options/",
    },

    {
      name: "Hainan Air",
      href: "https://www.hainanairlines.com/INT/GB/BASV",
    },
    {
      name: "Hawaiian Airlines",
      href: "https://hawaiianair.custhelp.com/app/answers/detail/a_id/80/~/baggage-fees-%26-policies",
    },
    {
      name: "Iberia",
      href: "https://www.iberia.com/luggage/allowance-in-hold/",
    },

    {
      name: "Japan Airlines",
      href: "https://www.jal.co.jp/jp/en/inter/baggage/",
    },
    {
      name: "JetBlue Airways",
      href: "https://www.jetblue.com/at-the-airport/baggage-information",
    },
    {
      name: "Jet Airways",
      href: "https://airlinesbaggage.com/jet-airways-baggage-allowance-fees/",
    },
    {
      name: "KLM",
      href: "https://www.klm.com/information/baggage/checked-baggage-allowance",
    },
    {
      name: "Korean Air",
      href: "https://www.koreanair.com/in/en/airport/baggage/overview",
    },
    {
      name: "LACSA",
      href: "https://www.seatguru.com/airlines/Taca_International_Airlines/baggage.php",
    },

    {
      name: "LAN Argentina",
      href: "https://www.latamairlines.com/ca/en/help-center/faq/baggage/hold/allow-flight",
    },
    {
      name: "LAN Ecuador",
      href: "https://www.latamairlines.com/ca/en/help-center/faq/baggage/hold/allow-flight",
    },
    {
      name: "LAN Peru",
      href: "https://www.latamairlines.com/ca/en/help-center/faq/baggage/hold/allow-flight",
    },
    {
      name: "Lufthansa",
      href: "https://www.lufthansa.com/fr/en/free-baggage-rules",
    },
    {
      name: "Luxair",
      href: "https://www.luxair.lu/en/information/baggage",
    },
    {
      name: "Malaysia Airlines",
      href: "https://www.malaysiaairlines.com/hq/en/travel-info/baggage.html",
    },

    {
      name: "Olympic",
      href: "https://www.olympicair.com/en/travel-information/travelling-with-olympic-air/baggage/",
    },
    {
      name: "Philippine Airlines",
      href: "https://www.philippineairlines.com/before-you-fly/baggage-information/baggage-allowance/baggage-allowance-fees",
    },
    {
      name: "Porter Airlines",
      href: "https://www.flyporter.com/en/travel-information/baggage/checked-allowance",
    },
    {
      name: "Qantas Airways",
      href: "https://www.qantas.com/in/en/travel-info/baggage/additional-baggage.html",
    },
    {
      name: "Qatar Airways",
      href: "https://www.qatarairways.com/en/baggage/allowance.html",
    },
    {
      name: "Royal Air Maroc",
      href: "https://www.royalairmaroc.com/us-en/checked-baggage",
    },
    {
      name: "Royal Jordanian",
      href: "https://www.rj.com/en/info-and-tips/baggage-information/checked-luggage",
    },

    {
      name: "Singapore Airlines",
      href: "https://www.singaporeair.com/en_UK/in/travel-info/baggage/checked-baggage/",
    },
    {
      name: "South African Airways",
      href: "https://www.flysaa.com/manage-fly/baggage/checked-baggage",
    },
    {
      name: "Sri Lankan",
      href: "https://www.srilankan.com/bgalw?gad_source=1&gclid=CjwKCAjw88yxBhBWEiwA7cm6pRnNIDCcY6RzDfqGBNv8U-3Z80u_unjIkBilMX0DV3IB7SrUf3lpWBoChToQAvD_BwE",
    },
    {
      name: "Swiss International Air Lines",
      href: "https://www.swiss.com/hu/en/prepare/baggage/checked-baggage",
    },
    {
      name: "TACA",
      href: "https://www.seatguru.com/airlines/Taca_International_Airlines/baggage.php",
    },

    {
      name: "Tarom-Romanian Air Transport",
      href: "https://www.tarom.ro/en/passenger-information/baggage/",
    },
    {
      name: "Thai Air International",
      href: "https://www.thaiairways.com/en/travel_information/baggage.page",
    },
    {
      name: "Turkish Airlines",
      href: "https://www.turkishairlines.com/en-tr/any-questions/free-baggage/?gad_source=1&gclid=CjwKCAjw88yxBhBWEiwA7cm6pfT-sQBQj2njk7ieibpa8ni0k4J6fFvNqZ5h_XC_ghA0s7xT-le6LBoCSckQAvD_BwE",
    },

    {
      name: "US Airways",
      href: "https://www.aa.com/i18n/travel-info/baggage/checked-baggage-policy.jsp",
    },
    {
      name: "Virgin America",
      href: "https://help.virginatlantic.com/gb/en/baggage/check-in-baggage-allowance.html",
    },
    {
      name: "Virgin Atlantic",
      href: "https://help.virginatlantic.com/gb/en/baggage/check-in-baggage-allowance.html",
    },

    {
      name: "WestJet",
      href: "https://www.westjet.com/en-ca/baggage/allowance",
    },
  ];

  const rowCount = 3;
  const rows = [...Array(rowCount)].map((_, index) =>
    airlines.slice(
      index * Math.ceil(airlines.length / rowCount),
      (index + 1) * Math.ceil(airlines.length / rowCount)
    )
  );

  return (
    <section className="text-gray-800">
      <UserNavbar />
      <ScrollToTopOnRouteChange />

      <div className=" m-auto max-w-8xl">
        <div className="bg-white p-8">
          <h2 className="mb-4 text-2xl font-bold">Baggage Information</h2>
          <p className="mb-4">
            Explore comprehensive details regarding baggage fees for each
            airline by choosing a link from the options provided below:
          </p>
          <div className="flex flex-wrap">
            {rows.map((row, index) => (
              <ul
                key={index}
                className="w-full list-none p-0 sm:w-1/2 md:w-1/3"
              >
                {row.map((airline, i) => (
                  <li key={i} className="mb-2">
                    <a
                      href={airline.href}
                      className="text-blue-600 hover:underline"
                      target="_blank"
                    >
                      {airline.name}
                    </a>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default BaggageInformation;
