import MultiCityForPhone from "components/MultiCityForPhone/MultiCityForPhone";
import OneWayForPhone from "components/OneWayForPhone/OneWayForPhone";
import RoundTripFormForPhone from "components/RoundTripFormForPhone/RoundTripFormForPhone";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import UserNavbar from "components/UserNavbar/UserNavbar";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

export default function OfferDetails() {
  const location = useLocation();
  const [round, setRound] = useState(true);
  const [oneWay, setOneWay] = useState(false);
  const [multiCity, setMultiCity] = useState(false);
  const data = location?.state?.data;

  const handleCopyCoupon = () => {
    navigator.clipboard.writeText(data?.coupon);
    alert("Coupon copied to clipboard!");
  };

  return (
    <section className="text-gray-800">
      <div>
        <ScrollToTopOnRouteChange />
        <UserNavbar />
      </div>
      <div className="mx-auto my-5 max-w-8xl sm:my-10 ">
        <div className="mb-10">
          <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
            <div className="md:col-span-2">
              <div className="mx-0 px-5 sm:mx-8 sm:px-0">
                <div
                  className="block h-60 w-full sm:hidden sm:h-96"
                  style={{
                    backgroundImage: `url(${data?.innerImage})`,
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                  }}
                ></div>
                <img
                  className="hidden h-96 w-full sm:block"
                  src={data?.innerImage}
                  alt=""
                />
              </div>
              <div className="mx-6 sm:mx-8">
                <div className="flex flex-wrap  items-center justify-between">
                  <h4 className="mt-3 rounded-sm text-lg font-semibold capitalize text-red-500 ">
                    {data?.heading}
                  </h4>
                  <div className="my-2 flex flex-wrap gap-2 text-sm">
                    <h4 className="whitespace-nowrap border px-2 shadow-md">
                      <span className="font-bold ">Coupon Code:</span>{" "}
                      {data?.coupon}
                    </h4>
                    <h4 className="border px-2 shadow-md">{data?.offer}</h4>
                  </div>
                </div>
                <p>{data?.longParagraph}</p>
                <p className="text-md mt-2 font-bold text-gray-900 sm:mt-5">
                  More details:{" "}
                </p>
                <p className="text-sm font-extralight ">
                  Booking Validity-Until {data?.booking_validaity}
                </p>
                <p className="text-md mt-2 font-bold text-gray-900 sm:mt-5">
                  Terms & Conditions:
                </p>
                <ul className="list-disc">
                  {data?.terms_conditions.map((item, index) => (
                    <li key={index} className="whitespace-nowrap text-sm">
                      {item}
                    </li>
                  ))}
                </ul>
                <button
                  className="button_bg float-right my-5 rounded-md py-2 px-4 text-white"
                  onClick={handleCopyCoupon}
                >
                  Copy coupon
                </button>
              </div>
            </div>
            <div className="rounded-sm border border-gray-600 md:col-span-1">
              <div className="p-3">
                <h2 className="text-2xl font-bold text-gray-900">
                  Book Flight Tickets
                </h2>
                <fieldset className="flex gap-2">
                  <div
                    className="flex items-center gap-2 rounded-full border bg-white px-3 py-0.5 text-sm"
                    onClick={() => {
                      setRound(true);
                      setMultiCity(false);
                      setOneWay(false);
                    }}
                  >
                    <div
                      className={`h-4 w-4 rounded-full border-2 border-gray-300 ${
                        round === true ? "bg-red-400" : ""
                      }`}
                    ></div>

                    <label
                      htmlFor="roundTrip"
                      className="cursor-pointer whitespace-nowrap"
                    >
                      Round Trip
                    </label>
                  </div>
                  <div
                    className="flex items-center gap-2 rounded-full border bg-white px-2 py-0.5 text-sm"
                    onClick={() => {
                      setRound(false);
                      setMultiCity(false);
                      setOneWay(true);
                    }}
                  >
                    <div
                      className={`h-4 w-4 rounded-full border-2 border-gray-300 ${
                        oneWay === true ? "bg-red-400" : ""
                      }`}
                    ></div>
                    <label
                      htmlFor="oneWay"
                      className="cursor-pointer whitespace-nowrap"
                    >
                      One Way
                    </label>
                  </div>

                  <div
                    className="flex items-center gap-2 rounded-full border bg-white px-3 py-0.5 text-sm"
                    onClick={() => {
                      setRound(false);
                      setMultiCity(true);
                      setOneWay(false);
                    }}
                  >
                    <div
                      className={`h-4 w-4 rounded-full border-2 border-gray-300 ${
                        multiCity === true ? "bg-red-400" : ""
                      }`}
                    ></div>

                    <label
                      htmlFor="multiCity"
                      className="cursor-pointer whitespace-nowrap"
                    >
                      Multi City
                    </label>
                  </div>
                </fieldset>
                <div className="mt-2">
                  {round === true ? (
                    <RoundTripFormForPhone />
                  ) : oneWay === true ? (
                    <OneWayForPhone /> // Render OneWayForPhone for one-way trip
                  ) : multiCity === true ? (
                    <MultiCityForPhone /> // Render MultiCityForPhone for multi-city
                  ) : (
                    <RoundTripFormForPhone /> // Default: Round trip form
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
