import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { motion, AnimatePresence } from "framer-motion"; // For animation

const PhoneNumberPopup = () => {
  const [isOpen, setIsOpen] = useState(false); // Toggle popup visibility
  const [phoneNumber, setPhoneNumber] = useState(""); // Store phone number
  const [otp, setOtp] = useState(new Array(4).fill("")); // Array to store OTP digits
  const [isOtpSent, setIsOtpSent] = useState(false); // Toggle OTP field visibility

  const togglePopup = () => {
    setIsOpen(false);
  };

  setTimeout(() => {
    togglePopup();
  }, 10000);

  // Function to handle phone number form submission
  const handlePhoneSubmit = (e) => {
    e.preventDefault();
    // Assuming phone number validation passed
    setIsOtpSent(true); // Show OTP fields after phone number submission
    alert(`OTP sent to Phone Number: ${phoneNumber}`);
  };

  // Function to handle OTP submission
  const handleOtpSubmit = (e) => {
    e.preventDefault();
    const otpValue = otp.join(""); // Join OTP digits into a single string
    alert(`OTP Entered: ${otpValue}`);
    // Handle OTP verification logic here
  };

  // Handle input change for OTP fields
  const handleOtpChange = (element, index) => {
    if (/^[0-9]$/.test(element.value)) {
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);
      // Move focus to next input field if a valid digit is entered
      if (element.nextSibling && element.value) {
        element.nextSibling.focus();
      }
    }
  };

  useEffect(() => {
    setIsOpen(true); // Show the popup when the component mounts
  }, []);

  return (
    <div>
      {/* Popup */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="phonePopup"
            initial={{ y: "100%" }} // Start from bottom
            animate={{ y: 0 }} // Animate to top
            exit={{ y: "100%" }} // Exit back to bottom
            transition={{ duration: 0.5, ease: "easeOut" }}
            className="popup-container fixed bottom-14 left-0 right-0 z-[80] m-auto w-full sm:bottom-0 sm:w-96"
          >
            <div className="popup-content rounded-t-2xl bg-[#000317] p-6 text-white shadow-lg">
              <div className="flex items-center justify-between">
                <h4 className="text-md font-bold ">Register with Phone</h4>
                <button className="close-popup-btn " onClick={togglePopup}>
                  Close
                </button>
              </div>

              {/* Phone Number Input Form */}
              {!isOtpSent && (
                <form onSubmit={handlePhoneSubmit}>
                  <div className="mt-5 flex items-center justify-between">
                    <PhoneInput
                      international
                      defaultCountry="CA"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      className="phone-input"
                    />
                    <button
                      type="submit"
                      className="submit-btn rounded-none  bg-green-600 py-2 px-4 text-white"
                    >
                      Send OTP
                    </button>
                  </div>
                </form>
              )}

              {/* OTP Input Form */}
              {isOtpSent && (
                <form onSubmit={handleOtpSubmit}>
                  <div className="mt-5">
                    <label htmlFor="otp" className="text-sm">
                      Enter the 4-digit OTP sent to your phone
                    </label>
                    <div className="mt-2 flex space-x-2">
                      {otp.map((digit, index) => (
                        <input
                          key={index}
                          type="text"
                          name={`otp-${index}`}
                          maxLength={1}
                          value={digit}
                          onChange={(e) => handleOtpChange(e.target, index)}
                          className="otp-input text-black h-12 w-12 rounded border border-gray-300 text-center focus:outline-none"
                        />
                      ))}
                    </div>
                    <button
                      type="submit"
                      className="submit-btn mt-5 rounded bg-green-600 py-2 px-4 text-white"
                    >
                      Verify OTP
                    </button>
                  </div>
                </form>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default PhoneNumberPopup;
