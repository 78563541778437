import React from "react";
import Navbar from "./Navbar";
import Hero from "./Hero";
import Heading from "components/Heading/Heading.";
import { MdLocationOn } from "react-icons/md";
import { SiJaguar } from "react-icons/si";
import { SiNissan } from "react-icons/si";
import { SiHonda } from "react-icons/si";
import { SiAudi } from "react-icons/si";
import { SiSuzuki } from "react-icons/si";
import { SiFerrari } from "react-icons/si";
import { SiFord } from "react-icons/si";
import { motion } from "framer-motion";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoCarSportSharp } from "react-icons/io5";
import Carcard from "components/Carcard/Carcard";
import { car3 } from "Utils/Images";
import { car4 } from "Utils/Images";
import { car5 } from "Utils/Images";
import Whychooseus from "./Whychooseus/Whychooseus";
import { yellowFrarri } from "Utils/Images";
const steps = [
  {
    icon: <MdLocationOn color="red" size={34} />,
    title: "Choose location",
    description:
      "Choose your ride today and experience the best car rental service tailored just for you.",
    detailedDescription:
      "Discover the joy of driving the ideal car that complements your lifestyle and meets all your travel needs. Choose your ride today and experience the best car rental service tailored just for you.",
  },
  {
    icon: <FaRegCalendarAlt color="red" size={34} />,
    title: "Pick-up date",
    position: "relative sm:-top-14",
    description:
      "Planning a trip or need a car for a special occasion? Start by selecting your pick-up date and time to book the perfect vehicle for your needs.",
    detailedDescription:
      "Planning a trip or need a car for a special occasion? Start by selecting your pick-up date and time to book the perfect vehicle for your needs. Our intuitive booking system allows you to easily choose the exact date and time you wish to collect your car, ensuring that your vehicle is ready when you are. ",
  },
  {
    icon: <IoCarSportSharp color="red" size={34} />,
    title: "Book your car",
    description:
      "Choose your ride today and experience the best car rental service tailored just for you.",
    detailedDescription:
      "Discover the joy of driving the ideal car that complements your lifestyle and meets all your travel needs. Choose your ride today and experience the best car rental service tailored just for you.",
  },
];

const carBrands = [
  { component: SiJaguar, name: "Jaguar" },
  { component: SiNissan, name: "Nissan" },
  { component: SiHonda, name: "Honda" },
  { component: SiAudi, name: "Audi" },
  { component: SiSuzuki, name: "Suzuki" },
  { component: SiFerrari, name: "Ferrari" },
  { component: SiFord, name: "Ford" },
];

export default function CarRental() {
  return (
    <section>
      <section className="section-with-frame text-gray-800">
        <Navbar />
        <Hero />
      </section>
      <div
        className="0 m-auto flex max-w-8xl flex-col justify-start py-0 sm:py-12"
        id="how-it-works"
      >
        <Heading
          Heading={"HOW IT WORK"}
          paragraph={"Rent with following 3 working steps"}
        />
        <div className="mt-0 grid grid-cols-1 gap-6 sm:mt-14 sm:grid-cols-3 sm:gap-0 ">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`m-auto flex max-h-72 w-89p flex-col items-center justify-center rounded-lg bg-red-50 p-5 text-center sm:h-auto  sm:bg-white ${step?.position}`}
            >
              <div className="rounded-lg bg-white p-4 sm:bg-red-50">
                {step.icon}
              </div>
              <h2 className="mt-3 text-lg font-semibold text-gray-800">
                {step.title}
              </h2>
              <p
                className={`hidden text-sm text-gray-800 sm:block sm:text-base `}
              >
                {step.description}
              </p>
              <p className="block text-sm text-gray-700 sm:hidden sm:text-base">
                {step.detailedDescription}
              </p>
            </div>
          ))}
        </div>

        <div className="hide-scroll-bar m-auto w-80 overflow-scroll px-0 sm:w-full sm:px-0">
          <div className="icon-animat  my-5 mt-5 flex items-center justify-evenly gap-5 text-gray-900 sm:mt-12">
            {carBrands.map((brand, index) => {
              const IconComponent = brand.component;
              return (
                <motion.a
                  whileHover={{ scale: 1.2 }}
                  onHoverStart={(e) => {}}
                  onHoverEnd={(e) => {}}
                >
                  <IconComponent
                    key={index}
                    size={40}
                    className="block hover:text-red-600 sm:hidden"
                  />
                  <IconComponent
                    key={index}
                    size={80}
                    className="hidden hover:text-red-600 sm:block"
                  />
                </motion.a>
              );
            })}
          </div>
        </div>
      </div>

      <section
        className="m-auto mb-10 max-w-8xl px-6 text-center"
        id="rental-deals"
      >
        <Heading
          Heading={"POPULAR RENTAL DEALS"}
          paragraph={"Most popular cars rental deals"}
        />
        <div className="grid grid-cols-1 gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          <Carcard img={car3} name="Jaguar XE L P250" />
          <Carcard img={car4} name="Audi R8" />
          <Carcard img={car5} name="BMW M3" />
          <Carcard img={yellowFrarri} name="Lamborghini Huracan" />
        </div>
        <button className="bth mt-12 rounded-sm border border-gray-400 py-2 px-12 text-gray-800 shadow-sm sm:mt-20 ">
          Show all vehicles{" "}
          <span className="relative  left-2 text-xl font-bold transition-all duration-700 hover:left-5">
            &rarr;
          </span>
        </button>
      </section>
      <section className="my-5 sm:my-20" id="why-choose-us">
        <Whychooseus />
      </section>
    </section>
  );
}
