import React from "react";
import SearchItemRow from "../SearchItemRow/SearchItemRow";
import ExclusiveDeals from "components/ExclusiveDeals/ExclusiveDeals";
import Background from "components/background/Background";
import {
  beatch,
  Carousel1,
  holiOffer,
  innerHolioffer,
} from "../../../Utils/Images";
import { useLocation } from "react-router-dom";
import PopularDestination from "components/PopularDestination/PopularDestination";
export default function Hotdeals() {
  const location = useLocation();
  const carouselData = [
    {
      id: "1",
      Image: holiOffer,
      heading: "Unmissable Holi Offers Await!",
      paragraph: "Book now and get 30% discount's",
      offer: "Flight",
      coupon: "HOLI45",

      innerImage: innerHolioffer,

      booking_validaity: "30/05/2024",
      terms_conditions: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
    {
      id: "2",
      Image: Carousel1,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: Carousel1,
      booking_validaity: "30/05/2024",
      terms_conditions: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
    {
      id: "3",
      Image: Carousel1,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: Carousel1,
      booking_validaity: "30/05/2024",
      terms_conditions: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
    {
      id: "4",
      Image: Carousel1,
      heading: "car International at LOWEST FARE!",
      paragraph: "Book car France-KLM Airline Flight Tickets.",
      offer: "Flight",
      coupon: "HOLI45",
      innerImage: Carousel1,
      booking_validaity: "30/05/2024",
      terms_conditions: [
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
        "Offer is directly valid only on www.maharajaflights.com.",
      ],
    },
  ];
  return (
    <section className="text-gray-800">
      <Background bg={beatch} hidden="hidden" block="block" />

      <div
        className={`absolute top-72 left-1/2 hidden h-72 w-5/6 -translate-x-1/2 transform rounded-lg ${
          location.pathname === "/hotdeals" ? "bgimage" : ""
        } text-gray-900 sm:block`}
      >
        <SearchItemRow />
        {/* <div
          className={`${location.pathname === "/hotdeals" ? "bgimage" : ""}`}
        >
          g
        </div> */}
        <div className="absolute bottom-[-35px] flex w-full justify-center">
          <button className="btn-grad">Search</button>
        </div>
      </div>
      <ExclusiveDeals carouselData={carouselData} />
      {/* <PopularDestination /> */}
    </section>
  );
}
