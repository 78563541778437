import React, { useEffect, useState } from "react";
import VisaNavbar from "components/VisaComponents/VisaNavbar";
import { Visabg2, Visabg3, Visabg4 } from "Utils/Images";
import {
  indiaflagicon,
  canadaflagicon,
  singaporeflagicon,
  americaflagicon,
  germanyflagicon,
  chinaflagicon,
} from "Utils/Images";
import { Visabg5 } from "Utils/Images";
import { Visabg6 } from "Utils/Images";
import { Visabg7 } from "Utils/Images";
import { germany } from "Utils/Images";
import { singapore } from "Utils/Images";
import { america } from "Utils/Images";
import { tajmehal } from "Utils/Images";
import { canada } from "Utils/Images";
import { china } from "Utils/Images";
import ToprankPassport from "components/VisaComponents/ToprankPassport";
import WarningInfo from "components/WarningInfo/WarningInfo";

const VisaInformation = () => {
  const slides = [
    {
      image: Visabg2,
      imagelg: tajmehal,
      flag: indiaflagicon,
      toFlags: [
        canadaflagicon,
        americaflagicon,
        singaporeflagicon,
        chinaflagicon,
        germanyflagicon,
      ],
      description:
        "Indian citizens can travel to countries like Canada, USA, Singapore, China, and Germany with a valid visa.",
      descriptionlg:
        "Indian citizens are required to obtain a visa for travel to countries like Canada, the USA, Singapore, China, and Germany. Depending on the destination, they may need to apply for a tourist, business, or work visa, which typically involves submitting an application, providing biometric information, and showing proof of financial stability or travel plans. While some countries have simplified processes for Indian nationals, the general visa process remains mandatory for most travel destinations.",
    },
    {
      image: Visabg3,
      imagelg: america,
      flag: americaflagicon,
      toFlags: [
        canadaflagicon,
        singaporeflagicon,
        chinaflagicon,
        indiaflagicon,
        germanyflagicon,
      ],
      description:
        "U.S. citizens can travel to several countries including Canada, Singapore, China, India, and Germany with simplified visa requirements.",
      descriptionlg:
        "U.S. citizens have the benefit of traveling to numerous countries visa-free or with simplified visa processes. For countries like Canada, Germany, and Singapore, short visits generally do not require a visa, while destinations such as China and India mandate visa applications prior to travel. The visa application process may include providing an itinerary, financial proof, and in some cases, attending an in-person interview at a consulate or embassy.",
    },
    {
      image: Visabg4,
      imagelg: canada,
      flag: canadaflagicon,
      toFlags: [
        germanyflagicon,
        americaflagicon,
        chinaflagicon,
        indiaflagicon,
        singaporeflagicon,
      ],
      description:
        "Canadian citizens have simplified visa processes when traveling to Germany, the USA, China, India, and Singapore.",
      descriptionlg:
        "Canadian passport holders enjoy visa-free access to several countries such as the USA and Germany for short stays. However, when traveling to countries like China and India, Canadians must obtain a visa before arrival. The visa process typically requires proof of funds, a return ticket, and details of accommodation, although some countries offer expedited visa services for Canadian citizens.",
    },
    {
      image: Visabg5,
      imagelg: china,
      flag: chinaflagicon,
      toFlags: [
        canadaflagicon,
        americaflagicon,
        singaporeflagicon,
        indiaflagicon,
        germanyflagicon,
      ],
      description:
        "Chinese citizens need visas for countries such as Canada, USA, Singapore, India, and Germany, though special arrangements may apply.",
      descriptionlg:
        "Chinese nationals must apply for visas to travel to most countries, including Canada, the USA, India, Germany, and Singapore. The visa application process often includes providing an invitation letter, proof of sufficient funds, and travel insurance. For neighboring Asian countries, Chinese travelers may benefit from visa-on-arrival or short-term visa exemptions under specific bilateral agreements.",
    },
    {
      image: Visabg6,
      imagelg: singapore,
      flag: singaporeflagicon,
      toFlags: [
        canadaflagicon,
        americaflagicon,
        chinaflagicon,
        indiaflagicon,
        germanyflagicon,
      ],
      description:
        "Singaporeans can travel to Canada, USA, China, India, and Germany with visa exemptions or streamlined visa applications.",
      descriptionlg:
        "Singaporean citizens benefit from widespread visa-free or visa-on-arrival access across numerous countries. For destinations like Canada, the USA, and Germany, Singaporeans typically don’t require a visa for short stays. However, for longer stays or travel to China and India, a visa application is necessary. These applications often include submitting an itinerary and proof of accommodation, though Singapore's strong diplomatic relationships simplify the process.",
    },
    {
      image: Visabg7,
      imagelg: germany,
      flag: germanyflagicon,
      toFlags: [
        canadaflagicon,
        americaflagicon,
        chinaflagicon,
        indiaflagicon,
        singaporeflagicon,
      ],
      description:
        "German citizens can travel to many countries including Canada, the USA, China, India, and Singapore with visa-free or simplified visa processes.",
      descriptionlg:
        "German passport holders enjoy extensive visa-free access to numerous countries, including the USA, Canada, and most of Europe under the Schengen Agreement. When traveling to countries like China and India, German citizens must apply for a visa, which may include providing proof of accommodation, financial stability, and travel health insurance. For short stays in many countries, Germans are allowed to enter without a visa, benefiting from one of the strongest passports in the world.",
    },
  ];

  const [activeSlide, setActiveSlide] = useState(0);

  // Automatically change slides every 4 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 9000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="text-gray-800">
      <div className="relative ">
        <div className="w-full">
          <div className="carousel h-[90vh] w-full sm:h-[100vh]">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`carousel-item relative  w-full ${
                  index === activeSlide ? "block" : "hidden"
                }`}
              >
                <div className="absolute top-0  w-full  ">
                  <img
                    src={slide.image}
                    className="block h-[90vh] w-full overflow-hidden sm:hidden "
                    alt={`Slide ${index + 1}`}
                  />
                  <img
                    src={slide.imagelg}
                    className="hidden h-[100vh] w-full overflow-hidden sm:block "
                    alt={`Slide ${index + 1}`}
                  />
                  <div
                    className="absolute top-0 z-10 h-full w-full opacity-60"
                    style={{ background: "black" }}
                  ></div>

                  {/* Visa and Flag Information */}
                  <div className="absolute bottom-0 left-1/3 z-10 ml-2 -translate-x-1/2 -translate-y-28 transform text-white sm:bottom-1/4 sm:ml-0 sm:w-1/2 sm:translate-y-20">
                    <h1 className="font-poppins text-2xl font-semibold text-red-500 sm:text-5xl">
                      Passport Index and Visa Information
                    </h1>
                    <p className="mt-2 block font-light text-gray-300 sm:hidden">
                      {slide.description}
                    </p>
                    <p className="mt-3 hidden font-light text-gray-300 sm:block">
                      {slide.descriptionlg}
                    </p>
                    <div className="mt-5 flex items-center gap-2">
                      {/* From Flag */}
                      <div className="avatar">
                        <div className="w-12 rounded-full border-4 border-customDarkBlue">
                          <img src={slide.flag} alt="From Country Flag" />
                        </div>
                      </div>

                      <h2 className="font-mono text-xl">TO</h2>

                      {/* To Flags */}
                      <div className="avatar-group -space-x-6 rtl:space-x-reverse ">
                        {slide.toFlags.map((flag, idx) => (
                          <div
                            className="avatar border-customDarkBlue"
                            key={idx}
                          >
                            <div className="w-10 ">
                              <img src={flag} alt="To Country Flag" />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <VisaNavbar />
      </div>
      <div className="my-5 px-5 sm:my-10 sm:px-0">
        <ToprankPassport />
      </div>
      <div className="m-auto max-w-7xl">
        <WarningInfo
          heading="Important Visa Information"
          paragraph={[
            "Every effort has been made to ensure that the visa information provided on our website is accurate and up-to-date. However, visa rules and requirements are subject to change without prior notice, and it may take time for us to update the information.",
            "We strongly recommend verifying all visa-related details directly with the respective embassy or high commission before making travel arrangements. Maharaja Flights Limited will not be held responsible for any discrepancies, changes, or issues arising due to outdated or incomplete information.",
          ]}
        />
      </div>
    </section>
  );
};

export default VisaInformation;
