import React from "react";
import { useNavigate } from "react-router-dom";
import MaharajaLogo from "components/MaharajaLogo/MaharajaLogo";
import Logo from "components/maharjalogowithplane/Logo";

export default function Navbar() {
  const navItems = [
    { label: "Become a renter", path: "/become-a-renter" },
    { label: "Rental deals", path: "#rental-deals" },
    { label: "How it works", path: "#how-it-works" },
    { label: "Why choose us", path: "#why-choose-us" },
    { label: "Contact us", path: "/contact-us" },
  ];

  return (
    <section className="relative z-10 py-5">
      <div className="flex w-96 items-center justify-center sm:hidden">
        <Logo airplanePosition="left-2" />
      </div>
      <div className="madimi-one-regular m-auto my-5 hidden max-w-4xl list-none items-end justify-between bg-transparent text-center sm:flex">
        <MaharajaLogo />
        {navItems.map((item, key) => (
          <a
            href={item.path}
            key={key}
            className="cursor-pointer hover:text-red-600"
          >
            {item.label}
          </a>
        ))}
      </div>
    </section>
  );
}
