import React, { useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "react-tailwindcss-select";
export default function CruiseFromPhone({ options }) {
  const [airpotsFrom, setAirpotsFrom] = useState(null);
  const [airpotsTo, setAirpotsTo] = useState(null);
  const [Room, setRoom] = useState(1);
  const [Guest, setGuest] = useState(2);
  const [depart, setDepart] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });
  const [returnFlight, setReturnFlight] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const From = (value) => {
    setAirpotsFrom(value);
  };

  const departFun = (newValue) => {
    setDepart(newValue);
  };

  const setReturnFlightFun = (newValue) => {
    setReturnFlight(newValue);
  };

  const onSubmit = () => {
    const data = {
      airports_from: airpotsFrom,
      airports_to: airpotsTo,
      departure_date: depart,
      return_date: returnFlight,
      roundTrip: true,
      travelers: {
        Room: parseInt(Room),
        Guest: parseInt(Guest),
      },
    };
    console.log(data);
  };
  return (
    <section className="text-gray-800">
      <form onSubmit={onSubmit} className="">
        <div className="">
          <h2 className="mb-2 px-3 text-xl font-semibold">Cruise</h2>
          <div className="rounded-xl bg-gray-100 p-1 px-3">
            <label className="text-md ">
              Enter Destination or Preferred Cruise Line
            </label>
            <Select
              value={airpotsFrom}
              onChange={From}
              options={options}
              isSearchable={true}
              placeholder="Toronto"
              loading={false}
              classNames={{
                menuButton: ({ isDisabled }) =>
                  `flex  w-full item-center relative -left-3 border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer  ${
                    isDisabled
                      ? "bg-gray-200 "
                      : "bg-transparent hover:border-gray-400 cursor-pointer"
                  }`,
                menu: "absolute -top-0 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                listItem: ({ isSelected }) =>
                  `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                    isSelected
                      ? `text-white bg-blue-500`
                      : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                  }`,
              }}
              formatGroupLabel={(data) => (
                <div
                  className={`flex items-center justify-between py-2 text-xs`}
                >
                  <span className="font-bold">{data.label}</span>
                  <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                    {data.options.length}
                    👉 Airpots
                  </span>
                </div>
              )}
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {data.label}
                </li>
              )}
            />
          </div>

          <div className="my-2 rounded-xl bg-gray-100 p-1 px-3">
            <div className="flex items-center gap-2">
              <FaCalendarAlt />
              <label className="text-md">CHECK IN</label>
            </div>
            <Datepicker
              containerClassName=""
              inputClassName="p-0 pt-2 pb-1 bg-transparent outline-0 text-gray-900 w-72 z-50 cursor-pointer "
              toggleClassName="relative hover:text-red-500 "
              popoverDirection="bottom"
              useRange={false}
              asSingle={true}
              value={depart}
              showShortcuts={false}
              // disabledDates={[
              //   {
              //     startDate: "2024-03-04",
              //     endDate: "2023-02-05",
              //   },
              //   {
              //     startDate: "2024-04-05",
              //     endDate: "2023-02-12",
              //   },
              // ]}
              onChange={departFun}
            />
          </div>
          <div className="my-2 rounded-xl bg-gray-100 p-1 px-3">
            <div className="flex items-center gap-2">
              <FaCalendarAlt />
              <label className="text-md">CHECK OUT</label>
            </div>
            <Datepicker
              containerClassName=""
              inputClassName="p-0 pt-2  pb-1 bg-transparent outline-0 text-gray-900 w-72 cursor-pointer"
              toggleClassName="hover:text-red-500 "
              popoverDirection="bottom"
              useRange={false}
              asSingle={true}
              value={returnFlight}
              showShortcuts={false}
              showFooter={false}
              configs={{
                // shortcuts: {
                //   today: "TText",
                //   yesterday: "YText",
                //   past: (period) => `P-${period} Text`,
                //   currentMonth: "CMText",
                //   pastMonth: "PMText",
                // },
                footer: {
                  cancel: "Cencel",
                  apply: "Apply",
                },
              }}
              // disabledDates={[
              //   {
              //     startDate: "2024-03-04",
              //     endDate: "2023-02-05",
              //   },
              //   {
              //     startDate: "2024-04-05",
              //     endDate: "2023-02-12",
              //   },
              // ]}
              onChange={setReturnFlightFun}
            />
          </div>
          <div className="my-2 rounded-xl bg-gray-100 p-1 px-3">
            <div className="flex items-center gap-2">
              <FaCalendarAlt />
              <label className="text-md">CRUISE DETAILS</label>
            </div>
            <div className=" dropdown w-full ">
              <div tabIndex={0} role="button" className="cursor-pointer">
                <h3 className="text-lg">
                  {Room} Room {Guest} Guest
                </h3>
              </div>
              <div
                tabIndex={0}
                className="menu dropdown-content z-[1] w-full rounded-box bg-white p-2 shadow "
              >
                <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                  <div className="flex items-center justify-between">
                    <h4>Room</h4>
                    <h4>{Room}</h4>
                    <div className="flex items-center rounded-md border">
                      <div
                        className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => {
                          if (Room > 1) {
                            setRoom(Room - 1);
                          }
                        }}
                      >
                        -
                      </div>
                      <div
                        className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => setRoom(Room + 1)}
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                  <div className="flex items-center justify-between">
                    <div>
                      <h4>Guest</h4>
                    </div>
                    <h4>{Guest}</h4>
                    <div className="flex items-center rounded-md border">
                      <div
                        className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => {
                          if (Guest > 0) {
                            setGuest(Guest - 1);
                          }
                        }}
                      >
                        -
                      </div>
                      <div
                        className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                        onClick={() => setGuest(Guest + 1)}
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full justify-center">
          <button className="btn-grad w-full" type="submit">
            SUBMIT
          </button>
        </div>
      </form>
    </section>
  );
}
