import React from "react";

export default function PrivacyContentComponent({
  heading,
  list,
  paragraph,
  email,
  ContactNumber,
}) {
  return (
    <div>
      {heading && <h2 className="text-xl font-semibold">{heading}</h2>}{" "}
      {list && (
        <ol type="1">
          {list.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
      )}
      <div className="mb-2">
        {paragraph && <p className="inline">{paragraph}</p>}
        {email && (
          <a className="ml-1 inline text-primary" href={`mailto:${email}`}>
            {email}
          </a>
        )}
      </div>
      <div>{ContactNumber && ContactNumber}</div>
    </div>
  );
}
