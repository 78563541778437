import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function FlightWayRoute() {
  const location = useLocation();
  return (
    <section className="">
      {location.pathname !== "/" ? (
        <div className="m-auto mt-5 flex flex-wrap items-center justify-between px-5 font-semibold text-gray-800 sm:mt-10">
          <fieldset className="flex gap-2">
            <Link to={"/flights/round-trip"}>
              <div className="flex items-center gap-2 rounded-full border bg-white px-3 py-0.5 text-sm">
                <div
                  className={`h-4 w-4 rounded-full border-2 border-gray-300 ${
                    location.pathname === "/flights/round-trip" ||
                    location.pathname === "/flights"
                      ? "bg-red-400"
                      : ""
                  }`}
                ></div>

                <label
                  htmlFor="roundTrip"
                  className="cursor-pointer whitespace-nowrap"
                >
                  Round Trip
                </label>
              </div>
            </Link>
            <Link to={"/flights/oneway"}>
              <div className="flex items-center gap-2 rounded-full border bg-white px-2 py-0.5 text-sm">
                <div
                  className={`h-4 w-4 rounded-full border-2 border-gray-300 ${
                    location.pathname === "/flights/oneway" ? "bg-red-400" : ""
                  }`}
                ></div>
                <label
                  htmlFor="oneWay"
                  className="cursor-pointer whitespace-nowrap"
                >
                  One Way
                </label>
              </div>
            </Link>

            <Link to={"/flights/multi-city"}>
              <div className="flex items-center gap-2 rounded-full border bg-white px-3 py-0.5 text-sm">
                <div
                  className={`h-4 w-4 rounded-full border-2 border-gray-300 ${
                    location.pathname === "/flights/multi-city"
                      ? "bg-red-400"
                      : ""
                  }`}
                ></div>

                <label
                  htmlFor="multiCity"
                  className="cursor-pointer whitespace-nowrap"
                >
                  Multi City
                </label>
              </div>
            </Link>
          </fieldset>
          <div className="mt-2 sm:mt-0">
            Book International and Domestic Flights
          </div>
        </div>
      ) : (
        ""
      )}
      <Outlet />
    </section>
  );
}
