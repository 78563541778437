import React from "react";
import { Link } from "react-router-dom";

export default function PopularFlightRoutesCard({
  title,
  imageSrc,
  destinations,
}) {
  return (
    <div className="box_shadow grid grid-cols-[15%_85%] overflow-hidden rounded-lg border border-gray-200">
      <div>
        <img
          src={imageSrc}
          alt={title}
          className="h-full w-full object-cover"
        />
      </div>
      <div className="p-4 pr-0 text-sm">
        <h2 className="text-base font-bold">{title}</h2>
        <div className="flex flex-nowrap ">
          <p className="mr-2 inline font-semibold text-gray-600">To:</p>
          <ul className="flex cursor-pointer list-disc flex-wrap  space-x-6   text-blue-400">
            {destinations.map((destination, index) => (
              <p
                key={index}
                className={`${
                  index === destinations.length - 1 ? "hidden  sm:block" : ""
                } `}
              >
                <li className={`${index === 0 ? "list-none" : ""} `}>
                  <Link>{destination}</Link>
                </li>
              </p>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
