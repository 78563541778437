import React, { useState } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import Datepicker from "react-tailwindcss-datepicker";
import Select from "react-tailwindcss-select";

export default function HotelBookingForm({ cityOptions }) {
  const [selectedCity, setSelectedCity] = useState(null);
  const [checkInDate, setCheckInDate] = useState(new Date());

  const handleCityChange = (value) => {
    setSelectedCity(value);
  };

  const handleCheckInDateChange = (newValue) => {
    setCheckInDate(newValue.startDate);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = {
      city: selectedCity,
      check_in_date: checkInDate,
    };
    console.log(formData);
  };

  return (
    <section className="text-gray-800">
      <form onSubmit={handleSubmit} className="">
        <div className="">
          <h2 className="mb-2 px-3 text-xl font-semibold">Hotel Booking</h2>
          <div className="rounded-xl bg-gray-100 p-1 px-3">
            <label className="text-md">Select City</label>
            <Select
              value={selectedCity}
              onChange={handleCityChange}
              options={cityOptions}
              isSearchable={true}
              placeholder="Select City"
              loading={false}
              classNames={{
                menuButton: ({ isDisabled }) =>
                  `flex  w-full item-center relative -left-3 border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer  ${
                    isDisabled
                      ? "bg-gray-200 "
                      : "bg-transparent hover:border-gray-400 cursor-pointer"
                  }`,
                menu: "absolute -top-0 z-10 w-full bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                listItem: ({ isSelected }) =>
                  `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                    isSelected
                      ? `text-white bg-blue-500`
                      : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                  }`,
              }}
              formatGroupLabel={(data) => (
                <div
                  className={`flex items-center justify-between py-2 text-xs`}
                >
                  <span className="font-bold">{data.label}</span>
                  <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                    {data.options.length}👉 Cities
                  </span>
                </div>
              )}
              formatOptionLabel={(data) => (
                <li
                  className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                    !data.isSelected
                      ? `bg-gray-200 text-gray-900`
                      : `bg-blue-800 text-gray-200`
                  }`}
                >
                  {data.label}
                </li>
              )}
            />
          </div>

          <div className="my-2 rounded-xl bg-gray-100 p-1 px-3">
            <div className="flex items-center gap-2">
              <FaCalendarAlt />
              <label className="text-md">Check-in Date</label>
            </div>
            <Datepicker
              containerClassName=""
              inputClassName="p-0 pt-2 pb-1 bg-transparent outline-0 text-gray-900 w-72 z-50 cursor-pointer "
              toggleClassName="relative hover:text-red-500 "
              popoverDirection="bottom"
              useRange={false}
              asSingle={true}
              value={{ startDate: checkInDate, endDate: checkInDate }}
              showShortcuts={false}
              onChange={handleCheckInDateChange}
            />
          </div>
        </div>

        <div className="flex w-full justify-center">
          <button className="btn-grad w-full" type="submit">
            SUBMIT
          </button>
        </div>
      </form>
    </section>
  );
}
