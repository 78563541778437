import React from "react";
import Background from "components/background/Background";
import { motion } from "framer-motion";
export default function Banner({
  charterplane,
  charterplanesm,
  heading,
  paragraph,
  darkness,
  button,
}) {
  return (
    <section className="relative">
      <div>
        <Background
          bg={charterplane}
          hidden="hidden"
          block="block"
          darkness="rgba(0, 0, 0, 0.7)"
        />
      </div>
      <div>
        <Background
          bg={charterplanesm}
          hidden="block"
          block="hidden"
          darkness={darkness}
        />
      </div>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-center px-5 text-white sm:px-32"
      >
        <div className="text-center">
          <h1 className="mb-1 text-xl font-extrabold sm:mb-4 sm:text-6xl">
            {heading}
          </h1>
          <p className="text-md mb-8  sm:text-xl">{paragraph}</p>
          {button && (
            <button className="bg-carousal rounded-full py-3 px-6 text-white shadow-md transition duration-300 hover:bg-red-700">
              {button || "Booking Enquiry"}
            </button>
          )}
        </div>
      </motion.div>
      {/* <div className="relative block sm:hidden"> */}
      <div className="relative">
        <div class="custom-shape-divider-bottom-1714468197">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 598.97 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </div>
    </section>
  );
}
