import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import UserNavbar from "components/UserNavbar/UserNavbar";
import travel_stories from "./Data";
import TravelDataCompo from "components/TravelDataCompo/TravelDataCompo";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";

export default function TravelStories() {
  const location = useLocation();
  const data = travel_stories.find((item) => item.id === location.state);

  return (
    <section className="text-gray-800">
      <ScrollToTopOnRouteChange />
      <UserNavbar />
      {data?.poster && (
        <div
          className="relative h-80 overflow-hidden bg-cover bg-center"
          style={{ backgroundImage: `url(${data?.poster})` }}
        >
          <div
            className="absolute top-0 h-full w-full"
            style={{
              backgroundColor: "rgb(0 0 0 / 63%)",
            }}
          ></div>
          {data?.heading && (
            <div className="absolute bottom-5 left-5 right-5 px-1 text-white  sm:left-28 sm:right-28 ">
              <h2 className="text-xl font-bold sm:text-5xl">{data?.heading}</h2>
              {data?.writtenBy && (
                <div>
                  <h5>
                    Written by{" "}
                    <span className="font-bold">{data?.writtenBy}</span> on{" "}
                    <span className="font-bold">{data?.writtenDate}</span>
                  </h5>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div className="m-auto max-w-8xl px-5 text-gray-800 sm:px-0">
        <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
          <div className="md:col-span-2">
            {data?.discruption && (
              <div className="my-3">
                <p>{data?.discruption}</p>
              </div>
            )}
            <TravelDataCompo
              heading={data.firstHeading}
              image={data.firstImage}
              dis={data.firstDis}
              paragraph={data.firstparagraph}
            />
            <TravelDataCompo
              heading={data.secondHeading}
              image={data.secondImage}
              dis={data.secondDis}
              paragraph={data.secondparagraph}
            />
            <TravelDataCompo
              heading={data.thirdHeading}
              image={data.thirdImage}
              dis={data.thirdDis}
              paragraph={data.thirdparagraph}
            />

            <TravelDataCompo
              heading={data.fourthHeading}
              image={data.fourthImage}
              dis={data.fourthDis}
              paragraph={data.fourthparagraph}
            />
            <TravelDataCompo
              heading={data.fiveHeading}
              image={data.fiveImage}
              dis={data.fiveDis}
              paragraph={data.fiveparagraph}
            />
            <TravelDataCompo
              heading={data.sixHeading}
              image={data.sixImage}
              dis={data.sixDis}
              paragraph={data.sixparagraph}
            />
            <TravelDataCompo
              heading={data.savenHeading}
              image={data.savenImage}
              dis={data.savenDis}
              paragraph={data.savenparagraph}
            />
            <TravelDataCompo
              heading={data.eightHeading}
              image={data.eightImage}
              dis={data.eightDis}
              paragraph={data.eightparagraph}
            />
            <TravelDataCompo
              heading={data.nineHeading}
              image={data.nineImage}
              dis={data.nineDis}
              paragraph={data.nineparagraph}
            />
            <TravelDataCompo
              heading={data.tenHeading}
              image={data.tenImage}
              dis={data.tenDis}
              paragraph={data.tenparagraph}
            />

            {/* <div>
              {data?.fourthHeading && (
                <div className="my-3 text-2xl font-bold">
                  <h2>{data?.fourthHeading}</h2>
                </div>
              )}
              {data?.fourthImage && (
                <div>
                  <img src={data?.fourthImage} alt={data?.fourthHeading} />
                </div>
              )}
              {data?.imgDis && (
                <div>
                  <p>{data?.imgDis}</p>
                </div>
              )}
              {data?.fourthDis && (
                <ul style={{ listStyleType: "disc" }} className="my-3">
                  {data?.fourthDis.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              )}
              {data?.fourthparagraph && (
                <div className="mb-5">
                  <p>{data?.fourthparagraph}</p>
                </div>
              )}
            </div> */}
          </div>
          <div className="my-5 rounded-sm md:col-span-1">
            <div className="sticky top-24">
              <form
                action="/submit-form"
                method="post"
                className="mx-auto max-w-md bg-red-50 p-5"
              >
                <h2 className="font-poppins text-2xl font-semibold">
                  Contact Us
                </h2>
                <div className="mb-2">
                  <label for="name" className="block text-gray-700">
                    Your Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    required
                    className="w-full rounded-md bg-white p-2 shadow-sm outline-none  "
                  />
                </div>

                <div className="mb-2">
                  <label for="email" className="block text-gray-700">
                    Your Email <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    className="w-full rounded-md bg-white p-2 shadow-sm outline-none  "
                  />
                </div>

                <div className="mb-2">
                  <label for="subject" className="block text-gray-700">
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    className="w-full rounded-md bg-white p-2 shadow-sm outline-none  "
                  />
                </div>

                <div className="mb-2">
                  <label for="message" className="block text-gray-700">
                    Your Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    cols={50}
                    rows={5}
                    className="w-full rounded-md bg-white p-2 shadow-sm outline-none  "
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="w-full rounded-md bg-green-600 px-4 py-2 text-white hover:bg-green-700 focus:outline-none focus:ring "
                >
                  Submit
                </button>
              </form>
              <div className="my-10 bg-red-50">
                {data?.poster && (
                  <div className="p-5">
                    <img src={data?.poster} alt={data?.heading} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
