import { useEffect, useState } from "react";

export default function PasswordStrengthIndicator({ value }) {
  const [strength, setStrength] = useState(0);

  const getPasswordStrength = (password) => {
    let score = 0;

    // Length
    if (password?.length >= 8) score += 25;
    else if (password?.length >= 6) score += 10;

    // Letters
    if (/[a-z]/.test(password) && /[A-Z]/.test(password)) score += 25;

    // Numbers
    if (/\d/.test(password)) score += 25;

    // Special characters
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) score += 25;

    return score;
  };

  const getProgressColor = (strength) => {
    if (strength >= 75) return "bg-green-500";
    if (strength >= 50) return "bg-yellow-500";
    if (strength >= 25) return "bg-orange-500";
    return "bg-red-500";
  };

  // Update strength when value changes
  useEffect(() => {
    if (value) {
      const strengthScore = getPasswordStrength(value);
      setStrength(strengthScore);
    } else {
      setStrength(0);
    }
  }, [value]);

  const progressColor = getProgressColor(strength);

  return (
    <div>
      {value?.length > 0 ? (
        <div>
          <div className="relative mb-2 h-2 w-full">
            <div
              className={`absolute left-0 top-0 h-full rounded-lg ${progressColor}`}
              style={{ width: `${strength}%` }}
            ></div>
          </div>
          <p className="text-sm">
            {strength >= 75
              ? "Strong"
              : strength >= 50
              ? "Medium"
              : strength >= 25
              ? "Weak"
              : "Very Weak"}
          </p>
        </div>
      ) : null}
    </div>
  );
}
