import React from "react";

export default function TravelDataCompo({ heading, image, dis, paragraph }) {
  return (
    <div>
      {heading && (
        <div className="my-3 text-2xl font-bold">
          <h2>{heading}</h2>
        </div>
      )}
      {image && (
        <div>
          <img src={image} alt={heading} />
        </div>
      )}
      {dis && (
        <ul style={{ listStyleType: "disc" }} className="my-3">
          {dis.map((item, index) => (
            <li key={index}>
              {item.heading && (
                <span className="font-semibold">{item.heading}</span>
              )}
              <span className="text-gray-400">{item.heading && "-"}</span>{" "}
              {item.li}
            </li>
          ))}
        </ul>
      )}
      {paragraph && (
        <div className="my-3 text-sm ">
          <h2>{paragraph}</h2>
        </div>
      )}
    </div>
  );
}
