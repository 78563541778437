import MaharajaLogo from "components/MaharajaLogo/MaharajaLogo";
import React, { useState } from "react";

// Define an array of objects for the links and their dropdown content
const menuItems = [
  {
    title: "Passport index",
    href: "#home",
    dropdown: null,
  },
  {
    title: "Visa free countries",
    href: "#services",
    dropdown: null,
  },
  {
    title: "Visa",
    href: "#visa",
    dropdown: [
      { name: "Canada Visa", href: "#canada-visa" },
      { name: "USA Visa", href: "#usa-visa" },
      { name: "UK Visa", href: "#uk-visa" },
      { name: "Australia Visa", href: "#australia-visa" },
      { name: "Germany Visa", href: "#germany-visa" },
      { name: "France Visa", href: "#france-visa" },
      { name: "India Visa", href: "#india-visa" },
      { name: "Japan Visa", href: "#japan-visa" },
    ],
  },
  {
    title: "Immigration visa",
    href: "#immigration",
    dropdown: [{ name: "Canada Visa", href: "#canada-immigration" }],
  },
  {
    title: "eTA",
    href: "#eta",
    dropdown: [
      { name: "Australia ETA", href: "#australia-eta" },
      { name: "Canada eTA", href: "#canada-eta" },
      { name: "USA ESTA", href: "#usa-esta" },
      { name: "New Zealand ETA", href: "#nz-eta" },
      { name: "UK eVisa", href: "#uk-evisa" },
      { name: "Singapore eVisa", href: "#singapore-evisa" },
    ],
  },
  {
    title: "Golden Visa",
    href: "#golden-visa",
    dropdown: [
      { name: "Greece Golden Visa", href: "#greece-golden-visa" },
      { name: "Portugal Golden Visa", href: "#portugal-golden-visa" },
      { name: "Spain Golden Visa", href: "#spain-golden-visa" },
      { name: "Italy Golden Visa", href: "#italy-golden-visa" },
      { name: "Cyprus Golden Visa", href: "#cyprus-golden-visa" },
      { name: "United Arab Emirates Golden Visa", href: "#uae-golden-visa" },
      { name: "Turkey Golden Visa", href: "#turkey-golden-visa" },
    ],
  },
];

export default function VisaNavbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="relative">
      <div className="fixed inset-x-0 top-2 z-50 mx-3 max-w-7xl rounded-md bg-white shadow-lg sm:top-5 sm:mx-auto">
        <div className="flex items-end justify-between px-6 py-4 ">
          {/* Logo Section */}
          <MaharajaLogo servise={"Visa"} />

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="focus:outline-none"
            >
              <svg
                className="h-6 w-6 text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"
                  }
                />
              </svg>
            </button>
          </div>

          {/* Navbar Links (Hidden on mobile) */}
          <ul className="hidden space-x-6 md:flex">
            {menuItems.map((item, index) => (
              <li key={index}>
                {item.dropdown ? (
                  <div className="dropdown-hover dropdown-end dropdown-bottom dropdown font-roboto font-semibold hover:text-red-500">
                    <div tabIndex={0} role="button">
                      {item.title}
                    </div>
                    <ul
                      tabIndex={0}
                      className="menu dropdown-content z-[1] w-52 rounded-box bg-white p-2 text-gray-800 shadow"
                    >
                      {item.dropdown.map((subItem, subIndex) => (
                        <li
                          key={subIndex}
                          className="rounded-md hover:bg-red-50 hover:text-red-500"
                        >
                          <a href={subItem.href}>{subItem.name}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <a
                    href={item.href}
                    className=" font-semibold hover:text-red-500"
                  >
                    {item.title}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Mobile Menu Sliding Drawer */}
        <div
          className={`fixed inset-0 transform bg-gray-900 bg-opacity-10 transition-transform ${
            isOpen ? "translate-x-0" : "translate-x-full"
          } z-40 md:hidden`}
          onClick={() => setIsOpen(false)}
        ></div>

        <div
          className={`fixed top-0 right-0 h-full w-64 transform overflow-scroll bg-white shadow-lg transition-transform ${
            isOpen ? "translate-x-0" : "translate-x-full"
          } z-50 md:hidden`}
        >
          <ul className="flex flex-col space-y-4 p-6 text-gray-800 ">
            <MaharajaLogo servise={"Visa"} />

            {menuItems.map((item, index) => (
              <li key={index}>
                {item.dropdown ? (
                  <div className="space-y-2">
                    <span className="font-semibold">{item.title}</span>
                    <ul
                      tabIndex={0}
                      className="menu dropdown-content z-[1] w-52 rounded-box bg-white p-2 text-gray-800 shadow"
                    >
                      {item.dropdown.map((subItem, subIndex) => (
                        <li
                          key={subIndex}
                          className="rounded-md hover:bg-red-50 hover:text-red-500"
                        >
                          <a href={subItem.href}>{subItem.name}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <a
                    href={item.href}
                    className="font-semibold hover:text-red-500"
                  >
                    {item.title}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
