import React, { useEffect, useState } from "react";
import Select from "react-tailwindcss-select";
import { useForm, useFieldArray } from "react-hook-form";
import Datepicker from "react-tailwindcss-datepicker";
import { FaCalendarAlt } from "react-icons/fa";
import Crossicon from "components/CrossIcon/Crossicon";

export default function MultiCityForPhone({ options }) {
  const { register, handleSubmit, control, setValue } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "flight",
  });
  const [airportsFrom, setAirportsFrom] = useState({});
  const [airportsTo, setAirportsTo] = useState({});
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(2);
  const [infants, setInfants] = useState(0);
  const [selectedFlightType, setSelectedFlightType] = useState("Economy");
  const [depart, setDepart] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const From = (value, index) => {
    setAirportsFrom((prev) => ({
      ...prev,
      [index]: value,
    }));
    // Set the value for "from" field in the form data
    setValue(`flight[${index}].from`, value.value);
  };
  const To = (value, index) => {
    setAirportsTo((prev) => ({
      ...prev,
      [index]: value,
    }));
    // Set the value for "from" field in the form data
    setValue(`flight[${index}].to`, value.value);
  };

  const departFun = (value, index) => {
    setDepart(value);
    setDepart((prev) => ({
      ...prev,
      [index]: value,
    }));
    // Set the value for "from" field in the form data
    setValue(`flight[${index}].depart`, value);
  };

  const handleFlightTypeChange = (event) => {
    setSelectedFlightType(event.target.value);
  };

  const onSubmit = (data) => {
    const finalData = {
      data,
      travelers: {
        adults: parseInt(adults),
        children: parseInt(children),
        infants: parseInt(infants),
      },
    };
    console.log(finalData);
  };

  useEffect(() => {
    append({
      from: "",
      to: "",
      depart: "",
    });
    // append({
    //   from: "",
    //   to: "",
    //   depart: "",
    // });
  }, [append]);
  return (
    <section className="text-gray-800">
      <form onSubmit={handleSubmit(onSubmit)} className="">
        {fields.map((city, index) => (
          <div>
            <div
              key={city.id}
              className="mb-2 flex justify-between gap-0.5 pl-5"
            >
              <div className="w-28 rounded-md border-2 pl-3">
                <label className="text-md">FROM</label>
                <Select
                  {...register(`flight.${index}.from`)}
                  value={airportsFrom[index]}
                  onChange={(value) => From(value, index)}
                  options={options}
                  isSearchable={true}
                  placeholder="Toronto"
                  loading={false}
                  classNames={{
                    menuButton: ({ isDisabled }) =>
                      `flex gap-5  cursor-pointer relative -left-3 border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                        isDisabled
                          ? "bg-gray-200 "
                          : "bg-white hover:border-gray-400 cursor-pointer"
                      }`,
                    menu: "absolute  z-10 w-80 bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 px-2  cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                  }}
                  formatGroupLabel={(data) => (
                    <div
                      className={`flex items-center justify-between py-2 text-xs`}
                    >
                      <span className="font-bold">{data.label}</span>
                      <span className="flex h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                        {data.options.length}
                        👉 Airpots
                      </span>
                    </div>
                  )}
                  formatOptionLabel={(data) => (
                    <li
                      className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                        !data.isSelected
                          ? `bg-gray-200 text-gray-900`
                          : `bg-blue-800 text-gray-200`
                      }`}
                    >
                      {data.label}
                    </li>
                  )}
                />
              </div>
              <div className=" w-28 rounded-md border-2 pl-3">
                <label className="text-md">To</label>
                <Select
                  {...register(`flight.${index}.to`)}
                  value={airportsTo[index]}
                  onChange={(value) => To(value, index)}
                  options={options}
                  isSearchable={true}
                  placeholder="Delhi"
                  loading={false}
                  classNames={{
                    menuButton: ({ isDisabled }) =>
                      `flex gap-5  cursor-pointer relative -left-3 border-0 text-lg overflow-hidden text-gray-900 transition-all outline-none duration-300 focus:outline-none cursor-pointer ${
                        isDisabled
                          ? "bg-gray-200 "
                          : "bg-white hover:border-gray-400 cursor-pointer"
                      }`,
                    menu: "absolute -left-28 z-10 w-80 bg-white shadow-lg border rounded py-1 mt-1.5 text-sm text-gray-700",
                    listItem: ({ isSelected }) =>
                      `block transition duration-200 px-2 cursor-pointer select-none truncate rounded ${
                        isSelected
                          ? `text-white bg-blue-500`
                          : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                      }`,
                  }}
                  formatGroupLabel={(data) => (
                    <div
                      className={`flex items-center justify-between py-2 text-xs`}
                    >
                      <span className="font-bold">{data.label}</span>
                      <span className="flex  h-5 items-center justify-center rounded-full bg-gray-200 p-1.5">
                        {data.options.length}
                        👉 Airpots
                      </span>
                    </div>
                  )}
                  formatOptionLabel={(data) => (
                    <li
                      className={`mb-1 block cursor-pointer select-none truncate rounded px-2 py-2 transition duration-200 ${
                        !data.isSelected
                          ? `bg-gray-200 text-gray-900`
                          : `bg-blue-800 text-gray-200`
                      }`}
                    >
                      {/* // data represents each option in the list */}
                      {data.label}
                    </li>
                  )}
                />
              </div>

              <div className=" w-28 rounded-md border-2 pl-1.5 ">
                <label className="text-md">Date</label>
                <Datepicker
                  {...register(`flight.${index}.depart`)}
                  containerClassName=""
                  inputClassName="pt-3 bg-white outline-0 text-gray-900 w-full z-50 cursor-pointer text-sm"
                  toggleClassName="relative hover:text-red-500 hidden "
                  popoverDirection="down"
                  useRange={false}
                  asSingle={true}
                  value={depart[index]}
                  showShortcuts={false}
                  // disabledDates={[
                  //   {
                  //     startDate: "2024-03-04",
                  //     endDate: "2023-02-05",
                  //   },
                  //   {
                  //     startDate: "2024-04-05",
                  //     endDate: "2023-02-12",
                  //   },
                  // ]}
                  onChange={(value) => departFun(value, index)}
                />
              </div>
              <div className="cursor-pointer ">
                {fields.length > 1 && (
                  <div
                    onClick={() => remove(index)}
                    className="flex h-full cursor-pointer items-center justify-center  px-2 text-center text-xs "
                  >
                    <Crossicon />
                    {/* REMOVE */}
                  </div>
                )}
              </div>
            </div>
            <div className="px-5">
              {index === fields.length - 1 && (
                <div className="border px-3 py-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <FaCalendarAlt />
                      <label className="text-md">TRAVELLERS</label>
                    </div>
                    <p className="text-sm">{selectedFlightType}</p>
                  </div>

                  <div className=" dropdown w-full">
                    <div tabIndex={0} role="button" className="cursor-pointer">
                      <h3 className="text-lg">
                        {adults + children + infants} Travellers
                      </h3>
                    </div>
                    <div
                      tabIndex={0}
                      className="menu dropdown-content z-[1] w-full rounded-box bg-white p-2 shadow"
                    >
                      <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                        <div className="flex items-center justify-between">
                          <h4>Adults</h4>
                          <h4>{adults}</h4>
                          <div className="flex items-center rounded-md border">
                            <div
                              className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => {
                                if (adults > 1) {
                                  setAdults(adults - 1);
                                }
                              }}
                            >
                              -
                            </div>
                            <div
                              className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => setAdults(adults + 1)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                        <div className="flex items-center justify-between">
                          <div>
                            <h4>Children</h4>
                            <h6 className=" text-gray-400">2-12 Years</h6>
                          </div>
                          <h4>{children}</h4>
                          <div className="flex items-center rounded-md border">
                            <div
                              className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => {
                                if (children > 0) {
                                  setChildren(children - 1);
                                }
                              }}
                            >
                              -
                            </div>
                            <div
                              className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => setChildren(children + 1)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cursor-pointer rounded-md border-b p-2 hover:bg-gray-100">
                        <div className="flex items-center justify-between">
                          <div>
                            <h4>Infants</h4>
                            <h6 className=" text-gray-400">0-23 Months</h6>
                          </div>
                          <h4>{infants}</h4>
                          <div className="flex items-center rounded-md border">
                            <div
                              className="cursor-pointer border border-r-0 p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => {
                                if (infants > 0) {
                                  setInfants(infants - 1);
                                }
                              }}
                            >
                              -
                            </div>
                            <div
                              className="border-s-0 cursor-pointer border p-1 px-3 hover:bg-red-500 hover:text-white"
                              onClick={() => setInfants(infants + 1)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="flex cursor-pointer items-center gap-2 rounded-md py-1 px-1 hover:bg-gray-100">
                          <input
                            type="radio"
                            id="economy"
                            name="flightType"
                            value="Economy"
                            defaultChecked
                            onChange={handleFlightTypeChange}
                          />
                          <label htmlFor="economy" className="cursor-pointer">
                            Economy
                          </label>
                        </div>
                        <div className="flex cursor-pointer items-center gap-2 rounded-md py-1 px-1 hover:bg-gray-100">
                          <input
                            type="radio"
                            id="premiumEconomy"
                            name="flightType"
                            value="Premium Economy"
                            onChange={handleFlightTypeChange}
                          />
                          <label
                            htmlFor="premiumEconomy"
                            className="cursor-pointer"
                          >
                            Premium Economy
                          </label>
                        </div>
                        <div className="flex cursor-pointer items-center gap-2 rounded-md py-1 px-1 hover:bg-gray-100">
                          <input
                            type="radio"
                            id="business"
                            name="flightType"
                            value="Business"
                            onChange={handleFlightTypeChange}
                          />
                          <label htmlFor="business" className="cursor-pointer">
                            Business
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="relative -top-5 m-0 flex items-center justify-center">
                {index === fields.length - 1 &&
                  index !== 0 &&
                  fields.length <= 6 && (
                    <div className="">
                      <button
                        type="button"
                        className="add-city-inner"
                        onClick={() => {
                          append({
                            from: "",
                            to: "",
                            depart: "",
                          });
                        }}
                      >
                        Add City
                      </button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        ))}
        {fields.length <= 1 ? (
          <div className="relative -left-2.5 -top-1.5 float-right">
            <button
              type="button"
              className="add-city "
              onClick={() => {
                append({
                  from: "",
                  to: "",
                  depart: "",
                });
              }}
            >
              Add City
            </button>
          </div>
        ) : (
          ""
        )}

        <div className="flex w-full justify-center px-2">
          <button className="btn-grad w-full" type="submit">
            SUBMIT
          </button>
        </div>
      </form>
    </section>
  );
}
