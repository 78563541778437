import React from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import UserNavbar from "components/UserNavbar/UserNavbar";
import { useLocation } from "react-router-dom";

export default function Bloginfo() {
  const location = useLocation();
  const { imglg, content } = location.state;

  return (
    <section className="text-gray-800">
      <UserNavbar />
      <ScrollToTopOnRouteChange />

      <div className="m-auto max-w-8xl">
        <div className="px-4 py-8">
          <img src={imglg} alt="Blog Header" className="mb-4 w-full" />
          {content.map((section, index) => (
            <div key={index}>
              <h2 className="mb-2 text-2xl font-semibold">{section.title}</h2>
              <ul className="ml-5 list-disc">
                {section.points.map((point, index) => (
                  <li key={index} className="mb-2">
                    {point.includes(":")
                      ? point.split(":").map((part, index) => {
                          if (index === 0) {
                            return <b key={index}>{part}</b>;
                          } else {
                            return ":" + part;
                          }
                        })
                      : point}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
