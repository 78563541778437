import React, { useState } from "react";
import userResources from "api/userResources";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import UserNavbar from "components/UserNavbar/UserNavbar";
import { toast } from "react-toastify";

export default function CreditCardAuthorization() {
  const [formData, setFormData] = useState({
    name_as_appears: "",
    amount: "",
    card_type_id: "",
    card_number: "",
    expire_date: "",
    form: "",
    to: "",
    billing_address: "",
    home_phone: "",
    business_phone: "",
    mobile: "",
    date: "",
    passenger_name: "",
    Signature: null,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const newValue = type === "file" ? files[0] : value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
    // Clear the error message when user starts typing again
    setErrors({
      ...errors,
      [name]: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields
    const newErrors = {};
    Object.entries(formData).forEach(([key, value]) => {
      if (value === "" || value === null) {
        newErrors[key] = true;
      }
    });
    setErrors(newErrors);

    // If there are empty fields, return and don't submit
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    // If all fields are filled, proceed with form submission
    try {
      const formDataWithImage = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        formDataWithImage.append(key, value);
      });
      const response = await userResources.CreditCardAuthorizationList(
        formDataWithImage
      );
      console.log(response);
      toast.success("Form submitted successfully!");
      setFormData({
        name_as_appears: "",
        amount: "",
        card_type_id: "",
        card_number: "",
        expire_date: "",
        form: "",
        to: "",
        passenger_name: "",
        billing_address: "",
        home_phone: "",
        business_phone: "",
        mobile: "",
        date: "",
        Signature: null,
      });
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while submitting the form.");
    }
  };

  return (
    <section className="text-gray-800">
      <UserNavbar />
      <ScrollToTopOnRouteChange />

      <div className="m-auto max-w-8xl">
        <div className="container mx-auto py-6 px-2">
          <h2 className="mb-6 text-2xl font-bold">
            Credit Card Authorization Form
          </h2>
          <p className="mb-4">
            I{" "}
            <input
              type="text"
              id="name_as_appears"
              name="name_as_appears"
              value={formData.name_as_appears}
              onChange={handleChange}
              className={`mx-2 w-64 border-b ${
                errors.name_as_appears ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            />{" "}
            (name as appears on the credit card) hereby authorize Maharaja
            Flights Limited to charge a total amount of CAD{" "}
            <input
              type="text"
              id="amount"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              className={`mx-2 w-32 border-b ${
                errors.amount ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            />{" "}
            from my{" "}
            <select
              id="card_type_id"
              name="card_type_id"
              value={formData.card_type_id}
              onChange={handleChange}
              className={`mx-2 border-b ${
                errors.card_type_id ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            >
              <option value="">Select Card Type</option>
              <option value={1}>Master Card</option>
              <option value={2}>American Express</option>
              <option value={3}>Visa</option>
            </select>{" "}
            Card Number{" "}
            <input
              type="text"
              id="card_number"
              name="card_number"
              value={formData.card_number}
              onChange={handleChange}
              className={`mx-2 w-64 border-b ${
                errors.card_number ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            />{" "}
            Expiry Date{" "}
            <input
              type="date"
              id="expire_date"
              name="expire_date"
              value={formData.expire_date}
              onChange={handleChange}
              className={`mx-2 w-32 border-b ${
                errors.expire_date ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            />{" "}
            for travel for myself and/or (names of other passenger(s) or family
            members, if any){" "}
            <input
              type="text"
              id="passenger_name"
              name="passenger_name"
              value={formData.passenger_name}
              onChange={handleChange}
              className={`mx-2 w-64 border-b border-gray-400 bg-white outline-none ${
                errors.passenger_name ? "border-b-red-500" : "border-gray-400"
              }`}
            />
          </p>
          <p className="mb-4">
            For an itinerary as follows: From{" "}
            <input
              type="text"
              id="form"
              name="form"
              value={formData.form}
              onChange={handleChange}
              className={`mx-2 w-32 border-b ${
                errors.form ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            />{" "}
            To{" "}
            <input
              type="text"
              id="to"
              name="to"
              value={formData.to}
              onChange={handleChange}
              className={`mx-2 w-60 border-b ${
                errors.to ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            />
          </p>
          <p className="mb-4">
            My billing address for this card is:{" "}
            <textarea
              id="billing_address"
              name="billing_address"
              value={formData.billing_address}
              onChange={handleChange}
              className={`mb-4 h-20 w-full border ${
                errors.billing_address ? "border-red-500" : "border-gray-400"
              } bg-white p-2 outline-none`}
            ></textarea>
          </p>
          <p className="mb-4">
            Home Phone #{" "}
            <input
              type="number"
              id="home_phone"
              name="home_phone"
              value={formData.home_phone}
              onChange={handleChange}
              className={`mx-2 w-32 border-b ${
                errors.home_phone ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            />{" "}
            Business #{" "}
            <input
              type="text"
              id="business_phone"
              name="business_phone"
              value={formData.business_phone}
              onChange={handleChange}
              className={`mx-2 w-32 border-b ${
                errors.business_phone ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            />{" "}
            Mobile #{" "}
            <input
              type="number"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className={`mx-2 w-32 border-b ${
                errors.mobile ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            />
          </p>
          <p className="mb-4">
            NOTE: I will support this authorization with photocopies of my same
            credit card (front and back) and a copy of my photo identification
            (driver’s license or passport) which will be faxed herewith.
          </p>
          <p className="mb-4">
            By signing below, I acknowledge full liability for the charge
            described herein. Payment is to be made in full, billed in
            accordance with the standard policy of the company issuing the card.
          </p>
          <p className="mb-4">
            Signature{" "}
            <input
              type="file"
              id="Signature"
              name="Signature"
              onChange={handleChange}
              accept="image/jpeg, image/png, image/jpg, image/gif"
              className={`mx-2 w-64 border-b ${
                errors.Signature ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            />{" "}
            Date{" "}
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              className={`mx-2 w-32 border-b ${
                errors.date ? "border-red-500" : "border-gray-400"
              } bg-white outline-none`}
            />
          </p>
          <p className="mb-6">
            This form must be completed in full and all information must be true
            and correct in order for the ticket issuance to be completed. Please
            complete this form and email to{" "}
            <a
              href="mailto:ticketing@maharajaflights.com"
              className="text-primary"
            >
              ticketing@maharajaflights.com
            </a>
          </p>
          <button
            onClick={handleSubmit}
            className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          >
            Submit
          </button>
          <p className="mt-6">
            For any questions please feel free to contact us at
          </p>
          <div className="mt-2 flex flex-col ">
            <div>
              <p className="font-bold">Toronto ON</p>
              <p>905-244-1300</p>
            </div>
            <div>
              <p className="font-bold">Vancouver BC</p>
              <p>778-244-1300</p>
            </div>
            <div>
              <p className="font-bold">Syracuse NY</p>
              <p>680-244-1300</p>
            </div>
            <div>
              <p className="font-bold">Miami FL</p>
              <p>786-733-1300</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
