import Banner from "components/Charters/Banner";
import UserNavbar from "components/UserNavbar/UserNavbar";
import React, { useState } from "react";
import { travelinsurance } from "Utils/Images";
import { studentInsurance } from "Utils/Images";
import { charterplanesm } from "Utils/Images";
import { charterplane } from "Utils/Images"; // Import insurance banner image

const Insurance = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    travelDates: "",
    destination: "",
    additionalComments: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can implement your form submission logic
    console.log(formData);
  };

  // Array of form fields
  const formFields = [
    { name: "name", type: "text", label: "Name" },
    { name: "email", type: "email", label: "Email" },
    { name: "phone", type: "number", label: "Phone" },
    { name: "travelDates", type: "date", label: "Travel Dates" },
    {
      name: "destination",
      type: "textarea",
      label: "destination",
      span: 2,
    },
  ];
  const insuranceOptions = [
    {
      title: "Travel Insurance",
      image: charterplane,
      description: [
        "Medical Coverage: Covers medical expenses due to illnesses or accidents during the trip, which can be exorbitantly expensive abroad.",
        "Trip Cancellation/Interruption: Reimburses for pre-paid, non-refundable expenses if the trip is canceled or cut short due to unforeseen circumstances like sickness, a death in the family, or other eligible reasons.",
        "Baggage Loss: Provides compensation for lost, stolen, or damaged luggage.",
        "Emergency Evacuations: Covers the cost of evacuation due to a medical emergency or a sudden crisis like a natural disaster.",
      ],
    },
    {
      title: "Visitors Insurance",
      image: charterplane,
      description: [
        "Healthcare Costs: Especially important in countries like the USA, where healthcare is very expensive; it covers international visitors for accidents or illnesses.",
        "Protection in Transit: Covers incidents during travel to and from the destination, which is critical if the traveler has layovers in various countries.",
        "Repatriation of Remains: Covers the costs associated with returning the insured's remains to their home country in case of death during the visit.",
        "Emergency Medical Reunion: Covers the cost of bringing a family member to the insured's location in case of a medical emergency.",
      ],
    },
    {
      title: "Super Visa Insurance (for visitors to Canada)",
      image: charterplane,
      description: [
        "High Coverage: Super Visa requirements include having insurance with at least CAD 100,000 coverage for healthcare, hospitalization, and repatriation.",
        "Validity Period: It covers the insured for the duration of their stay in Canada, which can be up to two years on a Super Visa, unlike standard visitor visas which typically only allow a six-month stay per entry.",
        "Multiple Entries: Beneficial for parents and grandparents visiting family in Canada, allowing them entries over a span of up to 10 years without renewing the status.",
      ],
    },
    {
      title: "Student Travel Insurance",
      image: studentInsurance,
      description: [
        "Medical Coverage: Covers medical expenses due to illnesses or accidents during the trip.",
        "Study Interruption: Reimburses for pre-paid, non-refundable expenses if the trip is interrupted due to unforeseen circumstances like sickness or accidents.",
        "Loss of Passport: Provides assistance and coverage for expenses related to the loss of passport during the trip.",
        "Emergency Dental Treatment: Covers emergency dental treatment expenses during the trip.",
      ],
    },
  ];

  return (
    <section>
      <UserNavbar />
      <Banner
        charterplane={travelinsurance}
        charterplanesm={charterplanesm}
        heading={"Get the Right Insurance Coverage"}
        paragraph={"Protect Your Assets with Our Comprehensive Insurance Plans"}
      />
      <div className="container mx-auto px-4 py-8 text-gray-800">
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
          {insuranceOptions.map((option, index) => (
            <div
              key={index}
              className="overflow-hidden rounded-lg bg-gray-100 shadow-lg"
            >
              <img
                src={option.image}
                alt={option.title}
                className="h-auto w-full object-cover object-center"
              />
              <div className="rounded-lg  p-6">
                <h3 className="mb-4 text-xl font-semibold text-red-600">
                  {option.title}
                </h3>
                <ul className="ml-6 list-disc">
                  {option.description.map((point, idx) => (
                    <li key={idx}>{point}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>

        <h3 className="mt-8 text-2xl font-semibold">
          Why Choose Maharaja Flights Limited for Your Travel Insurance Needs?
        </h3>
        <div className="mt-4 grid grid-cols-1 gap-8 md:grid-cols-2">
          <div className="rounded-lg bg-gray-100 p-6">
            <h3 className="mb-4 text-xl font-semibold text-red-600">
              Customized Solutions
            </h3>
            <p>
              We offer tailored insurance plans to suit your specific travel
              requirements.
            </p>
          </div>
          <div className="rounded-lg bg-gray-100 p-6">
            <h3 className="mb-4 text-xl font-semibold text-red-600">
              24/7 Customer Support
            </h3>
            <p>
              Our dedicated team is always available to assist you, no matter
              where you are in the world.
            </p>
          </div>
          <div className="rounded-lg bg-gray-100 p-6">
            <h3 className="mb-4 text-xl font-semibold text-red-600">
              Easy Claims Process
            </h3>
            <p>
              Hassle-free and straightforward claims process to ensure you get
              the support you need when you need it the most.
            </p>
          </div>
          <div className="rounded-lg bg-gray-100 p-6">
            <h3 className="mb-4 text-xl font-semibold text-red-600">
              Competitive Prices
            </h3>
            <p>
              Get comprehensive coverage at competitive prices, ensuring you
              have peace of mind without breaking the bank.
            </p>
          </div>
        </div>

        <h3 className="mt-8 text-2xl font-semibold">Get a Quote Today!</h3>
        <form onSubmit={handleSubmit} className="">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
            {formFields.map((field, index) => (
              <div
                key={index}
                className={`col-span-1 sm:col-span-${field.span || 1}`}
              >
                <label className="mb-2 block">{field.label}: </label>
                {field.type === "textarea" ? (
                  <textarea
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleChange}
                    className="w-full rounded border bg-white py-2 px-3"
                  ></textarea>
                ) : (
                  <input
                    type={field.type}
                    name={field.name}
                    value={formData[field.name]}
                    onChange={handleChange}
                    className="w-full rounded border bg-white py-2 px-3"
                    required
                  />
                )}
              </div>
            ))}
          </div>
          <button
            type="submit"
            className="mt-4 rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
          >
            Get a Quote
          </button>
        </form>

        <div className="mt-8">
          <h3 className="text-xl font-semibold">Contact Information</h3>
          <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
            <div className="rounded-lg bg-gray-100 p-4">
              <p className="text-lg font-semibold">Maharaja Flights Limited</p>
            </div>
            <div className="rounded-lg bg-gray-100 p-4">
              <p className="font-semibold">Address:</p>
              <p>10 Zina St, Orangeville, ON L9W 1E1</p>
            </div>
            <div className="rounded-lg bg-gray-100 p-4">
              <p className="font-semibold">Phone:</p>
              <p>+1 (905)-244-1300</p>
            </div>
            <div className="rounded-lg bg-gray-100 p-4">
              <p className="font-semibold">Email:</p>
              <p>contact@majarajaflights.com</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Insurance;
