import React from "react";
import "./loader.css";
export default function Loader() {
  return (
    <div class="three-body">
      <div class="three-body__dot"></div>
      <div class="three-body__dot"></div>
      <div class="three-body__dot"></div>
    </div>
  );
}
