import React, { useState } from "react";
import Login from "auth/Login";
import SignUp from "auth/SignUp";
import HomeSearch from "components/HomeSearchComponent/HomeSearch";
import MaharajaLogo from "components/MaharajaLogo/MaharajaLogo";
import MobileDrawer from "components/Mobile/MobileDrawer";
import { FaCar, FaHotel, FaSuitcaseRolling } from "react-icons/fa";
import {
  GiCommercialAirplane,
  GiFishingBoat,
  GiHelicopter,
  GiPalmTree,
  GiPassport,
} from "react-icons/gi";
import { motion } from "framer-motion";
import { IoMdBus } from "react-icons/io";
import { MdDirectionsRailwayFilled } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { insurance } from "Utils/Images";
import { selectUserInfo } from "../../redux/userSlice";
import { useSelector } from "react-redux";
import LoginProfileDropdown from "components/LoginProfileDropdown/LoginProfileDropdown";
import Loginsignup from "components/loginsignup/Loginsignup";
import { FaShip } from "react-icons/fa";

export default function FormSetupFormMobile() {
  const userInfo = useSelector(selectUserInfo);
  const location = useLocation();
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setOpenSignUp] = useState(false);
  const [show, setShow] = useState("hidden");
  const [more, setMore] = useState("More");

  const expand = () => {
    setShow(show === "hidden" ? "block" : "hidden");
    setMore(more === "More" ? "Less" : "More");
  };

  // Animation variants
  const gridItemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="m-auto my-2 block px-5 sm:hidden">
      <div className="fixed -left-3.5 top-3 z-30">
        <MobileDrawer />
      </div>
      <div className="flex items-end justify-between">
        <div>
          <MaharajaLogo text="text-gray-800" />
        </div>
        {userInfo.status === true ? <LoginProfileDropdown /> : <Loginsignup />}
      </div>
      {/* Services */}
      {location.pathname === "/" || location.pathname === "/hotdeals" ? (
        <div className="my-6">
          <h1 className="madimi-one-regular mb-5 text-lg font-bold text-gray-800">
            Explore Your Travel Options
          </h1>
          <div className="grid grid-cols-4 gap-3 overflow-hidden">
            <motion.div
              variants={gridItemVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.3, delay: 0.1 }}
            >
              <HomeSearch
                icon={<GiCommercialAirplane size={"25px"} />}
                text="Flight"
                link={"/flights"}
              />
            </motion.div>
            <motion.div
              variants={gridItemVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.3, delay: 0.2 }}
            >
              <HomeSearch
                icon={<FaHotel size={"25px"} />}
                text="Hotel"
                link={"/hotels"}
              />
            </motion.div>
            <motion.div
              variants={gridItemVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.3, delay: 0.3 }}
            >
              <HomeSearch
                icon={<GiPalmTree size={"25px"} />}
                text="Holiday"
                link={"/holidays"}
              />
            </motion.div>
            <motion.div
              variants={gridItemVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.3, delay: 0.4 }}
            >
              <HomeSearch
                icon={<FaCar size={"25px"} />}
                text="Car rentals"
                link={"/car-rental"}
              />
            </motion.div>
            <motion.div
              variants={gridItemVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.3, delay: 0.5 }}
            >
              <HomeSearch
                icon={<GiFishingBoat size={"25px"} />}
                text="Cruise"
                link={"/cruise"}
              />
            </motion.div>
            <motion.div
              variants={gridItemVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.3, delay: 0.6 }}
            >
              <HomeSearch
                icon={<FaSuitcaseRolling size={"25px"} />}
                text="Insurance"
                link={"/insurance"}
              />
            </motion.div>
            <motion.div
              variants={gridItemVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.3, delay: 0.7 }}
            >
              <HomeSearch
                icon={<GiPassport size={"25px"} />}
                text="Visa info"
                link={"/Visa"}
              />
            </motion.div>
            {show === "block" ? (
              <motion.div
                variants={gridItemVariants}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.3, delay: 0.1 }}
              >
                <HomeSearch
                  icon={<MdDirectionsRailwayFilled size={"25px"} />}
                  text="Train"
                  show={show}
                />
              </motion.div>
            ) : null}
            {show === "block" ? (
              <motion.div
                variants={gridItemVariants}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.3, delay: 0.2 }}
              >
                <HomeSearch
                  icon={<IoMdBus size={"25px"} />}
                  text="Bus"
                  show={show}
                />
              </motion.div>
            ) : null}
            {show === "block" ? (
              <motion.div
                variants={gridItemVariants}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.3, delay: 0.3 }}
              >
                <HomeSearch
                  icon={<GiHelicopter size={"25px"} />}
                  text="Flight Charters"
                  link={"/plane"}
                  show={show}
                />
              </motion.div>
            ) : null}
            {show === "block" ? (
              <motion.div
                variants={gridItemVariants}
                initial="hidden"
                animate="visible"
                transition={{ duration: 0.3, delay: 0.4 }}
              >
                <HomeSearch
                  icon={<FaShip size={"25px"} />}
                  text="Yacht Charters"
                  link={"/yacht"}
                  show={show}
                />
              </motion.div>
            ) : null}

            {show === "hidden" ? (
              <motion.div
                variants={gridItemVariants}
                initial="hidden"
                animate="visible"
                transition={
                  show === "hidden"
                    ? { duration: 0.3, delay: 0.8 }
                    : { duration: 0.3, delay: 0 }
                }
              >
                <HomeSearch
                  icon={
                    <span className="loading loading-dots loading-xs text-red-500 sm:text-white"></span>
                  }
                  text={more}
                  expand={expand}
                />
              </motion.div>
            ) : null}
            {show === "block" ? (
              <motion.div
                variants={gridItemVariants}
                initial="hidden"
                animate="visible"
                transition={
                  show === "block"
                    ? { duration: 0.3, delay: 0.5 }
                    : { duration: 0.3, delay: 0 }
                }
              >
                <HomeSearch
                  icon={
                    <span className="loading loading-dots loading-xs text-red-500 sm:text-white"></span>
                  }
                  text={more}
                  expand={expand}
                />
              </motion.div>
            ) : null}
          </div>
        </div>
      ) : (
        ""
      )}
      <Login openLogin={openLogin} setOpenLogin={setOpenLogin} />
      <SignUp open={openSignUp} setOpen={setOpenSignUp} />
    </div>
  );
}
