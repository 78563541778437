import React from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { charterplanesm } from "Utils/Images";
import { charterplane } from "Utils/Images";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import Background from "components/background/Background";
import { MdOutgoingMail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import userResources from "api/userResources";

// Array of contact items
const contacts = [
  {
    icon: <FaPhoneAlt className="mr-3" />,
    info: "+1 (905)-244-1300",
  },
  {
    icon: <MdOutgoingMail className="mr-3" />,
    info: "contact@maharajaflights.com",
  },
  {
    icon: <IoLocationSharp className="mr-3" />,
    info: "10 Zina St, Orangeville, ON L9W 1E1 ",
  },
];

const additionalContacts = [
  {
    location: "Toronto ON",
    email: "toronto@maharajaflights.com",
    phone: "905-244-1300",
    mapLocation: "Toronto, ON, Canada",
  },
  {
    location: "Vancouver BC",
    email: "vancouver@maharajaflights.com",
    phone: "778-244-1300",
    mapLocation: "Vancouver, BC, Canada",
  },
  {
    location: "Syracuse NY",
    email: "syracuse@maharajaflights.com",
    phone: "680-244-1300",
    mapLocation: "Syracuse, NY, USA",
  },
  {
    location: "San Francisco CA",
    email: "sanfrancisco@maharajaflights.com",
    phone: "628-244-1400",
    mapLocation: "San Francisco, CA, USA",
  },
  {
    location: "Miami FL",
    email: "miami@maharajaflights.com",
    phone: "786-733-1300",
    mapLocation: "Miami, FL, USA",
  },
  {
    location: "India office",
    address:
      "236, NH-8, Sector 1, IMT Manesar, Gurugram, Haryana 122052, India",
    email: "gurugram@maharajaflights.com",
    phone: "+91-9819811300",
    mapLocation:
      "236, NH-8, Sector 1, IMT Manesar, Gurugram, Haryana 122052, India",
  },
  {
    location: "Toll Free",
    phone: "1855-243-1300",
  },
  {
    location: "HEAD OFFICE",
    email: "contact@majarajaflights.com",
  },
  {
    location: "For Ticketing",
    email: "ticketing@maharajaflights.com",
  },
  {
    location: "For complaints and resolutions",
    email: "resolutions@maharajaflights.com",
  },
];

export default function ContactUs() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log(data); // Handle form submission here
    // Display toast notification
    try {
      const response = await userResources.contact(data);
      console.log(response);
      toast.success("Form submitted successfully!");
      reset();
    } catch (error) {
      toast.error("An error occurred while submitting the form.");
    }
  };

  const openInMap = (mapLocation) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      mapLocation
    )}`;
    window.open(url, "_blank");
  };

  return (
    <section className="text-gray-800">
      <ScrollToTopOnRouteChange />
      <ToastContainer />

      <div className="relative">
        {/* <Background
          bg={charterplane}
          hidden="hidden"
          block="block"
          darkness="rgba(0, 0, 0, 0.7)"
        /> */}

        <div className="top-0 left-0 flex h-full w-full items-center justify-center ">
          <div className="mb-14 block w-full flex-wrap overflow-hidden rounded-none bg-white shadow-lg sm:mb-0 sm:flex sm:max-w-full lg:rounded-lg">
            <div className="bg-carousal w-full p-8 text-white sm:w-1/2">
              <h1 className="mb-4 text-3xl font-bold">Get in Touch</h1>
              <p className="mb-4">
                We'd love to hear from you. Fill out the form below and we'll
                get back to you as soon as possible.
              </p>
              <ul className="text-lg">
                {contacts.map((contact, index) => (
                  <li key={index} className="mb-2 flex items-center">
                    {contact.icon}
                    {contact.info}
                  </li>
                ))}
              </ul>
              {/* Form Section */}
              <div className="mx-auto mt-8 max-w-4xl">
                <h2 className="mb-4 text-3xl font-bold">
                  Contact For Any Query
                </h2>
                <form
                  className="w-full max-w-lg"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="-mx-3  flex flex-wrap">
                    <div className="mb-6 w-full px-3 md:mb-0 md:w-1/2">
                      <label
                        className="mb-2 block text-xs font-bold uppercase tracking-wide "
                        htmlFor="grid-first-name"
                      >
                        First Name
                      </label>
                      <input
                        {...register("first_name", { required: true })}
                        className={`mb-1 block w-full appearance-none rounded border bg-transparent py-3 px-4 leading-tight  focus:outline-none ${
                          errors.firstName ? "border-red-500" : ""
                        }`}
                        type="text"
                        placeholder="Enter your name"
                      />
                      {errors.firstName && (
                        <p className="text-xs  text-white">
                          First name is required
                        </p>
                      )}
                    </div>
                    <div className="mb-6 w-full px-3 md:w-1/2">
                      <label
                        className="mb-2 block text-xs font-bold uppercase tracking-wide "
                        htmlFor="grid-last-name"
                      >
                        Last Name
                      </label>
                      <input
                        {...register("last_name", { required: true })}
                        className={`block w-full appearance-none rounded border bg-transparent py-3 px-4 leading-tight focus:border-gray-500  focus:outline-none ${
                          errors.lastName ? "border-red-500" : ""
                        }`}
                        type="text"
                        placeholder="Enter your last name"
                      />
                      {errors.lastName && (
                        <p className="text-xs  text-white">
                          Last name is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="-mx-3 mb-6 flex flex-wrap">
                    <div className="w-full px-3">
                      <label
                        className="mb-2 block text-xs font-bold uppercase tracking-wide "
                        htmlFor="grid-email"
                      >
                        E-mail
                      </label>
                      <input
                        {...register("email", { required: true })}
                        className={`mb-1 block w-full appearance-none rounded border bg-transparent py-3 px-4 leading-tight focus:border-gray-500  focus:outline-none ${
                          errors.email ? "border-red-500" : ""
                        }`}
                        type="email"
                        placeholder="example@example.com"
                      />
                      {errors.email && (
                        <p className="text-xs text-white">Email is required</p>
                      )}
                    </div>
                  </div>
                  <div className="-mx-3 mb-6 flex flex-wrap">
                    <div className="w-full px-3">
                      <label
                        className="mb-2 block text-xs font-bold uppercase tracking-wide "
                        htmlFor="grid-message"
                      >
                        Message
                      </label>
                      <textarea
                        {...register("message", { required: true })}
                        className={`hide-scroll-bar no-resize mb-1 block h-24 w-full resize-none appearance-none rounded border bg-transparent py-3 px-4 leading-tight focus:border-gray-500  focus:outline-none ${
                          errors.message ? "border-red-500" : ""
                        }`}
                        id="message"
                        cols={2}
                        rows={"2"}
                        placeholder="Your message..."
                      ></textarea>
                      {errors.message && (
                        <p className="text-xs  text-white">
                          Message is required
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="">
                    <div className="w-full">
                      <button
                        className="focus:shadow-outline w-full rounded bg-white py-2 px-4 font-bold text-red-600 shadow  focus:outline-none"
                        type="submit"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </form>
                <div className="mt-5 hidden sm:hidden md:block lg:hidden">
                  <iframe
                    title="Embedded Map"
                    width="100%"
                    height="500"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2873.9017781008333!2d-80.10054372392592!3d43.92000757109073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b009d7f17b41d%3A0x40579bd5fb157d4f!2s10%20Zina%20St%2C%20Orangeville%2C%20ON%20L9W%201E1%2C%20Canada!5e0!3m2!1sen!2sin!4v1714671574901!5m2!1sen!2sin"
                  ></iframe>
                </div>
              </div>
              {/* End of Form Section */}
            </div>
            <div className="w-full bg-gray-100 sm:w-1/2">
              <section>
                {/* Additional Contacts Section */}
                <section className="w-full  py-8">
                  <div className=" w-full px-4">
                    <h2 className="mb-4 text-3xl font-bold">
                      Additional Contacts
                    </h2>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-1 lg:grid-cols-2">
                      {additionalContacts.map((contact, index) => (
                        <div
                          key={index}
                          className={`${
                            contact.map || contact.smmap
                              ? ""
                              : "rounded bg-white p-4 shadow"
                          }`}
                        >
                          <div className="flex items-center justify-between">
                            <h3 className=" text-lg font-semibold">
                              {contact.location}
                            </h3>
                            {contact.mapLocation && (
                              <FaMapMarkerAlt
                                className="cursor-pointer text-red-500"
                                onClick={() => openInMap(contact.mapLocation)}
                              />
                            )}
                          </div>
                          {contact.address && (
                            <h3 className="mb-2 text-sm">{contact.address}</h3>
                          )}
                          {contact.phone && (
                            <div className="mb-1 flex items-center">
                              <FaPhoneAlt className="mr-2" />
                              <p>{contact.phone}</p>
                            </div>
                          )}
                          {contact.email && (
                            <div className="mb-1 flex items-center">
                              <MdOutgoingMail className="mr-2" />
                              <p className="text-sm">{contact.email}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </section>
                {/* End of Additional Contacts Section */}
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
