import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";

// Auth Imports
import Adduser from "views/auth/Adduser";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
} from "react-icons/md";
import { RiCoupon2Fill } from "react-icons/ri";
import Coupon from "views/admin/Coupon/Coupon";
import CreateOffer from "views/admin/CreateOffer/CreateOffer";
import SubscribersList from "views/admin/subscribersList/SubscribersList";
import Deals from "views/admin/Deals/Deals";
import Employee from "views/auth/Employee";
import UserList from "views/auth/UserList";
import Visarank from "views/admin/Visa/Visarank";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <MainDashboard />,
    show: true,
  },
  {
    name: "NFT Marketplace",
    layout: "/admin",
    path: "nft-marketplace",
    icon: (
      <MdOutlineShoppingCart className="h-6 w-6 text-gray-900 dark:text-white" />
    ),
    component: <NFTMarketplace />,
    secondary: true,
    show: true,
  },
  {
    name: "Data Tables",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6 text-gray-900 dark:text-white" />,
    path: "data-tables",
    component: <DataTables />,
    show: true,
  },
  {
    name: "Create Offers",
    layout: "/admin",
    path: "create-offers",
    icon: <MdPerson className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <CreateOffer />,
    show: true,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <Profile />,
    show: true,
  },
  {
    name: "Add User",
    layout: "/auth",
    path: "add-user",
    icon: <MdLock className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <Adduser />,
    show: false,
  },
  {
    name: "User List",
    layout: "/admin",
    path: "user-list",
    icon: <MdLock className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <UserList />,
    show: false,
  },
  {
    name: "Employee List",
    layout: "/admin",
    path: "employee-list",
    icon: <MdLock className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <Employee />,
    show: false,
  },
  {
    name: "Visa Rank",
    layout: "/admin",
    path: "visa-rank",
    icon: <MdLock className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <Visarank />,
    show: false,
  },
  {
    name: "Coupon",
    layout: "/admin",
    path: "coupon",
    icon: <RiCoupon2Fill className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <Coupon />,
    show: true,
  },
  {
    name: "Deals",
    layout: "/admin",
    path: "Deals",
    icon: <RiCoupon2Fill className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <Deals />,
    show: true,
  },
  {
    name: "Subscribers",
    layout: "/admin",
    path: "Subscribers",
    icon: <RiCoupon2Fill className="h-6 w-6 text-gray-900 dark:text-white" />,
    component: <SubscribersList />,
    show: true,
  },

  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="text-gray-900 dark:text-white h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
