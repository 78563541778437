import React from "react";
import UserNavbar from "components/UserNavbar/UserNavbar";
import {
  Paradisesm,
  Paradiselg,
  houlflightsm,
  houlflightlg,
} from "Utils/Images";
import { useNavigate } from "react-router-dom";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { indiasm } from "Utils/Images";
import { indialg } from "Utils/Images";
import { solosm } from "Utils/Images";
import { sololg } from "Utils/Images";
import { aircraftlg } from "Utils/Images";
import { aircraftsm } from "Utils/Images";
import { aircraftfleetssm } from "Utils/Images";
import { aircraftfleetslg } from "Utils/Images";
import { FaArrowRight } from "react-icons/fa";
const blogPosts = [
  {
    id: 1,
    title: "Explore the Caribbean",
    description: "Your Ultimate Guide to a Tropical Paradise",
    imgsm: Paradisesm,
    imglg: Paradiselg,
    content: [
      {
        title: "Introduction",
        points: [
          "The Caribbean is synonymous with relaxation, adventure, and endless sunshine.",
          "Whether you're seeking a romantic getaway, a family vacation, or an adrenaline-packed adventure, the Caribbean offers something for everyone.",
        ],
      },
      {
        title: "Diverse Islands, Endless Adventures",
        points: [
          "The Caribbean comprises over 7,000 islands, each with its own unique charm and allure.",
          "Picture-perfect beaches with powdery white sands and azure waters.",
        ],
      },
      {
        title: "Activities",
        points: [
          "Dive into the vibrant underwater world of the Belize Barrier Reef, the second-largest coral reef system in the world.",
          "Embark on a hike through the lush rainforests of Grenada, where waterfalls cascade into natural pools.",
          "Zip-line through the treetops in the Dominican Republic for an adrenaline rush like no other.",
        ],
      },
      {
        title: "Rich History and Culture",
        points: [
          "Explore the colonial architecture of Old San Juan in Puerto Rico.",
          "Visit the historic plantation houses of Jamaica.",
          "The Caribbean is a melting pot of cultures with influences from Africa, Europe, Asia, and indigenous peoples.",
        ],
      },
      {
        title: "Cuisine and Music",
        points: [
          "Sample diverse cuisines from spicy jerk chicken in Jamaica to savory roti in Trinidad and Tobago.",
          "Immerse yourself in the rhythms of soca, reggae, and calypso music.",
        ],
      },
      {
        title: "Accommodation Options",
        points: [
          "Luxury Resorts:",
          "- Exclusive resorts in destinations like the Bahamas and the Turks and Caicos Islands.",
          "- Pampering spas, gourmet dining, and private villas await.",
          "Rustic Retreats:",
          "- Eco-friendly lodges and boutique hotels provide a closer connection to nature.",
          "- Wake up to the sound of waves crashing on the shore in a beachfront cabana in Belize.",
          "- Unwind in a secluded jungle retreat in the rainforests of St. Lucia.",
        ],
      },
      {
        title: "Planning Your Caribbean Adventure",
        points: [
          "Climate: Tropical year-round (75°F to 85°F).",
          "Hurricane season: June to November.",
          "Travel requirements: Passport, visa, currency.",
        ],
      },
      {
        title: "Conclusion",
        points: [
          "A trip to the Caribbean is more than just a vacation—it's an experience that will stay with you long after you return home.",
          "Pack your bags, leave your worries behind, and embark on the adventure of a lifetime in this tropical paradise. Your Caribbean adventure awaits.",
        ],
      },
    ],
  },
  {
    id: 2,
    title: "Surviving a Long-haul Flight",
    description: "Tips for a Comfortable Journey",
    imgsm: houlflightsm,
    imglg: houlflightlg,
    content: [
      {
        title: "Choose the Right Seat",
        points: [
          "Recommend that travelers opt for aisle seats for easier mobility or window seats for those who want to lean against the wall to sleep.",
          "Exit rows or bulkhead seats offer extra legroom.",
        ],
      },
      {
        title: "Wear Comfortable Clothing",
        points: [
          "Encourage wearing loose, breathable clothing and layers that can be easily adjusted based on the airplane’s temperature.",
        ],
      },
      {
        title: "Stay Hydrated",
        points: [
          "Airplane cabins are notoriously dry, so drinking plenty of water is crucial.",
          "Advise against too much caffeine or alcohol as they can dehydrate.",
        ],
      },
      {
        title: "Move Around",
        points: [
          "Recommend stretching and walking around the cabin every few hours to keep blood circulating and to reduce the risk of deep vein thrombosis (DVT).",
        ],
      },
      {
        title: "Bring Entertainment",
        points: [
          "Suggest preparing a range of activities such as downloading movies, books, or music playlists to stay entertained.",
        ],
      },
      {
        title: "Sleep Strategies",
        points: [
          "Advise bringing a neck pillow, eye mask, and earplugs or noise-canceling headphones to help catch some sleep.",
        ],
      },
      {
        title: "Eat Lightly",
        points: [
          "Eating heavy meals can make travelers feel lethargic and uncomfortable.",
          "Suggest opting for lighter meals or bringing healthy snacks.",
        ],
      },
      {
        title: "Adjust to the Destination’s Time Zone",
        points: [
          "Encourage adjusting sleep schedules to the new time zone a few days before the flight if possible, or at least using the flight time to start making that adjustment.",
        ],
      },
      {
        title: "Use Relaxation Techniques",
        points: [
          "Breathing exercises, meditation apps, or listening to calming music can help manage flight anxiety and promote relaxation.",
        ],
      },
    ],
  },
  {
    id: 3,
    title: "Experience the Magic of India",
    description: "A Journey Through a Land of Diversity",
    imgsm: indiasm,
    imglg: indialg,
    content: [
      {
        title: "Introduction",
        points: [
          "India is a land of diversity, rich in history, culture, and natural beauty.",
          "From the snow-capped peaks of the Himalayas to the sun-kissed beaches of Goa, India offers a wide range of experiences for travelers.",
        ],
      },
      {
        title: "Historical Landmarks",
        points: [
          "Explore the iconic landmarks of India, including the Taj Mahal, the Red Fort, and the ancient caves of Ajanta and Ellora.",
          "Discover the architectural wonders of Rajasthan, with its majestic forts and palaces, or wander through the ruins of Hampi, a UNESCO World Heritage Site.",
        ],
      },
      {
        title: "Cultural Diversity",
        points: [
          "Experience India's rich cultural heritage through its festivals, traditions, and cuisine.",
          "Witness the colorful celebrations of Diwali, Holi, and Durga Puja, or sample the diverse flavors of Indian cuisine, from spicy curries to sweet desserts.",
        ],
      },
      {
        title: "Natural Beauty",
        points: [
          "Discover India's stunning natural landscapes, from the lush tea plantations of Darjeeling to the backwaters of Kerala.",
          "Embark on a wildlife safari in one of India's national parks, where you can spot tigers, elephants, and rhinos in their natural habitat.",
        ],
      },
      {
        title: "Adventure and Outdoor Activities",
        points: [
          "Experience the thrill of trekking in the Himalayas, white-water rafting in Rishikesh, or camel safaris in the deserts of Rajasthan.",
          "Go paragliding in the hill stations of Himachal Pradesh, or scuba diving in the Andaman and Nicobar Islands.",
        ],
      },
      {
        title: "Planning Your Trip",
        points: [
          "When planning your trip to India, consider factors such as the best time to visit, visa requirements, and health and safety tips.",
          "Research your destination thoroughly and create a flexible itinerary that allows you to explore at your own pace.",
        ],
      },
      {
        title: "Conclusion",
        points: [
          "A trip to India is an unforgettable experience that will leave you with memories to last a lifetime.",
          "Whether you're exploring ancient temples, trekking in the Himalayas, or relaxing on a tropical beach, you're sure to be captivated by the beauty and diversity of this incredible country.",
        ],
      },
    ],
  },
  {
    id: 4,
    title: "The Art of Solo Travel",
    description: "Tips and Tricks for Exploring the World on Your Own",
    imgsm: solosm,
    imglg: sololg,
    content: [
      {
        title: "Introduction",
        points: [
          "Solo travel can be a rewarding and life-changing experience, allowing you to explore the world on your own terms and discover new destinations, cultures, and people.",
          "Whether you're embarking on a weekend getaway or a year-long adventure, solo travel offers the freedom and flexibility to create your own itinerary and embrace new experiences.",
        ],
      },
      {
        title: "Benefits of Solo Travel",
        points: [
          "Solo travel allows you to step out of your comfort zone and challenge yourself in new and exciting ways.",
          "You'll have the opportunity to meet new people, make new friends, and form meaningful connections with locals and fellow travelers.",
          "Solo travel also gives you the freedom to explore at your own pace, without having to compromise or make concessions for others.",
        ],
      },
      {
        title: "Overcoming Challenges",
        points: [
          "While solo travel can be incredibly rewarding, it can also be challenging at times.",
          "You may encounter language barriers, cultural differences, and logistical challenges along the way.",
          "However, with careful planning, research, and preparation, you can overcome these challenges and make the most of your solo adventure.",
        ],
      },
      {
        title: "Tips for Solo Travelers",
        points: [
          "Research your destination thoroughly and familiarize yourself with local customs, traditions, and etiquette.",
          "Pack light and bring only the essentials, as you'll be responsible for carrying your own luggage.",
          "Stay connected with friends and family back home by sharing your experiences through social media, email, or online travel journals.",
          "Trust your instincts and stay alert and aware of your surroundings at all times.",
          "Be open-minded, flexible, and willing to step outside your comfort zone to embrace new experiences and adventures.",
        ],
      },
      {
        title: "Conclusion",
        points: [
          "Solo travel is an empowering and transformative experience that will challenge, inspire, and enrich you in ways you never thought possible.",
          "By stepping out of your comfort zone and embracing new adventures, you'll gain a deeper understanding of yourself, the world, and your place in it.",
          "So pack your bags, leave your fears behind, and embark on the adventure of a lifetime. Your solo journey awaits!",
        ],
      },
    ],
  },
  {
    id: 5,
    title: "Flying High",
    description:
      "Exploring the Aircraft Fleets of the World's Leading Airlines",
    imgsm: aircraftlg,
    imglg: aircraftsm,
    content: [
      {
        title: "Introduction",
        points: [
          "Have you ever wondered what kind of aircraft you'll be flying on during your next trip? Or why some planes are chosen over others for specific routes? The type of aircraft operated by an airline plays a crucial role in defining the travel experience, impacting everything from passenger comfort and safety to the airline's environmental footprint and fuel efficiency. In this blog post, we'll dive into the diverse aircraft fleets of some of the world's leading airlines, exploring how their choices affect your journey. Whether you're an aviation enthusiast or a curious traveler, understanding these flying machines can add an extra layer of appreciation to your travel adventures.",
        ],
      },
      {
        title: "Wide-Body vs. Narrow-Body Aircraft",
        points: [
          "Wide-Body Aircraft: These aircraft are the backbone of international long-haul aviation, with models like the Boeing 777, Airbus A350, and Boeing 787 Dreamliner leading the pack. They offer twin aisles, larger seating capacity, and enhanced passenger amenities, including more spacious seating arrangements and advanced in-flight entertainment systems.",
          "Narrow-Body Aircraft: Typically seen on short-haul or domestic routes, aircraft such as the Airbus A320 and Boeing 737 are prized for their efficiency and frequency. These planes are easier to fill, turn around quickly for multiple flights per day, and offer cost-effective operations for airlines.",
        ],
      },
      {
        title: "Fleet Diversity Among Major Airlines",
        points: [
          "American Airlines: Operates a diverse fleet that includes both Boeing and Airbus aircraft. This variety allows them to optimize each route based on demand, distance, and airport infrastructure, ensuring efficient use of resources across both domestic and international routes.",
          "Emirates: Known for their luxurious service, Emirates primarily uses the Airbus A380 and Boeing 777. These large aircraft are fitted with high-end amenities that include shower spas and private suites in first class.",
          "Ryanair: Europe’s largest budget carrier exclusively uses the Boeing 737 series. This standardization simplifies maintenance and training, while also maximizing scheduling efficiency and reducing operational costs.",
        ],
      },
      {
        title: "Technological Advancements in Aircraft Design",
        points: [
          "Fuel Efficiency: Modern aircraft are equipped with technologies such as winglets, which reduce aerodynamic drag, and materials like carbon fiber, which decrease the overall weight and increase fuel efficiency.",
          "Passenger Comfort: Advances have been made in enhancing passenger comfort through larger windows, improved cabin pressure and humidity levels, and quieter engines, making long-haul flights more bearable.",
          "Environmental Impact: Newer models are increasingly designed to be more environmentally friendly, reducing carbon emissions and helping airlines meet stricter global emission standards.",
        ],
      },
      {
        title: "Future Trends",
        points: [
          "Electric and Hybrid Planes: The aviation industry is exploring electric and hybrid propulsion technologies that promise significant reductions in greenhouse gas emissions and operational costs.",
          "Supersonic Travel: Companies like Boom Supersonic are developing aircraft that could travel faster than the speed of sound, potentially bringing back the era of supersonic passenger flights with modern improvements.",
        ],
      },
      {
        title: "Conclusion",
        points: [
          "The aircraft chosen by an airline significantly impacts the efficiency of its operations and the quality of the travel experience. As we look towards a future of more sustainable and faster travel, the evolution of aircraft technology continues to play a critical role in shaping how we fly.",
        ],
      },
    ],
  },
  {
    id: 6,
    title: "Aircraft Fleets",
    description:
      "Exploring the Fascinating Facts About Airlines and Their Aircraft Fleets",
    imgsm: aircraftfleetssm,
    imglg: aircraftfleetslg,
    content: [
      {
        title: "Introduction",
        points: [
          "Have you ever wondered what kind of aircraft you'll be flying on during your next trip? Or why some planes are chosen over others for specific routes? The type of aircraft operated by an airline plays a crucial role in defining the travel experience, impacting everything from passenger comfort and safety to the airline's environmental footprint and fuel efficiency. In this blog post, we'll dive into some fascinating facts about airlines and their aircraft fleets. Whether you're an aviation enthusiast or a curious traveler, understanding these flying machines can add an extra layer of appreciation to your travel adventures.",
        ],
      },
      {
        title: "Some Facts About Airlines",
        points: [
          "Oldest Airline: KLM Royal Dutch Airlines is considered the world's oldest airline, still operating under its original name. It was founded in 1919.",
          "Largest Airline (by Fleet Size): American Airlines holds the title for the largest airline by fleet size. As of 2023, it operates a fleet of over 900 aircraft.",
          "Busiest Air Route: The flight route between Jeju Island and Seoul in South Korea is often cited as the busiest in the world, with millions of passengers annually.",
          "Longest Non-stop Flight: The world's longest non-stop commercial flight is operated by Singapore Airlines, covering approximately 9,537 miles (15,344 km) from Singapore to Newark, New Jersey.",
          "Safest Airline: Qantas has been frequently recognized as one of the world's safest airlines, with a remarkable safety record since its founding in 1920.",
          "Most Punctual Airline: According to several reports, Japanese airlines like All Nippon Airways (ANA) and Japan Airlines (JAL) often rank at the top for punctuality.",
          "Most Expensive Airline Route: The route between New York City and London has been reported as one of the most lucrative and expensive, with premium seat prices often reaching into the thousands of dollars for a round trip.",
          "Freight Leaders: While FedEx and UPS are not traditional passenger airlines, they lead in terms of air freight, operating massive fleets of cargo planes globally.",
          "In-flight Entertainment Firsts: Emirates was one of the first airlines to introduce personal screens in all classes back in 1992.",
          "Environmental Initiatives: Many airlines, including United Airlines and British Airways, are actively investing in sustainable aviation fuels and technologies to reduce their carbon footprints.",
          'Pioneering Round-the-World Services: Air India is one of the first airlines to offer a "Round-the-World" service, which it started in 1960, showcasing its global reach.',
        ],
      },
      {
        title: "Conclusion",
        points: [
          "The aircraft chosen by an airline and the operational strategies they implement significantly impact the efficiency of their operations and the quality of the travel experience. As we look towards a future of more sustainable and faster travel, the evolution of aircraft technology and airline operations continues to play a critical role in shaping how we fly. Whether it's through groundbreaking in-flight amenities, stringent safety records, or ambitious environmental goals, the world's leading airlines are constantly innovating to enhance the passenger experience.",
        ],
      },
    ],
  },
];

export default function Blog() {
  const navigate = useNavigate();
  return (
    <section className="text-white">
      <UserNavbar />
      <ScrollToTopOnRouteChange />

      <div className="m-auto my-5 grid max-w-8xl grid-cols-1 gap-4 px-5 sm:px-0 md:grid-cols-2 lg:grid-cols-4">
        {blogPosts.map((post) => (
          <div key={post.id} className="card bg-white text-gray-900 shadow-xl">
            <figure>
              <img
                src={post.imgsm}
                alt={post.title}
                className="h-full p-4 sm:h-60 sm:p-0"
              />
            </figure>
            <div className="card-body pt-0 sm:pt-5">
              <h2 className="card-title">{post.title}</h2>
              <p>{post.description}</p>
              <div
                className="flex cursor-pointer items-center justify-end"
                onClick={() =>
                  navigate(
                    `/blog?${post.title.toLowerCase().replace(/\s+/g, "-")}`,
                    { state: post }
                  )
                }
              >
                <p className=" text-red-500">Read more</p>
                <FaArrowRight className="text-red-500" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
