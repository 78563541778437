import React from "react";

export default function WarningInfo({ heading, paragraph }) {
  return (
    <section className="mx-5 mb-20 rounded-3xl border-l-4 border-yellow-500 bg-yellow-100 p-6 shadow-md sm:mx-0">
      {/* Dynamically render the heading */}
      {heading && (
        <h2 className="mb-3 text-lg font-semibold text-yellow-800">
          {heading}
        </h2>
      )}
      {/* Dynamically render the paragraphs */}
      {paragraph &&
        paragraph.map((text, index) => (
          <p key={index} className="mb-3 leading-relaxed text-yellow-800">
            {text}
          </p>
        ))}
    </section>
  );
}
